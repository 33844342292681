import React, {useState} from "react";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../components/styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function Step3Scope(){
    const [stepNavi, setStepNavi] = React.useState(1);

    const [selValue1, setSelValue1] = useState('');

    const selValue1Change = (event) => {
        setSelValue1(event.target.value);
    };
    const [selValue2, setSelValue2] = useState('');

    const selValue2Change = (event) => {
        setSelValue2(event.target.value);
    };
    const [selValue3, setSelValue3] = useState('');

    const selValue3Change = (event) => {
        setSelValue3(event.target.value);
    };

    const StepTitle = [
        "",
        "Scope 3 배출량을 산정하시겠습니까?",
        "귀 사업장에 해당하는 항목을 선택해주세요.",
        "귀 사업장은 Scope3 <em>Category 1,10,11</em> 산정 대상입니다."
    ]

    const cssRoot = window.getComputedStyle(document.documentElement);

    const chart1option= {
        chart: {
            type: 'bar',
            backgroundColor:'transparent',
            height: '100px',
            width: 750,
            margin: [0, 0, 0, 0],
        },
        title: {
            text: null
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            categories: ['c1', 'c10', 'c11'],
            lineWidth: 0,
            labels : {
                enabled: false
            },
        },
        yAxis: {
            title: {
                text: null,
            },
            gridLineWidth: 0,
            labels : {
                enabled: false
            },
            max: 100,
        },
        legend: {
            enabled: false
        },
        series: [
            {
                name: "c11",
                data : [8.5],
                color: cssRoot.getPropertyValue('--scope1'),
            },
            {
                name: "c10",
                data: [12.15],
                color: cssRoot.getPropertyValue('--scope3'),
            },
            {
                name: "c1",
                data: [63.35],
                color: cssRoot.getPropertyValue('--scope2'),
            },
        ],
        exporting: {
            buttons: {
                contextButton: {
                    enabled: false
                }
            }
        },
        plotOptions:{
            bar : {
                borderWidth : 0,
                dataLabels: {
                    enabled: true,
                    style : {
                        textOutline : 0,
                        color : '#fff',
                    }
                }
            },
            series: {
                stacking: 'normal',
            }
        },

    }

    return (
        <>
            <div className="CarbonPageTitle">
                <strong>안녕하세요, 귀 사업장의 탄소배출량 산정을 위한 설정 단계입니다.</strong>
            </div>
            <div className="CarbonStepWrap">
                <div className="stepDesc">
                    다음 내용을 미리 준비해주시면 더 쉽고 빠른 진행이 가능합니다.<br/>
                    ( 차량관리대장 서류, 기계장치 연료 사용량, 시설 배치도, 전기 고지서, 난방 고지서 )
                </div>
                <div className="CarbonBox">
                    <div className="CarbonNavi">
                        <p className="prev">이전</p>
                    </div>
                    <div className="CarbonStep stepCnt3">
                        <ul className={`step1`}>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                        </ul>
                    </div>
                    <div className="CarbonStepContent">
                        <strong dangerouslySetInnerHTML={{__html: StepTitle[1]}}/>
                        <div className="stepBtn">
                            <button>아니요</button>
                            <button>예</button>
                        </div>
                    </div>
                </div>
                <div className="CarbonBox">
                    <div className="CarbonNavi">
                        <p className="prev">이전</p>
                    </div>
                    <div className="CarbonStep stepCnt3">
                        <ul className={`step2`}>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                        </ul>
                    </div>
                    <div className="CarbonStepContent">
                        <strong dangerouslySetInnerHTML={{__html: StepTitle[2]}}/>
                        <div className="selectWrap">
                            <div>
                                <label>산업 선택</label>
                                <FormControl>
                                    <Select
                                        value={selValue1}
                                        onChange={selValue1Change}
                                        displayEmpty
                                        className="basicSelect"
                                        inputProps={{'aria-label': 'Without label'}}
                                        IconComponent={() => (
                                            <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value={''}>선택해주세요</MenuItem>
                                        <MenuItem value={'2023'}>사업장1</MenuItem>
                                        <MenuItem value={'2022'}>사업장2</MenuItem>
                                        <MenuItem value={'2021'}>사업장3</MenuItem>
                                        <MenuItem value={'2020'}>사업장4</MenuItem>
                                        <MenuItem value={'2019'}>사업장5</MenuItem>
                                        <MenuItem value={'2018'}>사업장6</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <label>활동 대분류 선택</label>
                                <FormControl>
                                    <Select
                                        value={selValue2}
                                        onChange={selValue2Change}
                                        displayEmpty
                                        className="basicSelect"
                                        inputProps={{'aria-label': 'Without label'}}
                                        IconComponent={() => (
                                            <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value={''}>선택해주세요</MenuItem>
                                        <MenuItem value={'2023'}>사업장1</MenuItem>
                                        <MenuItem value={'2022'}>사업장2</MenuItem>
                                        <MenuItem value={'2021'}>사업장3</MenuItem>
                                        <MenuItem value={'2020'}>사업장4</MenuItem>
                                        <MenuItem value={'2019'}>사업장5</MenuItem>
                                        <MenuItem value={'2018'}>사업장6</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <label>활동 소분류 선택</label>
                                <FormControl>
                                    <Select
                                        value={selValue3}
                                        onChange={selValue3Change}
                                        displayEmpty
                                        className="basicSelect"
                                        inputProps={{'aria-label': 'Without label'}}
                                        IconComponent={() => (
                                            <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value={''}>선택해주세요</MenuItem>
                                        <MenuItem value={'2023'}>사업장1</MenuItem>
                                        <MenuItem value={'2022'}>사업장2</MenuItem>
                                        <MenuItem value={'2021'}>사업장3</MenuItem>
                                        <MenuItem value={'2020'}>사업장4</MenuItem>
                                        <MenuItem value={'2019'}>사업장5</MenuItem>
                                        <MenuItem value={'2018'}>사업장6</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="stepBtn">
                            <button>다음</button>
                        </div>

                    </div>
                </div>
                <div className="CarbonBox">
                    <div className="CarbonNavi">
                        <p className="prev">이전</p>
                    </div>
                    <div className="CarbonStep stepCnt3">
                        <ul className={`step3`}>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                        </ul>
                    </div>
                    <div className="CarbonStepContent">
                        <strong dangerouslySetInnerHTML={{__html: StepTitle[3]}}/>
                        <div className="Scope3Chart">
                            <HighchartsReact highcharts={Highcharts} options={chart1option} />
                        </div>
                        {/* 디자인 가이드상 Legend는 Highchart에서 분리해주세요*/}
                        <div className="Scope3ChartLegend">
                            <p><em></em><span>C1</span><b>63.35%</b></p>
                            <p><em></em><span>C10</span><b>12.15%</b></p>
                            <p><em></em><span>C11</span><b>8.5%</b></p>
                        </div>
                    </div>
                </div>
                <div className="StepSubmit">
                    <button>저장 및 배출량 산정하러 가기</button>
                </div>
            </div>
        </>
    );
}

export default Step3Scope;