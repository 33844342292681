import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { TitleCloseDialog } from "../../styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getChart1option, getChart2option } from "./reportModal.helper";

import { formatNumber } from "../common/commonData";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useLoading } from "../../util/LoadingContext";

/**
 * @description 보고서 > 월간 보고서 모달
 * @author seoeunPark
 * @date 18/06/2024
 * @param {*}
 * @return
 */

const ReportModal = React.forwardRef(
  ({ open, onClose, pdfFlag, type, id, yyyyMm, inloNo, reportData, decodedToken }, ref) => {
    const { showLoading, hideLoading } = useLoading();
    const [localReportData, setLocalReportData] = useState(null);

    // console.log("reportData >>", reportData);
    // console.log(" decodedToken >>", decodedToken);

    useEffect(() => {
      showLoading();
      if (reportData) {
        const sortedReportData = JSON.parse(JSON.stringify(reportData));
        sortedReportData.emissionDetailList =
          sortedReportData.emissionDetailList.sort((a, b) => {
            if (a.scope < b.scope) return -1;
            if (a.scope > b.scope) return 1;
            return 0;
          });
        setLocalReportData(sortedReportData);
      }
      hideLoading();
    }, [reportData]);

    const handleDownloadClick = async () => {
      showLoading();
      await handleDownloadPdf();
      hideLoading();
    };

    const handleDownloadPdf = async () => {
      const element = ref.current;
      if (!element) {
        console.error("Element is null");
        return;
      }

      try {
        const pdf = new jsPDF("p", "pt", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const margin = 40;
        let yOffset = margin;

        const renderElement = async (element) => {
          const canvas = await html2canvas(element, {
            scale: 2,
            useCORS: true,
            logging: false,
            letterRendering: true,
          });
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = pdfWidth - 2 * margin;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          if (yOffset + imgHeight > pdfHeight - margin) {
            pdf.addPage();
            yOffset = margin;
          }

          pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
          yOffset += imgHeight + 10;
        };

        const elements = element.querySelectorAll(
          ".reportTitle, .ReportGrid, .MonthlyReport "
        );
        for (let i = 0; i < elements.length; i++) {
          await renderElement(elements[i]);
        }

        pdf.save(`${localReportData?.title}.pdf`);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    };

    return (
      <>
        <TitleCloseDialog
          open={open}
          onClose={onClose}
          style={{ opacity: pdfFlag === true ? 0 : 100 }}
        >
          <div ref={ref}>
            <DialogTitle className="reportTitle">
              <span>
                {localReportData?.title}
                <IconButton onClick={handleDownloadClick}>
                  <SaveAltIcon />
                </IconButton>
              </span>
              <div>
                <strong>{localReportData?.inloName}</strong>
                <ul>
                  <li>업태 : {localReportData?.buisinessType}</li>
                  <li>배출량 산정 기간 : {localReportData?.yearMonth}</li>
                </ul>
              </div>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={onClose}
              className="dialogClose"
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <div className="CarbonPopCont">
                <div className="CarbonReportPop">
                  <div className="ReportGrid">
                    <div className="ReportGridItem">
                      <dl>
                        <dt>기간 내 총 배출량</dt>
                        <dd>
                          <strong>
                            {/* ================== 기간 내 총 배출량 ================== */}
                            {formatNumber(localReportData?.totalEmission, 2)}
                            <em className="carbonUnit">tCO2e</em>
                          </strong>
                          <div className="grayBox">
                            배출량 추정치는 회원님이 제공한 활동 데이터만을
                            기준으로 합니다. 누락된 활동은 산정 내역에 포함되지
                            않습니다. 관련하여 추가 문의사항이 있으시다면
                            고객센터를 통해 연락 주십시오.
                          </div>
                        </dd>
                      </dl>
                    </div>
                    <div className="ReportGridItem">
                      <dl>
                        <dt>배출권 환산 비용</dt>
                        <dd>
                          <strong>
                            {/* ================== 배출권 환산 비용 ================== */}
                            {formatNumber(localReportData?.emissionCost, 2)}
                            <em className="carbonUnit">원</em>
                          </strong>
                          <div className="grayBox">
                            회원님의 탄소배출량을 국내 배출권 시장 2022년
                            할당배출권 평균 가격으로 환산한 값입니다.
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className="ReportGrid">
                    <div className="ReportGridItem">
                      <dl>
                        <dt>
                          배출량 세부 내역
                          <span className="unit">(단위 : tCO2e)</span>
                        </dt>
                        <dd>
                          {/* ================== 배출량 세부 내역 테이블 ================== */}
                          <table>
                            <tbody>
                              {localReportData?.emissionDetailList.reduce(
                                (acc, item, index, array) => {
                                  // 이전 요소와 비교하여 scope가 다르면 새로운 그룹으로 간주
                                  const isDifferentScope =
                                    index === 0 ||
                                    item.scope !== array[index - 1].scope;
                                  // 현재 scope의 요소 수 계산 (같은 scope를 가진 요소들의 수)
                                  const scopeCount = isDifferentScope
                                    ? array
                                        .slice(index)
                                        .filter((x) => x.scope === item.scope)
                                        .length
                                    : 0;

                                  // 새로운 scope가 시작될 때 <th>에 rowSpan을 추가
                                  if (isDifferentScope) {
                                    acc.push(
                                      <tr key={index}>
                                        <th rowSpan={scopeCount}>
                                          {item.scope}
                                        </th>
                                        <td>{item.emissionType}</td>
                                        <td style={{ textAlign: "right" }}>
                                          <span className="second500">
                                            {item.percent}%
                                          </span>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          {formatNumber(item.emission, 2)}
                                        </td>
                                      </tr>
                                    );
                                  } else {
                                    acc.push(
                                      <tr key={index}>
                                        <td>{item.emissionType}</td>
                                        <td style={{ textAlign: "right" }}>
                                          <span className="second500">
                                            {item.percent}%
                                          </span>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          {formatNumber(item.emission, 2)}
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return acc;
                                },
                                []
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th colSpan={3}>합계</th>
                                <td style={{ textAlign: "right" }}>
                                  {formatNumber(
                                    localReportData?.emissionDetailList.reduce(
                                      (acc, item) => acc + item.emission,
                                      0
                                    ),
                                    2
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </dd>
                      </dl>
                    </div>
                    <div className="ReportGridItem">
                      <dl>
                        <dt>Scope별 배출량</dt>
                        <dd>
                          <div className="chartScope">
                            {/* ================== Scope별 배출량 하이차트 ================== */}
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={getChart1option(
                                localReportData?.scopeEmissionList || [],
                                pdfFlag
                              )}
                            />
                            <ul>
                              {localReportData?.scopeEmissionList.map(
                                (item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      style={{
                                        textAlign: "right",
                                        marginRight: "30px",
                                      }}
                                    >
                                      <em>
                                        {item.name} : {item.type}
                                      </em>
                                      <strong>
                                        {formatNumber(item.value, 2)}
                                        <em className="carbonUnit">tCO2e</em>
                                      </strong>
                                      <u className={item.name}></u>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </dd>
                      </dl>
                      <dl>
                        <dt>
                          {localReportData?.inloClCd == 'PROJECT' ? "사업장별 배출량" : "시설별 배출량"}
                          <span className="unit">(단위 : tCO2e)</span>
                        </dt>
                        <dd>
                          {/* ================== 사업장별 배출량 테이블 ================== */}
                          <table>
                            <colgroup>
                              <col />
                              <col width="35%" />
                              <col width="15%" />
                            </colgroup>
                            <tbody>
                              {localReportData?.inloEmissionList?.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <th>{item.inloName || '-'}</th>
                                      <td style={{ textAlign: "right" }}>
                                        <span className="second500">
                                          {parseFloat((item.percent).toFixed(2)).toString()}%
                                        </span>
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {formatNumber(item.emission, 2)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </dd>
                      </dl>
                    </div>
                  </div>

                  {type === "year" && (
                    <div className="MonthlyReport">
                      <dl>
                        <dt>월별 배출량 추이</dt>
                        <dd>
                          <div className="chartTitle">
                            <b>올해 월별 배출량</b>
                            <span className="unit">(단위 : tCO2e)</span>
                          </div>
                          {/* ================== 배출량 추이 하이차트 ================== */}
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={getChart2option(
                              localReportData?.stateEmissionList || [],
                              pdfFlag
                            )}
                          />

                          {/* ================== 배출량 추이 테이블 ================== */}
                          <table style={{ marginTop: "50px" }}>
                            <thead>
                              <tr>
                                <th>배출량</th>
                                {[...Array(12)].map((value, index) => {
                                  return <th>{index + 1}월</th>;
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {/* <tr>
                            <th>scope1</th>
                            {[...Array(12)].map((value, index) => {
                              return <td>12345</td>;
                            })}
                          </tr> */}
                              {localReportData?.stateEmissionList?.map(
                                (item) => {
                                  return (
                                    <tr>
                                      <th>{item.name}</th>
                                      {item.data.map((value, index) => {
                                        return (
                                          <td style={{ textAlign: "right" }}>
                                            {formatNumber(value, 2)}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>합계</th>
                                {/* {[...Array(12)].map((value, index) => {
                              return <td>24,690</td>;
                            })} */}
                                {(
                                  localReportData?.stateEmissionList[0]?.data ||
                                  []
                                ).map(
                                  // 두 배열의 값을 더하여 출력
                                  (value, index) => {
                                    return (
                                      <td style={{ textAlign: "right" }}>
                                        {formatNumber(
                                          localReportData?.stateEmissionList.reduce(
                                            (acc, item) =>
                                              acc + item.data[index],
                                            0
                                          ),
                                          2
                                        )}
                                      </td>
                                    );
                                  }
                                )}
                              </tr>
                            </tfoot>
                          </table>
                        </dd>
                      </dl>
                    </div>
                  )}
                </div>
              </div>
            </DialogContent>
          </div>
        </TitleCloseDialog>
      </>
    );
  }
);

export default ReportModal;
