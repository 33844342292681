import React from "react";

function Step(){
    const [stepNavi, setStepNavi] = React.useState(1);

    const StepNaviNext = () => {
        setStepNavi((prevStep) => {
            return prevStep < 9 ? prevStep + 1 : prevStep;
        });
    };

    const StepNaviPrev = () => {
        setStepNavi((prevStep) => {
            // 새로운 값이 최대값을 초과하지 않도록 설정
            return prevStep > 1 ? prevStep - 1 : prevStep;
        });
    };

    const StepTitle = [
        "",
        "차량을 소유하고 있거나 장기 리스를 유지하고 계신가요? (전기차 제외)",
        "연료를 사용하는 공장 설비 기기를 소유하고 있거나 장기 리스를 유지하고 있습니까? (예: 발전기, 터빈) ",
        "귀 사업장은 외부로부터 열스팀을 공급받나요?",
        "귀 사업장은 외부로부터 전기를 공급받나요?",
        "귀 사업장에서 직접 폐기물을 소각하나요?",
    ]

    return (
        <>
            <div className="CarbonPageTitle">
                <strong>안녕하세요, 귀 사업장의 탄소배출량 산정을 위한 설정 단계입니다.</strong>
            </div>
            <div className="CarbonStepWrap">
                <div className="stepDesc">
                    다음 내용을 미리 준비해주시면 더 쉽고 빠른 진행이 가능합니다.<br/>
                    ( 차량관리대장 서류, 기계장치 연료 사용량, 시설 배치도, 전기 고지서, 난방 고지서 )
                </div>
                <div className="CarbonBox">
                    <div className="CarbonNavi">
                        <p className="prev" onClick={StepNaviPrev}>이전</p>
                    </div>
                    <div className="CarbonStep stepCnt9">
                        <ul className={`step${stepNavi}`}>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                        </ul>
                    </div>
                    <div className="CarbonStepContent">
                        <strong>{StepTitle[stepNavi]}</strong>
                        <div className="stepBtn">
                            <button className="on" onClick={StepNaviNext}>아니요</button>
                            <button onClick={StepNaviNext}>예</button>
                        </div>
                    </div>
                </div>
                <div className="CarbonBox">
                    <div className="CarbonNavi">
                        <p className="prev"><a href="Step5.html">이전</a></p>
                        <p className="reset"><a href="Step1.html">초기화</a></p>
                    </div>
                    <div className="CarbonStep">
                        <ul className="step5">
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                        </ul>
                    </div>
                    <div className="CarbonStepContent">
                        <strong>귀 사업장에서 생산하는 제품들의 공정 유형에 해당되는 항목을 모두 골라주세요.</strong>
                        <div className="stepHash">
                            <div>시멘트 생산</div>
                            <div>석회 생산</div>
                            <div className="on">탄산영의 기타 공정 사용</div>
                            <div>유리 생산</div>
                            <div>마그네슘 생산</div>
                            <div>인산 생산</div>
                            <div>석유정제활동</div>
                            <div>암모니아 생산</div>
                            <div>질산 생산</div>
                            <div>아디프산 생산</div>
                            <div>카바이드 생산</div>
                            <div>소다회 생산</div>
                            <div>석유화학제품 생산</div>
                            <div>불소화합물 생산</div>
                            <div>카프로락탐 생산</div>
                            <div>철강 생산</div>
                            <div>합금철 생산</div>
                            <div>아연 생산</div>
                            <div>납 생산</div>
                            <div>전자 산업</div>
                            <div>연료 전지</div>
                            <div>오존층파괴물질의 대체물질 사용</div>
                        </div>
                    </div>

                </div>
                <div className="StepSubmit">
                    <button>저장 및 배출량 산정하러 가기</button>
                </div>
            </div>
        </>
    );
}

export default Step;