import api from "./api";
import { tscmsCommonEndpoints } from "./endpoints/tscmsCommon";

export const commonApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ...tscmsCommonEndpoints(builder),
  }),
  overrideExisting: false,
});

export const {
  useTestQuery,
  useFetchInloNameQuery,
  useFetchUserInloNameQuery,
  useFetchCompaniesQuery,
  useLazyFetchFileDownloadQuery,
  useFetchYearsQuery,
  useFetchCommonCodeQuery,
  useLazyFetchCommonCodeQuery,
} = commonApi;

export default commonApi;
