import React, { useEffect, useState } from "react";

/**
 * 숫자 입력을 위한 일반 HTML input 컴포넌트
 * @param {object} props - 컴포넌트 props
 * @param {string} props.value - 입력 값
 * @param {function} props.onChange - 값이 변경될 때 호출될 함수
 * @param {number} props.max - 최대 값
 * @return {JSX.Element}
 */
const InputNumber = ({
  id,
  value,
  onChange,
  unit,
  disabled,
  intDigit = 14,
  decDigit = 7,
  max,
  ...props
}) => {
  const [rawValue, setRawValue] = useState(value);
  const [error, setError] = useState("");

  useEffect(() => {
    setRawValue(value);
  }, [value]);

  const formatNumber = (inputNumber) => {
    if (typeof inputNumber !== "string") {
      inputNumber = String(inputNumber);
    }
    let [integer, decimal] = inputNumber.split(".");

    // 숫자 앞의 불필요한 0을 제거
    integer = integer
      .replace(/^0+(?!$)/, "")
      .replace(/[^0-9]/g, "")
      .substring(0, intDigit); // 정수 부분 14자리 제한
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (decimal !== undefined) {
      decimal = decimal.replace(/[^0-9]/g, "").substring(0, decDigit); // 소수점 부분 7자리 제한
      return `${integer}.${decimal}`;
    }

    return integer;
  };

  const handleChange = (event) => {
    let { value: newValue } = event.target;

    // "0"이나 "0."으로 시작하는 경우 그대로 유지하고, 나머지 숫자는 처리
    if (newValue === "0" || newValue.startsWith("0.")) {
      setRawValue(newValue);
      onChange({
        target: {
          id,
          value: newValue,
        },
      });
      setError("");
      return;
    }

    let [integer, decimal] = newValue.split(".");

    integer = (integer || "").replace(/[^0-9]/g, "").substring(0, intDigit); // 정수 부분 14자리 제한
    if (decimal !== undefined) {
      decimal = decimal.replace(/[^0-9]/g, "").substring(0, decDigit); // 소수점 부분 7자리 제한
      newValue = `${integer}.${decimal}`;
    } else {
      newValue = integer;
    }

    // 숫자 앞의 불필요한 0 제거
    newValue = newValue.replace(/^0+(?!$|.)/, "");

    // max 값 체크
    if (max !== undefined && parseFloat(newValue) > max) {
      setError(`값은 ${max} 이하로 입력해주세요.`);
    } else {
      setError("");
    }

    setRawValue(newValue);
    onChange({
      target: {
        id,
        value: newValue,
      },
    });
  };

  return (
    <>
      <input
        id={id}
        value={formatNumber(rawValue)}
        type="text"
        onChange={handleChange}
        disabled={disabled}
        {...props}
      />
      {unit && <span className="inpText">{unit}</span>}
      {error && <div style={{ color: "red", fontSize: "13px" }}>{error}</div>}
    </>
  );
};

export default InputNumber;
