/**
 * @description 에너지 실적 관리 탭 조회
 * @author seoeunPark
 * @date 24/06/2024
 * @param {*}
 * @return
 */
const fetchCnHistory = (builder) => {
  return builder.query({
    query: ({ year, type }) => {
      const params = {};
      if (year) params.year = year;
      if (type) params.type = type;
      return {
        url: `/api/history/board`,
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

export const tscmsHistoryBoardEndpoints = (builder) => ({
  fetchCnHistory: fetchCnHistory(builder),
});
