import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { TitleCloseDialog } from "../../styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { fetchFileBoard } from "../../../modules/features/api/endpoints/tscmsReportBoard";
import { downloadFile } from "../../../modules/features/api/endpoints/tscmsCommon";

/**
 * @description 증빙자료 첨부 모달
 * @author seoeunPark
 * @date 18/06/2024
 * @param {*}
 * @return
 */

const AttachmentModal = ({ open, onClose, inloNo, yyyyMmRaw, type }) => {
  // console.log("AttachmentModal inloNo >>", inloNo);
  // console.log("AttachmentModal yyyyMm >>", yyyyMmRaw);
  // console.log("AttachmentModal type >>", type);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const fileResposne = await fetchFileBoard({
          yyyyMm: yyyyMmRaw,
          type: type,
          inloNo: inloNo,
        });
        setFiles(fileResposne);
        //console.log("fileResponse : ", fileResposne);
      } catch (error) {
        console.log("attachmentModal fetchFiles error");
      }
    };

    if (open) {
      fetchFiles();
    }
  }, [open, inloNo, type, yyyyMmRaw]);

  // 모두저장
  const allFileSave = async (files) => {
    try {
      files.map((file) => {
        downloadFile(file.fileId, file.fileName);
      });
    } catch (e) {
      console.log("allFileSave error", e);
    }
  };

  return (
    <>
      <TitleCloseDialog open={open} onClose={onClose}>
        <DialogTitle>증빙 자료</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogClose"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="CarbonPopCont">
            <div className="CarbonPopFileUpload">
              <div className="uploadedList">
                <dl>
                  <dt>
                    첨부파일 <b>{files?.length}</b>개
                    <span className="btnArea">
                      <button onClick={() => allFileSave(files)}>
                        모두저장
                      </button>
                      {/* <button>전체삭제</button> */}
                    </span>
                  </dt>
                  <dd>
                    <ul>
                      {files?.map((file) => (
                        <li key={file.fileId}>
                          <p
                            className="fileName"
                            onClick={() =>
                              downloadFile(file.fileId, file.fileName)
                            }
                          >
                            [{file.fileName}] <span>{file.fileSize}KB</span>
                          </p>
                          <p className="fileBtn">
                            <button
                              className="down"
                              onClick={() =>
                                downloadFile(file.fileId, file.fileName)
                              }
                            ></button>
                            {/* <button className="del"></button> */}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="CarbonPopBtn">
              <button className="cancle" onClick={onClose}>
                취소
              </button>
              <button>저장</button>
            </div>
          </div>
        </DialogContent>
      </TitleCloseDialog>
    </>
  );
};

export default AttachmentModal;
