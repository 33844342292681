import { createContext, useContext, useState } from "react";
import { LoadingFull } from "./../common/loading/LoadingFull";

const LoadingContext = createContext();

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);
  const hideLoading = () => setLoading(false);

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      {loading && <LoadingFull />}
      {children}
    </LoadingContext.Provider>
  );
};
