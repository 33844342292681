import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuItem from "@mui/material/MenuItem";
import MenuProps from "../../styleModule/MenuProps";
import {
  useFetchManagerQuery,
  useFetchFactoryListQuery,
} from "../../../modules/features/api/calculationFormApi";

const CalcuBox = ({
  pophandleOpen2,
  tabValue,
  data,
  onDataChange,
  files,
  onFilesChange,
  usageInputRef,
  index,
  currentTList,
  year,
  month,
  company,
  inputRefs,
  focusData,
  onTabDataChange,
  onDelete,
}) => {
  const [actCatgList, setActCatgList] = useState({});
  const [labels, setLabels] = useState({});
  const [inputTexts, setInputTexts] = useState({});
  const [selectedValues, setSelectedValues] = useState(data.selectedValues || {});
  const [manager, setManager] = useState([]);
  const [factory, setFactory] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [requiredFields, setRequiredFields] = useState({});
  const [activeFields, setActiveFields] = useState([1]);
  const [openSelect, setOpenSelect] = useState(null);  // open 상태 추가
  const [initialLoad, setInitialLoad] = useState(true);

  const padMonth = (month) => month.toString().padStart(2, "0");

  const { data: managerData } = useFetchManagerQuery({inloNo : company ? company : localStorage.getItem("inloNo")});
  const { data: factoryData } = useFetchFactoryListQuery({inloNo : company ? company : localStorage.getItem("inloNo")});

  useEffect(() => {
    if (managerData) {
      setManager(Array.isArray(managerData.data) ? managerData.data : []);
    }
  }, [managerData]);

  useEffect(() => {
    if (factoryData) {
      setFactory(Array.isArray(factoryData.data) ? factoryData.data : []);
    }
  }, [factoryData]);

  useEffect(() => {
    if (focusData) {
      const { tabKey, boxIndex, missingField, fromButton } = focusData;
      // console.log("tabKey >>>>>", tabKey);
      // console.log("boxIndex >>>>>", boxIndex);
      // console.log("missingField >>>>>", missingField);
      // console.log("tabValue >>>>>", tabValue);
      // console.log("index >>>>>", index);
      // console.log("fromButton >>>>>", fromButton);

      if (parseInt(tabKey) === parseInt(tabValue) && parseInt(boxIndex) === parseInt(index) && fromButton) {
        //console.log("1111111111111111111111111111111111");

        setTimeout(() => {
          const inputElement = document.querySelector(`#calcuBox-${tabKey}-${boxIndex} [name='${missingField}']`);
          //console.log("inputElement >>>>>>>", inputElement);

          if (inputElement) {
            //inputElement.scrollIntoView({ behavior: "smooth" });
            if (inputElement.getAttribute('name') === 'userNo' || inputElement.getAttribute('name') === 'factory' || inputElement.getAttribute('name').startsWith('actCatg') || inputElement.getAttribute('name') === 'unit') {
              setOpenSelect(missingField);
            } else {
              inputElement.focus();
            }
          } else {
            console.error(`Element not found for missingField: ${missingField}`);
          }
        }, 100); // 지연 시간 추가
      } else {
        //console.log("222222222222222222222222222222");
      }
    }
  }, [focusData, tabValue, index]);

  useEffect(() => {
    const fields = {
      unit: !!data.unit,
      factory: !!data.facNo,
      userNo: !!data.userNo,
      calOkYn: data.calOkYn === true,
      inputText1: !!data.inputText1,
    };

    // inputText 필드들을 확인하여 fields에 추가
    Object.keys(data).forEach((key) => {
      if (key.startsWith("inputText")) {
        fields[key] = !!data[key];
      }
    });

    // activeFields 상태를 확인하여 해당 필드들이 requiredFields에 존재하도록 보장 (변경사항)
    activeFields.forEach((fieldIndex) => {
      const actCatgKey = `actCatg${fieldIndex}`;
      fields[actCatgKey] = !!selectedValues[actCatgKey];
    });


    setRequiredFields(fields);
    //console.log("required Field : ", requiredFields);
  }, [data, selectedValues, actCatgList, activeFields]);

  useEffect(() => {
    if (year && month) {
      const formattedYyyyMm = `${year}${padMonth(month)}`;
      onDataChange({
        ...data,
        yyyyMm: formattedYyyyMm,
      });
    }
  }, [year, month]);

  useEffect(() => {
    if (company) {
      onDataChange({
        ...data,
        inloNo: parseInt(company),
      });
    }
  }, [company]);

  useEffect(() => {
    if (currentTList.length > 0) {
      //console.log("currentTList >>", currentTList);
      const updatedActCatgList = {};
      const updatedLabels = {};
      const updatedInputTexts = {};
      let updatedUnitList = []; //

      currentTList.forEach((item) => {
        for (let i = 1; i <= 4; i++) {
          const actCatgKey = `actCatg${i}`;
          const actCatgTypeKey = `actCatgType${i}`;
          const inputTextKey = `inputText${i}`;

          if (item[actCatgKey]) {
            if (!updatedActCatgList[actCatgKey]) {
              updatedActCatgList[actCatgKey] = new Set(); // 새로운 Set 객체를 생성. Set은 중복된 값을 허용하지 않는 데이터 구조
            }
            updatedActCatgList[actCatgKey].add(item[actCatgKey]);
          }

          if (item[actCatgTypeKey]) {
            updatedLabels[actCatgKey] = item[actCatgTypeKey]; // updatedLabels 객체에 actCatgTypeKey 값을 추가
          }

          if (item[inputTextKey]) {
            updatedInputTexts[inputTextKey] = item[inputTextKey]; // updatedInputTexts 객체에 inputTextKey 값을 추가
          }
        }

        if (item.actCatg1 === data.actCatg1) {
          const units = item.units
            .replace(/[\[\]]/g, "")
            .split(",")
            .map((unit) => unit.trim());
          updatedUnitList = [...new Set([...updatedUnitList, ...units])];
        }
      });

      for (const key in updatedActCatgList) {
        updatedActCatgList[key] = Array.from(updatedActCatgList[key]); // Set 객체를 배열로 변환하여 저장
      }

      setActCatgList(updatedActCatgList);
      setLabels(updatedLabels);
      setInputTexts(updatedInputTexts);
      setUnitList(updatedUnitList);

      // selectedValues를 업데이트하여 actCatg1이 제대로 설정되도록 함
      if (initialLoad) {
        const newActiveFields = [1];
        const newSelectedValues = {};

        if (data.actCatg1) {
          newSelectedValues.actCatg1 = data.actCatg1;
        }
        if (data.actCatg2) {
          newSelectedValues.actCatg2 = data.actCatg2;
          newActiveFields.push(2);
        }
        if (data.actCatg3) {
          newSelectedValues.actCatg3 = data.actCatg3;
          newActiveFields.push(3);
        }
        if (data.actCatg4) {
          newSelectedValues.actCatg4 = data.actCatg4;
          newActiveFields.push(4);
        }

        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...newSelectedValues,
        }));
        setActiveFields(newActiveFields);
        setInitialLoad(false);
    }

      // const initialActiveFields = data.activeFields || [1]; // 기존 데이터의 activeFields를 사용하거나 기본값 [1]로 초기화
      // setActiveFields(initialActiveFields);
    }
  }, [currentTList, data]);

  // 숫자에 콤마를 추가하는 함수
  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (field, e) => {
    const { name, value } = e.target;
    let numericValue = value;

    // inputText 필드에 숫자만 입력 가능하도록 추가
    if (name.startsWith("inputText")) {
      numericValue = value.replace(/[^0-9]/g, ""); // 숫자가 아닌 문자는 제거

      // 입력값이 "0"이 아닌 경우 맨 앞에 0이 올 수 없게 처리
      if (numericValue !== "0") {
        numericValue = numericValue.replace(/^0+/, ""); // 맨 앞의 0을 제거
      }
    }

    //console.log(`Input Changed: ${name} = ${numericValue}`);

    // 필수 필드 설정 업데이트
    setRequiredFields((prevFields) => ({
      ...prevFields,
      [field]: numericValue !== "",
    }));

    // inputText1이 변경될 때 inputVal1도 업데이트
    const updatedData = {
      ...data,
      [name]: numericValue,
    };

    updatedData.curtCatgNo = tabValue;
    updatedData.inloNo = company ? parseInt(company) : parseInt(localStorage.getItem("inloNo"));
    const formattedYyyyMm = `${year}${padMonth(month)}`;
    updatedData.yyyyMm = formattedYyyyMm;

    if (name === 'inputText1') {
      updatedData['inputVal1'] = numericValue;
      updatedData['useVol'] = numericValue;
    }

    // 존재하는 inputText 필드에 따라 inputVal 업데이트
    for (let i = 2; i <= 4; i++) {
      if (name === `inputText${i}`) {
        updatedData[`inputVal${i}`] = numericValue;
        break;
      }
    }

    //console.log("updatedData >>>>>>>>>>> ", updatedData);

    // selectedValues 업데이트 (필드를 최신 상태로 유지하기 위해 업데이트)
    const updatedSelectedValues = { ...updatedData.selectedValues, [name]: numericValue };
    setSelectedValues(updatedSelectedValues);

    // 데이터를 업데이트하는 콜백 호출
    onDataChange(updatedData);
    onTabDataChange(tabValue, index, { selectedValues: updatedSelectedValues });

    inputRefs.current[name] = e.target; // inputRefs 업데이트
  };



  const handleSelectChange = (field, e) => {
    const { name, value } = e.target;
    const updatedSelectedValues = { ...selectedValues, [field]: value };

    // inputText 필드를 동적으로 selectedValues에 추가
    Object.keys(data).forEach(key => {
      if (key.startsWith("inputText") && data[key] !== undefined) {
        updatedSelectedValues[key] = data[key] || "";
      }
    });

    setSelectedValues(updatedSelectedValues);

    //console.log(`select changed: ${name} = ${value}`);

    const updatedData = { ...data, [name]: value, selectedValues: updatedSelectedValues };

    if (field === "manager") {
      const selectedUser = manager.find(user => user.userNo === value);
      const userName = selectedUser ? selectedUser.userName : "";
      updatedData.userNo = value;
      updatedData.userName = userName;
      setRequiredFields((prevFields) => ({
        ...prevFields,
        userNo: value !== "",
      }));
    }

    if (field === "factory") {
      updatedData.facNo = value;
      setRequiredFields((prevFields) => ({
        ...prevFields,
        facNo: value !== "",
      }));
    }

    if (field.startsWith("actCatg")) {
      const fieldIndex = parseInt(field.slice(-1));
      const nextFieldIndex = fieldIndex + 1;
      const nextField = `actCatg${nextFieldIndex}`;

      const nextList = currentTList.filter(
        (item) => item[field] === value && item[nextField]
      );

      const newSelectedValues = { ...updatedSelectedValues };
      const newActiveFields = [...activeFields];

      // Next field setup and initialization
      if (nextList.length > 0) {
        const nextValues = new Set(nextList.map((item) => item[nextField]));
        setActCatgList((prev) => ({
          ...prev,
          [nextField]: Array.from(nextValues),
        }));
        if (!newActiveFields.includes(nextFieldIndex)) {
          newActiveFields.push(nextFieldIndex);
        }
        newSelectedValues[nextField] = "";
      } else {
        const indexToRemove = newActiveFields.indexOf(nextFieldIndex);
        if (indexToRemove !== -1) {
          newActiveFields.splice(indexToRemove, 1);
        }
        delete newSelectedValues[nextField];
      }

      setActiveFields(newActiveFields);
      setSelectedValues(newSelectedValues);
      onTabDataChange(tabValue, index, { selectedValues: newSelectedValues, activeFields: newActiveFields });

      // Update data with selected values and active fields
      updatedData.selectedValues = newSelectedValues;
      updatedData.activeFields = newActiveFields;

      // Label handling for actCatg fields
      const actCatgTypeKey = `actCatgType${fieldIndex}`;
      const selectedActCatg = currentTList.find(item => item[field] === value);
      updatedData[actCatgTypeKey] = selectedActCatg ? selectedActCatg[actCatgTypeKey] : "";

      // Reset lower level actCatg fields
      for (let i = nextFieldIndex; i <= 6; i++) {
        const actCatgKey = `actCatg${i}`;
        delete updatedSelectedValues[actCatgKey];
        updatedData[actCatgKey] = null;
      }
    }

    // Unit list setup based on actCatg1 selection
    if (field === "actCatg1") {
      updatedData.unit = "";
      const selectedActCatg1 = currentTList.filter(item => item.actCatg1 === value);
      const units = selectedActCatg1.map(item => item.units).flat();
      const uniqueUnits = [...new Set(units.map(unit => unit.trim()))];
      setUnitList(uniqueUnits);
    }

    // Find current item based on actCatg and unit selections
    // if (updatedData.unit) {
    //   const currentItem = findCurrentItem(currentTList, updatedData);
    //   if (currentItem) {
    //     console.log("currentItem >>", currentItem);
    //     updatedData.actCatgNo = currentItem.actCatgNo;
    //     updatedData.userCurtItemId = currentItem.userCurtItemId;
    //     updatedData.inloCurtCatgId = currentItem.inloCurtCatgId;
    //   }
    // }

    const lastActiveFieldIndex = Math.max(...activeFields);
    const lastActCatgField = `actCatg${lastActiveFieldIndex}`;
    const lastActCatgValue = updatedSelectedValues[lastActCatgField];

    if (lastActCatgValue) {
      const currentItem = currentTList.find(item => item[lastActCatgField] === lastActCatgValue);
      if (currentItem) {
        //console.log("currentItem >>", currentItem);
        updatedData.actCatgNo = currentItem.actCatgNo;
        updatedData.userCurtItemId = currentItem.userCurtItemId;
        updatedData.inloCurtCatgId = currentItem.inloCurtCatgId;
      }
    }

    const formattedYyyyMm = `${year}${padMonth(month)}`;
    updatedData.yyyyMm = formattedYyyyMm;
    updatedData.curtCatgNo = tabValue;
    updatedData.inloNo = company ? parseInt(company) : parseInt(localStorage.getItem("inloNo"));

    //console.log("updatedData >>>>>>>>>>> ", updatedData);

    onDataChange(updatedData);
    onTabDataChange(tabValue, index, { selectedValues: updatedSelectedValues, activeFields });
    inputRefs.current[name] = e.target;
  };

  const handleToggleChange = () => {
    const newCalOkYn = !data.calOkYn;
    onDataChange({
      ...data,
      calOkYn: newCalOkYn,
    });
    setRequiredFields((prevFields) => ({
      ...prevFields,
      calOkYn: newCalOkYn === "true",
    }));
  };

  const formatToFourDecimalPlaces = (num) => {
    return parseFloat(num.toFixed(4));
  };

  const isValidOption = (value, options) => options.includes(value);

  const allFieldsFilled = Object.values(requiredFields).every(Boolean);

  return (
    <>
    <div
      className={`calcuBox ${allFieldsFilled ? "select" : ""}`}
      id={`calcuBox-${tabValue}-${index}`}>
      <div className="calcuCont">
        <div className="deleteArea"><button className="deleteBtn" onClick={() => onDelete(index)}>×</button></div>
        <div className="calcuTop">
          <ul>
            <li>
              <label>담당자</label>
              <FormControl>
                <Select
                  className="smallSelect"
                  displayEmpty
                  name={"userNo"}
                  value={manager.find(user => user.userNo === data.userNo) ? data.userNo : ""}
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={() => (
                    <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                  )}
                  MenuProps={MenuProps}
                  fullWidth
                  onChange={(e) => handleSelectChange("manager", e)}
                  onOpen={() => setOpenSelect("userNo")}
                  onClose={() => setOpenSelect(null)}
                  open={openSelect === "userNo"}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span>담당자 제외</span>;
                    }
                    const selectedUser = manager.find(
                      (user) => user.userNo === selected
                    );
                    return selectedUser ? selectedUser.userName : "담당자 제외";
                  }}>
                    <MenuItem value="">
                      담당자 제외
                    </MenuItem>
                  {manager.map((user) => (
                    <MenuItem
                      id={user.userId}
                      key={user.userNo}
                      value={user.userNo}>
                      {user.userName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </li>
            <li>
              <label>증빙자료</label>
              <div>
                <button className="upFile" onClick={() => pophandleOpen2(data.userCurtCatgId, company.value)}>
                  <span>파일 업로드</span>
                </button>
              </div>
            </li>
            <li>
              <label>최종수정</label>
              <div>{data.lastUpdate || '-'}</div>
            </li>
            <li>
              <label>산정 포함 여부</label>
              <div>
                <div className="toggleBtn" onClick={handleToggleChange}>
                  <input
                    type="checkbox"
                    checked={data.calOkYn}
                    onChange={handleToggleChange}
                  />
                  <p>
                    <em></em>
                  </p>
                </div>
              </div>
            </li>
            <li>
              <label>탄소배출량</label>
              <div>
                {data.baseCbem ? formatToFourDecimalPlaces(data.baseCbem) : '0'} tCO₂e
              </div>
            </li>
          </ul>
        </div>
        <div className="calcuBtm">
          <ul>
            <li>
              <label>배출시설(공정명)</label>
              <FormControl fullWidth>
                <Select
                  className="smallSelect"
                  displayEmpty
                  name="factory"
                  value={isValidOption(data.facNo, factory.map(fac => fac.facNo)) ? data.facNo : ""}
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={() => (
                    <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                  )}
                  MenuProps={MenuProps}
                  fullWidth
                  onChange={(e) => handleSelectChange("factory", e)}
                  onOpen={() => setOpenSelect("factory")}
                  onClose={() => setOpenSelect(null)}
                  open={openSelect === "factory"}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span>미지정</span>;
                    }
                    const selectedFac = factory.find(
                      (fac) => fac.facNo === selected
                    );
                    return selectedFac
                      ? `${selectedFac.facName}`
                      : "미지정";
                  }}>
                  <MenuItem value={0}>
                    미지정
                  </MenuItem>
                  {factory.map((fac) => (
                    <MenuItem id={fac.facNo} key={fac.facNo} value={fac.facNo}>
                      {fac.facName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </li>
            {activeFields.map((index) => (
              <li key={index}>
                <label>{labels[`actCatg${index}`]}</label>
                <FormControl fullWidth>
                  <Select
                    className="smallSelect"
                    displayEmpty
                    name={`actCatg${index}`}
                    value={isValidOption(selectedValues[`actCatg${index}`], actCatgList[`actCatg${index}`] || []) ? selectedValues[`actCatg${index}`] : ""}
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                    )}
                    MenuProps={MenuProps}
                    fullWidth
                    onChange={(e) => handleSelectChange(`actCatg${index}`, e)}
                    onOpen={() => setOpenSelect(`actCatg${index}`)}
                    onClose={() => setOpenSelect(null)}
                    open={openSelect === `actCatg${index}`}
                    renderValue={(selected) =>
                      selected || <span>선택하세요</span>
                    }>
                    {actCatgList[`actCatg${index}`]?.map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </li>
            ))}
            {unitList.length > 0 && (
              <li>
                <label>단위</label>
                <FormControl fullWidth>
                  <Select
                    className="smallSelect"
                    displayEmpty
                    name="unit"
                    value={isValidOption(data.unit, unitList) ? data.unit : ""}
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                    )}
                    MenuProps={MenuProps}
                    fullWidth
                    onChange={(e) => handleSelectChange("unit", e)}
                    onOpen={() => setOpenSelect("unit")}
                    onClose={() => setOpenSelect(null)}
                    open={openSelect === "unit"}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span>선택하세요</span>;
                      }
                      return selected;
                    }}>
                    {unitList.map((unit, index) => (
                      <MenuItem key={index} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </li>
            )}
            {Object.keys(inputTexts).map((key, index) => (
              <li key={index}>
                <label>{inputTexts[key]}</label>
                <div>
                  <input
                    type="text"
                    name={key}
                    value={formatNumberWithCommas(data[key] || "")}
                    onChange={(e) => handleInputChange(`${key}`, e)}
                    placeholder="입력해주세요"
                    ref={(el) => (inputRefs.current[key] = el)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </>
  );
};

export default CalcuBox;
