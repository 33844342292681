import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import { TitleCloseDialog } from "../../styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { FormControl, MenuItem, Select } from "@mui/material";
import MenuProps from "../../styleModule/MenuProps";
import {
  getToday,
  getYearOptionList,
  monthOptionList,
  yearOptionList,
} from "./../common/commonData";
/**
 * @description
 * @author seoeunPark
 * @date 18/06/2024
 * @param {*}
 * @return
 */
const ConfirmNewReport1 = ({ open, onClose, open2, selected, setSelected }) => {
  const [yearListOptions, setYearOptionList] = useState([]);
  const [monthListOptions, setMonthOptionList] = useState([]);

  useEffect(() => {
    setYearOptionList([...getYearOptionList()]);
    setMonthOptionList([...monthOptionList]);

    setSelected((prev) => ({
      ...prev,
      year: getToday("yyyy"),
      month: getToday("mm"),
    }));
  }, []);

  return (
    <>
      <TitleCloseDialog open={open} onClose={onClose}>
        <DialogTitle>새 보고서 작성</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogClose"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="CarbonPopCont">
            <p className="typoTy1">
              보고서 작성을 희망하는 기간을 선택해주세요.
            </p>
            <div className="doubleSel">
              <FormControl fullWidth>
                <Select
                  className="popSelect"
                  displayEmpty
                  value={selected.year}
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={() => (
                    <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                  )}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    setSelected({ ...selected, year: e.target.value });
                  }}
                >
                  {yearListOptions.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <Select
                  className="popSelect"
                  displayEmpty
                  value={selected.month}
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={() => (
                    <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                  )}
                  MenuProps={MenuProps}
                  onChange={(e) => {
                    setSelected({ ...selected, month: e.target.value });
                  }}
                >
                  {monthListOptions.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="CarbonPopBtn">
              <button className="cancle" onClick={onClose}>
                취소
              </button>
              <button onClick={open2}>확인</button>
            </div>
          </div>
        </DialogContent>
      </TitleCloseDialog>
    </>
  );
};

export default ConfirmNewReport1;
