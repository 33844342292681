import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { TitleCloseDialog } from "../../styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

/**
 * @description 보고서 삭제 확인 모달
 * @author seoeunPark
 * @date 18/06/2024
 * @param {*}
 * @return
 */
const CofirmDeleteModal = ({ open, onClose, handleDelete }) => {
  return (
    <>
      <TitleCloseDialog open={open} onClose={onClose}>
        <DialogTitle>보고서 삭제</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogClose"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="CarbonPopCont">
            <p className="typoTy1">
              보고서를 삭제하시면 추후 [~~년 ~~월]에 대한 보고서 작성을
              <br />
              다시 진행하셔야 합니다.
              <br />
              <br />
              정말 삭제하시겠습니까?
            </p>
            <div className="CarbonPopBtn">
              <button className="cancle" onClick={onClose}>
                취소
              </button>
              <button onClick={handleDelete}>확인</button>
            </div>
          </div>
        </DialogContent>
      </TitleCloseDialog>
    </>
  );
};

export default CofirmDeleteModal;
