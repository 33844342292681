import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

export const TitleCloseDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        padding: "30px",
    },
    '& .MuiDialogTitle-root': {
        color : "#030000",
        fontSize: "18px",
        fontWeight: 700,
        fontFamily : "Noto Sans KR",
        margin: "0",
        padding: "0 40px 0 0",
    },
    '& .MuiDialogContent-root' : {
        padding: 0,
    },
    '& .MuiDialogActions-root': {
        margin: "0 32px 32px",
        justifyContent: "center",
        '& button, & a' : {
            fontSize: "18px",
            height: "48px",
            minWidth : "162px",
        }
    },
});