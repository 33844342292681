import { apiList } from "../../modules/store";

export const selectIsAnyApiLoading = (state) => {
  return apiList.some((api) => {
    const apiState = state[api.reducerPath];
    return Object.values(apiState.queries || {}).some(
      (queryState) => queryState.status === "pending"
    );
  });
};
