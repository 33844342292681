import React, { useState, useEffect } from "react";
import { formatNumber } from "../../CarbonNote/common/commonData";

/**
 * @description DashBoard 컨테이너 → 전체 배출량
 * @author Choi Seon ha
 * @date 10/06/2024
 * @param {*} builder
 * @return {JSX.Element}
 */

const TotalEmission = ({ totalEmissionData }) => {
  const [nowEmi, setNowEmi] = useState(0);
  const [beforeEmi, setBeforeEmi] = useState(0);
  const [reductionPer, setReductionPer] = useState(0);

  //console.log("totalEmissionData >>>" , totalEmissionData);

  useEffect(() => {
    if (totalEmissionData) {
      setNowEmi(formatNumber(totalEmissionData[0].nowEmi));
      setBeforeEmi(formatNumber(totalEmissionData[0].beforeEmi));
      setReductionPer(totalEmissionData[0].reductionPer);
    }
  }, [totalEmissionData]);

  return (
    <>
      <div className="DashGridItem">
        <p className="DashItemTitle">
          <b>전체 배출량</b>
        </p>
        <div className="totalCnt">
          <strong>
            {formatNumber(nowEmi, 3)}
            <i className="carbonUnit">tCO2e</i>
          </strong>
          <em>
            전년도 대비 {formatNumber(Math.abs(reductionPer), 2)}%{" "}
            {reductionPer > 0 ? "감소" : "증가"}
          </em>
        </div>
      </div>
    </>
  );
};

export default TotalEmission;
