// src/components/CarbonNote/DashBoard/EmissionByBusiness.jsx

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { emissionByBusinessData } from "./DashBoardData";
import { formatNumber } from "../../CarbonNote/common/commonData";

/**
 * @description DashBoard 컨테이너 → 사업자별 배출량
 * @author Choi Seon ha
 * @date 10/06/2024
 * @return {JSX.Element}
 */

const EmissionByBusiness = ({ emissionByBusinessData, selectedYear }) => {
  const navigate = useNavigate();

  const handleDetailClick = () => {
    const stateData = {};
    stateData.year = selectedYear;
    navigate("/history", { state: stateData });
  };

  return (
    <div className="DashGridItem">
      <p className="DashItemTitle">
        <b>사업장별 배출량</b>
        <button onClick={handleDetailClick}>자세히 보기</button>
      </p>
      <table>
        <colgroup>
          <col width="35%" />
          <col width="30%" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>사업자명</th>
            <th style={{ textAlign: "right" }}>비중(%)</th>
            <th style={{ textAlign: "right" }}>총 배출량 (tCO2e)</th>
          </tr>
        </thead>
        <tbody>
          {emissionByBusinessData
            ? emissionByBusinessData.map((item, index) => (
                <tr key={index}>
                  <td>{item.inloName}</td>
                  <td style={{ textAlign: "right" }}>{item.percentage}</td>
                  <td style={{ textAlign: "right" }}>
                    {formatNumber(item.emission)}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default EmissionByBusiness;
