import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../components/styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {TitleCloseDialog} from "../components/styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import {TextField} from "@mui/material";

function PurposeGraph() {
    const [topYear, setTopYear] = useState('2024');

    const TopYearChange = (event) => {
        setTopYear(event.target.value);
    };

    const [topComp, setTopComp] = useState('all');

    const TopCompChange = (event) => {
        setTopComp(event.target.value);
    };

    const cssRoot = window.getComputedStyle(document.documentElement);

    const chart1option= {
        chart: {
            zooming: {
                type: 'xy'
            },
            marginTop: 80,
            height: 500,
        },
        title: {
            text: '목표관리 이행 현황',
            align: 'left',
            style : {
                fontSize: "18px"
            }
        },
        xAxis: {
            categories: ['2020','2021','<b style="color:#000">2022(기준년도)</b>', '2023','2024', '2025','2026', '2027','2028','2029','<b style="color:#000">2030(타켓연도)</b>'],
            crosshair : true,
            tickInterval : 2,
            style : {
                color : "#fff"
            },
            labels : {
                style : {
                    color : cssRoot.getPropertyValue('--gray500'),
                }
            },
            lineColor : cssRoot.getPropertyValue('--line20'),
        },
        yAxis: {
            max: 100,
            min: -100,
            title: {
                text: 'tCO2e/ea'
            },
            stackLabels: {
                enabled: false
            },
            labels : {
                style : {
                    color : cssRoot.getPropertyValue('--gray500'),
                }
            },
            gridLineDashStyle : "longdash"
        },
        exporting: {
            buttons: {
                contextButton: {
                    enabled: false
                }
            }
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            shadow: false,
            y: -50,
            x: -180,

        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                borderRadius: 4,
            },
            spline: {
                label: {
                    enabled: false
                }
            }
        },
        tooltip : {
            backgroundColor : "#374053",
            style : {
                color : "#fff",
                fontSize : "12px"
            }

        },
        series: [{
            name: '기준연도 배출량 집약도',
            type: 'spline',
            color : cssRoot.getPropertyValue('--scope2'),
            data: [80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80],
            marker: {
                enabled: false
            },
            dashStyle: 'dash',
            zIndex: 10,
        }, {
            name: '과거 배출량',
            type: 'column',
            data: [70, 60, 80, 0, 0, 0, 0, 0, 0, 0, 0],
            color: cssRoot.getPropertyValue('--scope1'),
        },  {
            name: '순배출량',
            type: 'column',
            color : cssRoot.getPropertyValue('--scope2'),
            data: [0, 0, 0, 70, 60, 50, 40, 30, 20, 20, 20]
        }, {
            name: '감축활동에 따른 예상 감축량',
            type: 'column',
            color : cssRoot.getPropertyValue('--scope4'),
            data: [0, 0, 0, 10, 20, 30, 40, 50, 60, 70, 70]
        }, {
            name: '배출권 구매로 인한 상세',
            type: 'column',
            color : cssRoot.getPropertyValue('--scope3'),
            data: [0, 0, 0, -10, -20, -30, -40, -50, -60, -70, -70]
        }, {
            name: '1.5℃ 감축 시나리오',
            type: 'spline',
            color : cssRoot.getPropertyValue('--scopepoint'),
            data: [null, null, null, 70, 60, 50, 40, 30, 20, 10, 0],
            lineWidth: 0,
            marker: {
                enabled: true,
                fillColor : cssRoot.getPropertyValue('--scopepoint'),
                radius: 8,
                symbol : 'url(data:image/svg+xml,%3Csvg width=\'25\' height=\'24\' viewBox=\'0 0 25 24\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg%3E%3Ccircle cx=\'12.5996\' cy=\'12\' r=\'8\' fill=\'%23FAA82E\'/%3E%3C/g%3E%3C/svg%3E%0A)'

            },
            dashStyle: 'shortdot',
            zIndex: 10,
        }]
    }

    const [popOpen, setPopOpen] = useState(false);

    const pophandleClose = () => {
        setPopOpen(false);
    };
    const pophandleOpen = () => {
        setPopOpen(true);
    };

    const [popOpen2, setPopOpen2] = useState(false);

    const pophandleClose2 = () => {
        setPopOpen2(false);
    };
    const pophandleOpen2 = () => {
        setPopOpen2(true);
    };

    return (
        <>
            <TitleCloseDialog
                open={popOpen}
                onClose={pophandleClose}
            >
                <DialogTitle>
                    배출량 감축 시나리오 선택
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <div className="popScenario">
                            <div className="ScenarioSelect">
                                <ul>
                                    <li>
                                        <label>업종</label>
                                        <FormControl>
                                            <Select
                                                className="popSelect"
                                                displayEmpty
                                                value={"1"}
                                                inputProps={{'aria-label': 'Without label'}}
                                                IconComponent={() => (
                                                    <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value={"1"}>폐기물</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </li>
                                    <li>
                                        <label>감축 대상 설비</label>
                                        <FormControl>
                                            <Select
                                                className="popSelect"
                                                displayEmpty
                                                value={"1"}
                                                inputProps={{'aria-label': 'Without label'}}
                                                IconComponent={() => (
                                                    <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value={"1"}>공기 압축기 설비</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </li>
                                    <li>
                                        <label>개선활동 구분</label>
                                        <FormControl>
                                            <Select
                                                className="popSelect"
                                                displayEmpty
                                                value={"1"}
                                                inputProps={{'aria-label': 'Without label'}}
                                                IconComponent={() => (
                                                    <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value={"1"}>신설비 도입</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </li>
                                    <li>
                                        <label>개선 활동명</label>
                                        <FormControl  onClick={pophandleOpen2}>
                                            <TextField className="popSelect" placeholder="선택하기"/>
                                        </FormControl>
                                    </li>
                                    <li>
                                        <label>개선 활동 적용 연도</label>
                                        <FormControl>
                                            <Select
                                                className="popSelect"
                                                displayEmpty
                                                value={"1"}
                                                inputProps={{'aria-label': 'Without label'}}
                                                IconComponent={() => (
                                                    <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value={"1"}>2024</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </li>
                                </ul>
                            </div>
                            <div className="ScenarioResult">
                                <ul>
                                    <li>
                                        <label>예상 온실가스 감축량</label>
                                        <b>3.6tCO2/년</b>
                                    </li>
                                    <li>
                                        <label>투자비</label>
                                        <b>15백만원</b>
                                    </li>
                                    <li>
                                        <label>연감 절감액</label>
                                        <b>3백만원/년</b>
                                    </li>
                                    <li>
                                        <label>투자비 회수기간</label>
                                        <b>5년</b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="CarbonPopBtn">
                            <button>목표관리 이행 현황에 적용하기</button>
                        </div>
                    </div>
                </DialogContent>
            </TitleCloseDialog>
            <TitleCloseDialog
                open={popOpen2}
                onClose={pophandleClose2}
            >
                <DialogTitle>
                    개선활동명 선택
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose2} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <div className="logTextSelect">
                            <ul>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로
                                    </p>
                                </li>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        효율이 높고
                                    </p>
                                </li>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        용량제어 기능이 갖추어진 종합효율
                                    </p>
                                </li>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        Blower도입 유지보수비가 적고 인버터제어로
                                    </p>
                                </li>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        인버터제어로
                                    </p>
                                </li>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로
                                    </p>
                                </li>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        효율이 높고
                                    </p>
                                </li>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        용량제어 기능이 갖추어진 종합효율
                                    </p>
                                </li>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        Blower도입 유지보수비가 적고 인버터제어로
                                    </p>
                                </li>
                                <li>
                                    <input type="radio"/>
                                    <p>
                                        인버터제어로
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="CarbonPopBtn">
                            <button>배출량 감축 시나리오에 적용하기</button>
                        </div>

                    </div>
                </DialogContent>
            </TitleCloseDialog>
            <div className="CarbonPageTitle">
            <strong>탄소저감 목표관리</strong>
            </div>
            <div className="CarbonTopSelect">
                <div className="selArea">
                    <p className="label">감축 방법론</p>
                    <FormControl>
                        <Select
                            value={topComp}
                            onChange={TopCompChange}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'all'}>생산량 원단위</MenuItem>
                            <MenuItem value={'2023'}>사업장1</MenuItem>
                            <MenuItem value={'2022'}>사업장2</MenuItem>
                            <MenuItem value={'2021'}>사업장3</MenuItem>
                            <MenuItem value={'2020'}>사업장4</MenuItem>
                            <MenuItem value={'2019'}>사업장5</MenuItem>
                            <MenuItem value={'2018'}>사업장6</MenuItem>
                        </Select>
                    </FormControl>
                    <p className="label">기준년도</p>
                    <FormControl>
                        <Select
                            value={topYear}
                            onChange={TopYearChange}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'2024'}>2024년</MenuItem>
                            <MenuItem value={'2023'}>2023년</MenuItem>
                            <MenuItem value={'2022'}>2022년</MenuItem>
                            <MenuItem value={'2021'}>2021년</MenuItem>
                            <MenuItem value={'2020'}>2020년</MenuItem>
                            <MenuItem value={'2019'}>2019년</MenuItem>
                            <MenuItem value={'2018'}>2018년</MenuItem>
                            <MenuItem value={'2017'}>2017년</MenuItem>
                            <MenuItem value={'2016'}>2016년</MenuItem>
                            <MenuItem value={'2015'}>2015년</MenuItem>
                            <MenuItem value={'2014'}>2014년</MenuItem>
                        </Select>
                    </FormControl>

                </div>
            </div>
            <div className="CarbonPurposeWrap">
                <div className="CarbonBox">
                    <div className="GraphInBtn">
                        <button onClick={pophandleOpen}>배출량 감축 시나리오 추가</button>
                    </div>
                    <div id="PurpoChart">
                        <HighchartsReact highcharts={Highcharts} options={chart1option}/>
                    </div>
                </div>
                <div className="PurposeSubmit">
                    <button className="cancle">다시하기</button>
                    <button>저장</button>
                </div>
                <div className="CarbonBox">
                    <div className="CarbonBoxTitle">
                        <b>적용 시나리오 이력</b>
                    </div>
                    <div className="scenarioGrid">
                        <div className="scenarioFile">
                            <ul>
                                <li className="select">
                                    <p><input type="checkbox" checked/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label>
                                    </p>
                                    <span className="del"></span>
                                </li>
                                <li>
                                    <p><input type="checkbox" checked/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label>
                                    </p>
                                    <span className="del"></span>
                                </li>
                                <li>
                                    <p><input type="checkbox"/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label></p>
                                    <span className="del"></span>
                                </li>
                                <li>
                                    <p><input type="checkbox"/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label></p>
                                    <span className="del"></span>
                                </li>
                                <li>
                                    <p><input type="checkbox"/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label></p>
                                    <span className="del"></span>
                                </li>
                                <li className="select">
                                    <p><input type="checkbox" checked/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label>
                                    </p>
                                    <span className="del"></span>
                                </li>
                                <li>
                                    <p><input type="checkbox" checked/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label>
                                    </p>
                                    <span className="del"></span>
                                </li>
                                <li>
                                    <p><input type="checkbox"/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label></p>
                                    <span className="del"></span>
                                </li>
                                <li>
                                    <p><input type="checkbox"/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label></p>
                                    <span className="del"></span>
                                </li>
                                <li>
                                    <p><input type="checkbox"/><label>2025-폐기물-공기 압축기 설비-신설비 도입-VSD..</label></p>
                                    <span className="del"></span>
                                </li>
                            </ul>
                        </div>
                        <div className="scenarioDetail">
                            <ul>
                                <li><span>예상 온실가스 감축량</span><b>3.6tCO2/년</b></li>
                                <li><span>투자비</span><b>15백만원</b></li>
                                <li><span>연감 절감액</span><b>3백만원/년</b></li>
                                <li><span>투자비 회수기간</span><b>5년</b></li>
                            </ul>
                        </div>
                    </div>
                    <p className="scenarioBtmText">* 시나리오를 선택하시면 예상 감축량 정보를 보실 수 있습니다.</p>
                </div>
                <div className="scenarioBtn">
                    <button>관련 감축지원 사업 확인하기</button>
                </div>
            </div>
        </>
    );
}

export default PurposeGraph;