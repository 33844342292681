import { formatNumber } from "../common/commonData";

/**
 * @description 탄소저감 목표관리 > 목표관리 이행 현황 그래프 옵션 설정 함수
 * @author seoeunPark
 */
const cssRoot = window.getComputedStyle(document.documentElement);
export const statusGraphHighchartOptions = (
  options,
  dataList,
  decreaseMethod
) => {
  const { graphYearCategory } = options;

  return {
    chart: {
      zooming: {
        type: "xy",
      },
      marginTop: 80,
      height: 500,
    },
    title: {
      text: "목표관리 이행 현황",
      align: "left",
      style: {
        fontSize: "18px",
      },
    },
    xAxis: {
      categories: graphYearCategory,
      crosshair: true,
      tickInterval: 2,
      style: {
        color: "#fff",
      },
      labels: {
        style: {
          color: cssRoot.getPropertyValue("--gray500"),
        },
      },
      tickPositions: graphYearCategory.map((_, index) => index),
      lineColor: cssRoot.getPropertyValue("--line20"),
    },
    yAxis: {
      max: setGraphMaxMin(dataList).max,
      min: setGraphMaxMin(dataList).min,
      title: {
        text:
          decreaseMethod === "Remi"
            ? "tCo2e"
            : decreaseMethod === "RProduct"
            ? "tCo2e/ea"
            : "tCo2e/원",
      },
      stackLabels: {
        enabled: false,
      },
      labels: {
        style: {
          color: cssRoot.getPropertyValue("--gray500"),
        },
      },
      gridLineDashStyle: "longdash",
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      shadow: false,
      y: -50,
      x: -180,
      itemStyle: {
        cursor: "default",
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
        borderWidth: 0,
        borderRadius: 4,
        // minPointLength: 20, // 최소 포인트 길이 설정
      },
      spline: {
        label: {
          enabled: false,
        },
      },
      series: {
        events: {
          // 기본 동작을 막아 시리즈 활성화/비활성화 되지 않도록 함
          legendItemClick: function (event) {
            event.preventDefault();
          },
        },
      },
    },
    tooltip: {
      shared: true,
      backgroundColor: "#374053",
      style: {
        color: "#fff",
        fontSize: "12px",
      },
      /* 툴팁  shared: false 버전  */
      // formatter: function () {
      //   const seriesIndex = this.series.index;
      //   const seriesName = this.series.name;
      //   const seriesColor = this.series.color;
      //   const x = this.x;
      //   const y = this.y;
      //   const index = this.point.index;

      //   console.log("seriesColor [", seriesIndex, "]>>", seriesColor);
      //   console.log("dataList >>", dataList);

      //   if (seriesIndex === 0) {
      //     const text = ` - 예상 감축량 : ${formatNumber(y, 2)} tCO2e/ea <br/>
      //     - 투자비: ${formatNumber(
      //       dataList.find((item) => item.id === 4)?.investCost[index],
      //       2
      //     )} 백만원 <br/>
      //     - 연간 절감액: ${formatNumber(
      //       dataList.find((item) => item.id === 4)?.annualSavings[index],
      //       2
      //     )} 백만원/yr <br/>
      //     `;
      //     return strHtml(x, y, seriesName, seriesColor, text);
      //   } else if (seriesIndex === 1) {
      //     return strHtml(x, y, seriesName, seriesColor);
      //   } else if (seriesIndex === 2) {
      //     const text =
      //       `${x}년 구매가 필요한 <br/>
      //       상쇄배출권 양은 ` +
      //       formatNumber(-y, 2) +
      //       ` tCO2e/ea 입니다.`;
      //     return strHtml(x, y, seriesName, seriesColor, text);
      //   } else if (seriesIndex === 3) {
      //     return strHtml(x, y, seriesName, seriesColor);
      //   } else if (seriesIndex === 4) {
      //     return strHtml(x, y, seriesName, seriesColor);
      //   } else if (seriesIndex === 5) {
      //     return strHtml(x, y, seriesName, seriesColor);
      //   }
      // },

      /* 툴팁  shared: true 버전  */
      formatter: function () {
        const points = this.points;
        if (this.x.includes('<b style="color:#000">')) {
          this.x = this.x
            .replace('<b style="color:#000">', "")
            .replace("</b>", "");
        }
         
        let tooltipHtml = `<b>${this.x}년</b><br/>`;

        points.forEach((point) => {
          const seriesName = point.series.name;
          const seriesColor = point.color;
          const y = point.y;
          const index = point.point.index;
          let text = "";

          if (point.series.index === 0) {
            text = ` - 예상 감축량 : ${formatNumber(y, 2)} ${
              decreaseMethod === "Remi"
                ? "tCo2e"
                : decreaseMethod === "RProduct"
                ? "tCo2e/ea"
                : "tCo2e/원"
            } <br/>
            - 투자비: ${formatNumber(
              dataList.find((item) => item.id === 4)?.investCost[index],
              2
            )} 백만원 <br/>
            - 연간 절감액: ${formatNumber(
              dataList.find((item) => item.id === 4)?.annualSavings[index],
              2
            )} 백만원/yr <br/><br/>`;
          } else if (point.series.index === 2) {
            text = `${this.x}년 구매가 필요한 <br/>
            상쇄배출권 양은 ${formatNumber(-y, 2)} tCO2e/원 입니다.<br/><br/>`;
          }

          tooltipHtml += strHtml(
            this.x,
            y,
            seriesName,
            seriesColor,
            text,
            decreaseMethod
          );
        });

        return tooltipHtml;
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        index: 0,
        name: "감축활동에 따른 예상 감축량",
        type: "column",
        color: cssRoot.getPropertyValue("--scope4"),
        data: dataList.find((item) => item.id === 4)?.data,
      },
      {
        index: 1,
        name: "순배출량".concat(decreaseMethod !== "Remi" ? " 집약도" : ""),
        type: "column",
        color: cssRoot.getPropertyValue("--scope2"),
        data: dataList.find((item) => item.id === 3)?.data,
      },
      {
        index: 2,
        name: "배출권 구매로 인한 상쇄",
        type: "column",
        color: cssRoot.getPropertyValue("--scope3"),
        data: dataList.find((item) => item.id === 5)?.data,
      },
      {
        index: 3,
        name: "과거 배출량".concat(decreaseMethod !== "Remi" ? " 집약도" : ""),
        type: "column",
        data: dataList.find((item) => item.id === 2)?.data,
        color: cssRoot.getPropertyValue("--scope1"),
      },
      {
        index: 4,
        name: "기준연도 배출량".concat(
          decreaseMethod !== "Remi" ? " 집약도" : ""
        ),
        type: "spline",
        color: cssRoot.getPropertyValue("--scope2"),
        data: dataList.find((item) => item.id === 1)?.data,
        marker: {
          enabled: false,
        },
        dashStyle: "dash",
        zIndex: 10,
      },
      {
        index: 5,
        name: "1.5℃ 감축 시나리오",
        type: "spline",
        color: cssRoot.getPropertyValue("--scopepoint"),
        data: dataList.find((item) => item.id === 6)?.data,
        lineWidth: 0,
        marker: {
          enabled: true,
          fillColor: cssRoot.getPropertyValue("--scopepoint"),
          radius: 8,
          symbol:
            "url(data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ccircle cx='12.5996' cy='12' r='8' fill='%23FAA82E'/%3E%3C/g%3E%3C/svg%3E%0A)",
        },
        dashStyle: "shortdot",
        zIndex: 10,
      },
    ],
  };
};

const strHtml = (
  x,
  y,
  seriesName,
  seriesColor,
  text = null,
  decreaseMethod
) => {
  return `
    <span style="color:${seriesColor}">●</span>
    <b>${seriesName}</b><br/>
    ${
      text
        ? `${text}`
        : ` ${x}년 : ${formatNumber(y.toFixed(2))} ${
            decreaseMethod === "Remi"
              ? "tCo2e"
              : decreaseMethod === "RProduct"
              ? "tCo2e/ea"
              : "tCo2e/원"
          }
    <br/><br/>`
    }
  `;
};

/**
 * @description 과거 배출량 데이터
 * @param {String} decreaseMethod 감축방법론(production|sales|emission)
 * @param {Number} baseEmission 해당연도 기준배출량
 * @param {Number} inputVal 입력값
 */
export const calcPastEmissionData = (
  decreaseMethod,
  baseEmission,
  inputVal
) => {
  // 감축방법론이 생산량 원단위 일때 => 해당연도 기준배출량 / 해당연도 판매 총수량(입력값)
  // 감축방법론이 매출 원단위 일때 => 해당연도 기준배출량 / 해당연도 매출 금액(입력값)
  // 감축방법론이 매출 배출량 일때 => 해당연도 기준배출량
  if (decreaseMethod === "RProduct" || decreaseMethod === "RSale") {
    return baseEmission / inputVal;
  } else {
    return baseEmission;
  }
};

/**
 * @description 순배출량 데이터 = 기준연도의 과거배출량 - 감축활동에 따른 예상 감축량
 * @param {Number} baseYearEmission 기준연도의 과거배출량
 * @param {Number} expectReduction 감축활동에 따른 예상 감축량
 */
export const calcNetEmission = (baseYearEmission, expectReduction) => {
  return baseYearEmission - expectReduction;
};

/**
 * @description 기준년도 배출량 집약도 = 기준년도의 과거 배출량 값
 * @param {Number} targetYear 타겟연도
 * @param {Number} baseYear 기준연도
 * @param {Number} baseYearEmission 기준연도의 과거배출량
 */
export const calcBaseYearEmission = (
  baseYear,
  targetYear,
  baseYearEmission
) => {
  const length = targetYear - baseYear + 3;

  const result = Array.from({ length }, (_, index) => {
    return Number(baseYearEmission);
  });

  return result;
};

/**
 * @description 감축활동에 따른 예상 감축량
 * @param {String} decreaseMethod 감축방법론(production|sales|emission)
 * @param {Number} baseYearInput 해당연도 기준값
 * @param {Number} growthRate 성장률
 */
export const calcExpectReduction = (
  decreaseMethod,
  baseYearInput,
  growthRate
) => {
  // 감축방법론이 생산량 원단위 일때 => 배출량 / 해당연도
  // 감축방법론이 매출 원단위 일때 => 해당연도 기준값 * 성장률
  // 감축방법론이 매출 배출량 일때 => 해당연도 기준값 * 성장률
  if (decreaseMethod === "production" || decreaseMethod === "sales") {
    return baseYearInput * growthRate;
  } else {
    return baseYearInput;
  }
};

/**
 * @description 배출권 구매로 인한 상쇄 = 순배출량 - 1.5℃ 감축 시나리오
 * @param {Number} netEmission 순배출량
 * @param {Number} reductScenario 1.5℃ 감축 시나리오
 */
export const calcOffsetByPurchasing = (netEmission, reductScenario) => {
  return 0 - (netEmission - reductScenario);
};

/**
 * @description 1.5℃ 감축 시나리오 = 기준연도의 과거배출량 기준으로 타겟연도까지 0으로 수렴(완)
 * @param {Number} baseYear 기준연도
 * @param {Number} targetYear 타겟연도
 * @param {Number} baseEmission 해당연도 기준배출량
 * @return [null, null, null, 83.33, 66.67, 50, 33.33, 16.67, 0]
 */
export const calc15CReductionScenario = (
  baseYear,
  targetYear,
  baseEmission
) => {
  const length = targetYear - baseYear;

  const minusAmount = baseEmission / length;

  const result = Array.from({ length }, (_, index) => {
    return Number((baseEmission - minusAmount * (index + 1)).toFixed(2));
  });

  return [null, null, null, ...result];
};

/**
 * @description 그래프 최대,최소 설정 (최대값은 가장 큰 값, 최소값은 최대값의 절대값의 마이너스 값)
 * @param {Array} dataList 그래프 데이터 리스트 [1, 2, 3, 4, 5, 6]
 */
export const setGraphMaxMin = (dataList) => {
  const max = Math.max(...dataList.map((item) => Math.max(...item.data)));
  const min = Math.min(...dataList.map((item) => Math.min(...item.data)));

  return {
    max: max,
    min: -Math.abs(max),
  };
};

/**
 * @description 예상감축량 시 나눌 값
 * @param {String} baseYearSales 기준연도 매출액
 * @param {Number} growthRate 성장률
 * @param {Number} targetYear 타겟연도
 * @param {Number} baseYear 기준연도
 * @param {Number} index 인덱스
 * @param {String} decreaseMethod 감축방법론(production|sales|emission)
 */
export const calcSalesAmount = (
  baseYearSales,
  growthRate,
  targetYear,
  baseYear,
  index,
  decreaseMethod
) => {
  if (decreaseMethod === "Remi") return null;

  const length = Number(targetYear - baseYear);

  const growthSales = Number(
    Number(baseYearSales) + Number(baseYearSales * (growthRate / 100))
  );
  const unit = Number((growthSales - baseYearSales) / length);
  const result = Number(Number(baseYearSales) + unit * index).toFixed(2);

  return Number(result);
};
/**
 * @description
 * @param {String} baseYearSales 기준연도 매출액
 * @param {Number} growthRate 성장률
 * @param {Number} targetYear 타겟연도
 * @param {Number} baseYear 기준연도
 */
export const calcSalesAmount2 = (
  baseYearSales,
  growthRate,
  targetYear,
  baseYear
) => {
  const years = targetYear - baseYear;
  const annualIncrease = baseYearSales * (growthRate / 100);

  return Array.from(
    { length: years },
    (_, i) => baseYearSales + annualIncrease * (i + 1)
  );
};
