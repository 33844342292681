import React, { useEffect, useMemo, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import EmissionReductionScenarioPopup from "./EmissionReductionScenarioPopup";
import {
  calc15CReductionScenario,
  calcBaseYearEmission,
  calcExpectReduction,
  calcOffsetByPurchasing,
  calcPastEmissionData,
  calcSalesAmount,
  statusGraphHighchartOptions,
} from "./carbonReduceMBO.helper";
import { useSaveScenarioMutation } from "../../../modules/features/api/reduceMboApi";
import { deleteManage } from "../../../modules/features/api/endpoints/tscmsReduceMbo";

/**
 * @description 탄소저감 목표관리 > 목표관리 이행 현황 그래프
 * @author seoeunPark
 * @date 17/06/2024
 * @param {*}
 * @return
 */

const cssRoot = window.getComputedStyle(document.documentElement);
const GoalManagementStatusGraph = ({
  changeComponent,
  scenarioList,
  setScenarioList,
  baseYear,
  targetYear,
  form,
  setForm,
  dataList,
  setDataList,
  setBeforeDataList,
  decreaseMethod,
  baseEmissions,
  setApplyNeedList,
  refetchExistingScenarioList,
  fetchData,
}) => {
  const [popOpen, setPopOpen] = useState(false);
  const [popOpen2, setPopOpen2] = useState(false);

  const [dataLenth, setDataLenth] = useState(0);

  const [graphYearCategory, setGraphYearCategory] = useState([]);
  const [applyYears, setApplyYears] = useState([]);

  const [saveScenario, { isLoading }] = useSaveScenarioMutation();

  useEffect(() => {
    settingGraphYearCategory();
    settingApplyYear();

    initGraphData(1);
    initGraphData(2);
    initGraphData(3);
    initGraphData(4);
    initGraphData(5);
    initGraphData(6);

    initGraphData(3, true);
    initGraphData(4, true);
    initGraphData(5, true);
  }, [baseYear, targetYear, baseEmissions, decreaseMethod]);

  useEffect(() => {
    //console.log("Graph : dataList >>", dataList);
  }, [dataList]);

  useEffect(() => {
    //("form >>", form);
  }, [form]);

  /**
   * @description 적용연도 리스트 설정 함수 ? 기준년도부터인지, 기준년도+1 부터인지 확인 필요
   * @author seoeunPark
   */
  const settingApplyYear = () => {
    const temp = targetYear - baseYear; //개수 설정

    let newApplyYears = Array.from({ length: temp }, (_, index) => {
      return String(Number(baseYear) + index + 1); // 기준년도 + 1부터 시작
    });

    //console.log("newApplyYears >>", newApplyYears);

    setApplyYears(newApplyYears);
  };

  /**
   * @description 그래프 연도 카테고리 설정 및 과거 배출량 데이터 설정
   * @author seoeunPark
   */
  const settingGraphYearCategory = () => {
    const temp = targetYear - baseYear + 1 + 2; // 개수 설정
    setDataLenth(temp);

    let newGraphYearCategory = Array.from({ length: temp }, (_, index) => {
      return String(Number(baseYear - 2) + index); // 기준년도 - 2부터 시작
    });

    newGraphYearCategory.forEach((item, index) => {
      if (String(item) === String(baseYear)) {
        newGraphYearCategory[
          index
        ] = `<b style="color:#000">${item} (기준연도)</b>`;
      }
      if (String(item) === String(targetYear)) {
        newGraphYearCategory[
          index
        ] = `<b style="color:#000">${item} (타겟연도)</b>`;
      }
    });

    setGraphYearCategory(newGraphYearCategory);
  };

  /**
   * @description id에 해당하는 datalist 데이터 변경
   * @author seoeunPark
   */
  const setDataListFunc = (id, data, first) => {
    if (first) {
      setBeforeDataList((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, data };
          }
          return item;
        });
      });
    } else {
      setDataList((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, data };
          }
          return item;
        });
      });
    }
  };

  /**
   * @description 그래프 데이터 처음 셋팅
   */
  const initGraphData = (id, first = false) => {
    //기준연도 배출량 집약도(1) -- 전부
    //과거 배출량(2) -- 2년전, 1년전, 기준년도
    //순 배출량(3) -- 기준년도 +1 부터 타겟년도까지
    //감축활동에 따른 예상 감축량(4) -- 기준년도 +1 부터 타겟년도까지
    //배출권 구매로 인한 상세(5) -- 기준년도 +1 부터 타겟년도까지
    //1.5℃ 감축 시나리오(6) -- 기준년도 +1 부터 타겟년도까지

    // 기준년도 -2 부터 타겟년도까지 0으로 초기화
    let listLength = targetYear - baseYear + 1 + 2;
    let newGraphData = [];

    const baseYearEmission = calcPastEmissionData(
      decreaseMethod,
      baseEmissions && baseEmissions[0]?.baseCbem,
      form.baseYearValue
    );

    if (id === 1) {
      newGraphData = calcBaseYearEmission(
        baseYear,
        targetYear,
        baseYearEmission
      );
    } else if (id === 2) {
      newGraphData = Array.from({ length: listLength }, (_, index) => {
        if (index === 0) {
          // return Number(form.beforeBaseYear2Value);
          // console.log("baseEmissions >>", baseEmissions);
          // console.log("decreaseMethod >>", decreaseMethod);

          const value = calcPastEmissionData(
            decreaseMethod,
            baseEmissions && baseEmissions[0]?.baseCbem2,
            form.beforeBaseYear1Value
          );
          //console.log("왜 이 값이 나올까??", value);

          return Number(value);
        }
        if (index === 1) {
          // return Number(form.beforeBaseYear1Value);
          const value = calcPastEmissionData(
            decreaseMethod,
            baseEmissions && baseEmissions[0]?.baseCbem1,
            form.beforeBaseYear1Value
          );
          return Number(value);
        }
        if (index === 2) {
          // return Number(form.baseYearValue);
          const value = baseYearEmission;
          return Number(value);
        }
        return null;
      });
    } else if (id === 3) {
      const length = targetYear - baseYear;

      const result = Array.from({ length }, (_, index) => {
        return Number(baseYearEmission);
      });

      newGraphData = [null, null, null, ...result];
    } else if (id === 4) {
      let tempIdx = 0;
      let tempList = [];
      newGraphData = Array.from({ length: listLength }, (_, index) => {
        if (index === 0 || index === 1 || index === 2) {
          tempList.push(null);
        } else {
          tempIdx++;

          const temp = calcSalesAmount(
            form.baseYearValue,
            form.growthRate,
            targetYear,
            baseYear,
            tempIdx,
            decreaseMethod
          );
          tempList.push(temp);
          //console.log("temp >>>", temp);
        }

        return null;
      });
      setApplyNeedList(tempList);
    } else if (id === 5) {
      newGraphData = Array.from({ length: listLength }, (_, index) => {
        if (index === 0 || index === 1 || index === 2) {
          return null;
        } else {
          const length = targetYear - baseYear;

          const result = Array.from({ length }, (_, index) => {
            return Number(baseYearEmission);
          });

          //순 배출량
          const netEmi = [null, null, null, ...result];

          // 1.5도씨 감축시나리오 리스트
          const dataList = calc15CReductionScenario(
            baseYear,
            targetYear,
            baseYearEmission
          );

          const result2 = calcOffsetByPurchasing(
            netEmi[index],
            dataList[index]
          );

          return result2;
        }
      });
    } else if (id === 6) {
      newGraphData = calc15CReductionScenario(
        baseYear,
        targetYear,
        baseYearEmission
      );
    } else {
      newGraphData = Array.from({ length: listLength }, (_, index) => {
        return null;
      });
    }

    //console.log("newGraphData >>", id, ":", newGraphData);

    setDataListFunc(id, newGraphData, first);
  };

  /**
   * @description 시나리오 팝업 닫기
   */
  const pophandleClose = () => {
    setPopOpen(false);
  };
  /**
   * @description 시나리오 팝업 열기
   */
  const pophandleOpen = () => {
    setPopOpen(true);
  };

  /**
   * @description 시나리오 팝업 > 개선활동명 선택 팝업 닫기
   */
  const pophandleClose2 = () => {
    setPopOpen2(false);
  };
  /**
   * @description 시나리오 팝업 > 개선활동명 선택 팝업 열기
   */
  const pophandleOpen2 = () => {
    setPopOpen2(true);
  };

  // 다시하기 버튼 클릭
  const handleRestarBtn = () => {
    // 다시하기 로직
    if (!window.confirm("다시하기를 진행하시겠습니까?")) {
      return;
    }

    try {
      if (form.tarImpId !== undefined && form.tarImpId !== null) {
        deleteManage({ tarImpId: form.tarImpId });
      }

      setScenarioList([]);

      setForm((prev) => {
        return {
          ...prev,
          beforeBaseYear2Value: "",
          beforeBaseYear1Value: "",
          baseYearValue: "",
          growthRate: "",
        };
      });

      if (decreaseMethod === "Remi") return;

      changeComponent(0);
    } catch (error) {
      alert("다시하기에 실패하였습니다.");
    }
  };

  // 저장 버튼 클릭
  const handleSaveBtn = async () => {
    // 저장 로직
    if (!window.confirm("저장하시겠습니까?")) {
      return;
    }

    const params = {
      fcnRelTarImp: {
        baseYyyy: baseYear,
        reduceClCd: decreaseMethod,
        bf2TotVol: form.beforeBaseYear2Value,
        bf1TotVol: form.beforeBaseYear1Value,
        baseTotVol: form.baseYearValue,
        percent: form.growthRate,
      },
      fcnRelTarImpDtlList: scenarioList.map((item) => {
        return {
          tarImpDtlId: item.tarImpDtlId || null,
          rdcActNo: item.detail.rdcActNo,
          applyYn: item.checked ? "Y" : "N",
          applyYyyy: item.detail.applyYear,
        };
      }),
    };

    try {
      await  saveScenario(params); // 저장
      await refetchExistingScenarioList(); // 이후에 데이터를 재조회

      alert("저장되었습니다.");
    } catch (e) {
      alert("저장에 실패하였습니다.");
    } finally {
      fetchData();
    }
  };

  // 하이차트 옵션 useMemo 적용
  // const options = statusGraphHighchartOptions({ graphYearCategory }, dataList);
  const options = useMemo(() => {
    return statusGraphHighchartOptions(
      { graphYearCategory },
      dataList,
      decreaseMethod
    );
  }, [dataList]);

  return (
    <>
      <EmissionReductionScenarioPopup
        open={popOpen}
        onClose={pophandleClose}
        open2={popOpen2}
        onClose2={pophandleClose2}
        handleOpen2={pophandleOpen2}
        scenarioList={scenarioList}
        setScenarioList={setScenarioList}
        baseYear={baseYear}
        applyYears={applyYears}
      />
      <div className="CarbonBox">
        <div className="GraphInBtn">
          <button onClick={pophandleOpen}>배출량 감축 시나리오 추가</button>
        </div>
        <div id="PurpoChart">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            key={JSON.stringify(options)}
          />
        </div>
      </div>
      <div className="PurposeSubmit">
        <button className="cancle" onClick={handleRestarBtn}>
          다시하기
        </button>
        <button onClick={handleSaveBtn}>저장</button>
      </div>
    </>
  );
};

export default GoalManagementStatusGraph;
