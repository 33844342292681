import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./css/Carbon.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./modules/store";
import { LoadingFull } from "./components/common/loading/LoadingFull";
import { LoadingProvider } from "./components/util/LoadingContext";

// const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<LoadingFull></LoadingFull>}>
          <LoadingProvider>
            <App />
          </LoadingProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
