import React, { useEffect, useState } from "react";
import { menuList } from "../CarbonNote/common/menuList";
import { Link, useLocation } from "react-router-dom";

function LNB() {
  const [activeMenu, setActiveMenu] = useState("");
  const pathname = useLocation().pathname.split("/")[1];
  const token = localStorage.getItem("token");

  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  useEffect(() => {
    setActiveMenu(pathname);
  }, [pathname]);

  const isActive = (item) => {
    const address = item.address?.split("/")[1];
    const address2 = item.address2?.split("/")[1];

    let result = "";

    if (activeMenu === address) {
      result = "on";
    } else if (activeMenu === address2) {
      result = "on";
    } else {
      result = "";
    }

    return result;
  };

  const getBtnName = () => {
    if (token) {
      return "로그아웃";
    } else {
      return "로그인";
    }
  };

  return (
    <div className="TSCMSLnb">
      <h1>
        <span>
          CarbonNote
         {/* <br />
          Management
          <br />
          Solution*/}
        </span>
      </h1>

      {token &&
        menuList.map((menu, index) => (
          <ul key={index} className={`mn${index + 1}_sub`}>
            {menu.itemList &&
              menu.itemList.map((item, index2) => {
                return (
                  <li className={isActive(item)} key={index2}>
                    <Link to={item.address} key={index2}>
                      {item.item}
                    </Link>
                  </li>
                );
              })}
          </ul>
        ))}

      <button className="LogOutBtn" onClick={logout}>
        {getBtnName()}
      </button>

      <div className="weatherInfo">
        <dl>
          <dt>제주특별자치도 서귀포시 대정읍</dt>
          <dd>
            <strong>12°</strong>
            <div>
              <p>
                날씨 <b>구름많음</b>
              </p>
              <p>
                습도 <b>80%</b>
              </p>
            </div>
          </dd>
          <dd>
            <p>
              통신상태 <b>좋음</b>
            </p>
          </dd>
        </dl>
      </div>
    </div>
  );
}

export default LNB;
