// src/components/CarbonNote/DashBoard/EmissionStatistics.jsx

import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Link } from "react-router-dom";
import {
  monthlyEmissionsData,
  yearlyEmissionsData,
  getEmissionsDataForChart,
} from "./DashBoardData";
import { formatNumber } from "../../CarbonNote/common/commonData";

/**
 * @description DashBoard 컨테이너 → 월별 배출량/연도별 배출량
 * @author Choi Seon ha
 * @date 17/07/2024
 * @return {JSX.Element}
 */

const EmissionStatistics = ({ emissionMonthStatisticsData, emissionYearStatisticsData }) => {
  const cssRoot = window.getComputedStyle(document.documentElement);
  const [isMonthly, setIsMonthly] = useState(true);
  const [categories, setCategories] = useState([]);
  const [scope1Data, setScope1Data] = useState([]);
  const [scope2Data, setScope2Data] = useState([]);
  const [scope3Data, setScope3Data] = useState([]);
  const [totalEmiData, setTotalEmiData] = useState([]);
  const [totalList, setTotalList] = useState([]);

  useEffect(() => {
    //console.log("emissionMonthStatisticsData >>", emissionMonthStatisticsData);
    //console.log("emissionYearStatisticsData >>", emissionYearStatisticsData);
    if (isMonthly && emissionMonthStatisticsData) {
      const months = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];
      const categories = emissionMonthStatisticsData.map(
        (data) => `${parseInt(data.mm, 10)}월`
      );
      setCategories(categories);

      const scope1Data = new Array(12).fill(null);
      const scope2Data = new Array(12).fill(null);
      const scope3Data = new Array(12).fill(null);
      const totalEmi = new Array(12).fill(null);

      emissionMonthStatisticsData.forEach((data) => {
        const monthIndex = parseInt(data.mm, 10) - 1;
        if (data.scope === "S1") {
          scope1Data[monthIndex] = {
            y: data.scopeEmi,
            total: data.totalScopeEmi,
          };
        } else if (data.scope === "S2") {
          scope2Data[monthIndex] = {
            y: data.scopeEmi,
            total: data.totalScopeEmi,
          };
        } else if (data.scope === "S3") {
          scope3Data[monthIndex] = {
            y: data.scopeEmi,
            total: data.totalScopeEmi,
          };
        }
        totalEmi[monthIndex] = data.totalScopeEmi;
      });

      setCategories(months);
      setScope1Data(scope1Data);
      setScope2Data(scope2Data);
      setScope3Data(scope3Data);
      setTotalEmiData(totalEmi);
    } else if (!isMonthly && emissionYearStatisticsData) {
      // year 데이터 처리 로직
      // const filteredYearData = emissionYearStatisticsData.filter(
      //   (data) => data.scope !== "S3"
      // );

      const categories = [
        ...new Set(
          emissionYearStatisticsData.map((data) => data.yyyy)
        ),
      ];
      setCategories(categories); // X축에 표시할 연도 설정

      const scope1Data = new Array(categories.length).fill(null);
      const scope2Data = new Array(categories.length).fill(null);
      const scope3Data = new Array(categories.length).fill(null);
      const totalEmi = new Array(categories.length).fill(null);

      emissionYearStatisticsData.forEach((data) => {
        const yearIndex = categories.indexOf(data.yyyy); // 연도에 해당하는 인덱스 찾기
        if (data.scope === "S1") {
          scope1Data[yearIndex] = {
            y: data.scopeEmi,
            total: data.totalScopeEmi,
          };
        } else if (data.scope === "S2") {
          scope2Data[yearIndex] = {
            y: data.scopeEmi,
            total: data.totalScopeEmi,
          };
        } else if (data.scope === "S3") {
          scope3Data[yearIndex] = {
            y: data.scopeEmi,
            total: data.totalScopeEmi,
          };
        }
        totalEmi[yearIndex] = data.totalScopeEmi;
      });

      setScope1Data(scope1Data);
      setScope2Data(scope2Data);
      setScope3Data(scope3Data);
      setTotalEmiData(totalEmi);
    }
  }, [isMonthly, emissionMonthStatisticsData, emissionYearStatisticsData]);

  useEffect(() => {
    const totalList = scope1Data.map((data, index) => {
      if (!data || scope2Data[index]) {
        return { data: scope2Data[index]?.total || 0 };
      }
      if (!data || scope3Data[index]) {
        return { data: scope3Data[index]?.total || 0 };
      }
      return {
        data: data.total || 0,
      };
    });

    setTotalList(totalList);
  }, [scope1Data, scope2Data, scope3Data]);

  const setGraphMaxMin = (totalList) => {
    const max = Math.max(...totalList.map((data) => data.data));
    const min = Math.min(...totalList.map((data) => data.data));
    return {
      max: max + (max - min) * 0.1,
      min: min - (max - min) * 0.1,
    };
  };

  const chartOptions = {
    chart: {
      type: "column",
      style: {
        fontFamily: "Noto Sans KR",
      },
      spacingTop: 40,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    title: {
      text: null,
    },
    accessibility: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        style: {
          color: cssRoot.getPropertyValue("--gray500"),
        },
      },
      lineColor: cssRoot.getPropertyValue("--line20"),
    },
    yAxis: {
      min: 0,
      max: setGraphMaxMin(totalList).max,
      title: {
        text: null,
      },
      tickInterval: setGraphMaxMin(totalList).max < 5000 ? null : 5000,
      labels: {
        format: "{value:,.0f}",
        style: {
          color: cssRoot.getPropertyValue("--gray500"),
        },
      },
      gridLineDashStyle: "longdash",
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      floating: true,
      reversed: true,
      shadow: false,
      y: -45,
    },
    plotOptions: {
      column: {
        borderRadius: 4,
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
        minPointLength: 3,
      },
    },
    series: [
      {
        name: "Scope3",
        color: cssRoot.getPropertyValue("--scope3"),
        data: scope3Data,
        borderWidth: 0,
      },
      {
        name: "Scope2",
        color: cssRoot.getPropertyValue("--scope2"),
        data: scope2Data,
        borderWidth: 0,
      },
      {
        name: "Scope1",
        color: cssRoot.getPropertyValue("--scope1"),
        data: scope1Data,
        borderWidth: 0,
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: "<b>{point.key}</b><br/><table>",
      pointFormat: `<tr>
                      <td style="color: {series.color}">{series.name}: </td>
                      <td style="text-align: right"><b>{point.y} tCO2e</b></td>
                    </tr>`,
      footerFormat: "</table>",
      valueDecimals: 2,
      formatter: function () {
        if (!this.points || this.points.length === 0) {
          return "";
        }

        // Sort points so Scope1 is above Scope2 in the tooltip
        this.points.sort((a, b) => {
          const scopeOrder = {
            Scope1: 1,
            Scope2: 2,
            Scope3: 3,
          };
          return scopeOrder[a.series.name] - scopeOrder[b.series.name];
        });

        let tooltip = `<b>${this.x}</b><br/><table>`;
        this.points.forEach((point) => {
          tooltip += `<tr>
                        <td style="color: ${point.series.color}">${
            point.series.name
          }:</td>
                        <td style="text-align: right"><b>${formatNumber(
                          point.y,
                          3
                        )}</b> tCO2e</td>
                      </tr>`;
        });

        const monthIndex = this.points[0].point.index;
        const totalScopeEmi = totalEmiData[monthIndex];

        tooltip += `<tr>
                      <td><b>전체 배출량:</b></td>
                      <td style="text-align: right"><b>${formatNumber(
                        totalScopeEmi,
                        3
                      )}</b> tCO2e</td>
                    </tr>`;
        tooltip += "</table>";
        return tooltip;
      },
    },
  };

  return (
    <div className="DashGridItem span3">
      <p className="DashItemTitle">
        <b>
          <Link
            to="#"
            className={isMonthly ? "on" : ""}
            onClick={() => setIsMonthly(true)}
          >
            월별 배출량
          </Link>
          <em>/</em>
          <Link
            to="#"
            className={!isMonthly ? "on" : ""}
            onClick={() => setIsMonthly(false)}
          >
            연도별 배출량
          </Link>
        </b>
        <span>(단위 : tCO2e)</span>
      </p>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default EmissionStatistics;

