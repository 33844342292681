import React from "react";
import styled, { keyframes } from "styled-components";
import LoadingProgress from "../../../images/Carbon/loading.svg";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
  animation: ${fadeIn} 0.3s ease;
`;

const Spinner = styled.div`
  /*border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 5px solid white;
  width: 40px;
  height: 40px;*/
    background: url(${LoadingProgress}) no-repeat 50% 50%;
    background-size: 100%;
    width: 80px;
    height: 80px;
  animation: ${spin} 2s linear infinite;
`;

const LoadingText = styled.div`
  margin-top: 10px;
  color: white;
  font-weight: bold;
`;

export const LoadingFull = () => (
  <LoadingOverlay>
    <div>
      <Spinner />
        {/*<LoadingText>Loading...</LoadingText>*/}
    </div>
  </LoadingOverlay>
);
