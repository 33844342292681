import { instance } from "../api";

/**
 * @description 연도 조회
 * @author choiseonha
 * @date 09/07/2024
 * @param {*} builder
 */
const fetchCalYears = (builder) => {
  return builder.query({
    query: () => ({
      url: `/api/cal/year`,
      method: "GET",
    }),
  });
};

/**
 * @description 로그인 한 사용자의 전체 사업장 리스트 조회
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchCalCompanies = (builder) => {
  return builder.query({
    query: ({ inloNo }) => {
      if (!inloNo) {
        throw new Error("inloNo is required");
      }
      const params = {
        inloNo: inloNo,
      };
      return {
        url: "/api/cal/fac",
        method: "GET",
        params,
      };
    },
  });
};

/**
 * @description 초기 문구 조회
 * @author choiseonha
 * @date 08/07/2024
 * @param {*} builder
 */
const fetchInitEmiCal = (builder) => {
  return builder.query({
    query: () => ({
      url: `/api/cal/iec`,
      method: "GET",
    }),
  });
};

/**
 * @description 폼 데이터 저장
 * @author choiseonha
 * @date 12/07/2024
 * @param {*} builder
 */
const fetchFormSave = (builder) => {
  return builder.mutation({
    query: (formData) => {
      return {
        url: `/api/cal/sut`,
        method: "POST",
        body: formData,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 템플릿 조회
 * @author choiseonha
 * @date 10/07/2024
 * @param {*} builder
 */
const fetchTemplate = (builder) => {
  return builder.query({
    query: ({ yyyyMm, inloNo }) => {
      const params = {};
      if (yyyyMm) params.yyyyMm = yyyyMm;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/cal/gitt`,
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 템플릿 조회
 * @author choiseonha
 * @date 10/07/2024
 * @param {*} builder
 */
export const axiosFetchTemplate = async (params) => {
  const { yyyyMm = null, inloNo = null } = params;
  const response = await instance.get(`/api/cal/gitt`, { params });
  return response.data;
};

/**
 * @description 배출시설 조회
 * @author choiseonha
 * @date 20/06/2024
 * @param {*} builder
 */
const fetchFactoryList = (builder) => {
  return builder.query({
    query: ({ inloNo }) => ({
      url: `/api/cal/gfl`,
      method: "GET",
      params: { inloNo },
    }),
  });
};

/**
 * @description 해당 사업장의 담당자 리스트 조회
 * @author choiseonha
 * @date 19/07/2024
 * @param {*} builder
 */
const fetchManager = (builder) => {
  return builder.query({
    query: ({ inloNo }) => ({
      url: `/api/cal/grs`,
      method: "GET",
      params: { inloNo },
    }),
  });
};

/**
 * @description 연료 옵션 조회
 * @author choiseonha
 * @date 20/06/2024
 * @param {*} builder
 */
const fetchFuelOptions = (builder) => {
  return builder.query({
    query: () => ({
      url: `/api/fuel-options`,
      method: "GET",
    }),
  });
};

/**
 * @description 단위 옵션 조회
 * @author choiseonha
 * @date 20/06/2024
 * @param {*} builder
 */
const fetchUnitOptions = (builder) => {
  return builder.query({
    query: ({ fuel }) => ({
      url: `/api/unit-options`,
      method: "GET",
      params: { fuel },
    }),
  });
};

/**
 * @description 탭 정보와 폼 데이터 조회
 * @author choiseonha
 * @date 24/06/2024
 * @param {*} builder
 */
const fetchTabsAndFormData = (builder) => {
  return builder.query({
    query: ({ year, month, company }) => ({
      url: "/api/tabs-and-form-data",
      method: "GET",
      params: { year, month, company },
    }),
  });
};

/**
 * @description 저장된 카테고리 조회
 * @author choiseonha
 * @date 19/07/2024
 * @param {*} builder
 */
const fetchCategory = (builder) => {
  return builder.query({
    query: ({ yyyyMm, inloNo }) => {
      const params = {};
      if (yyyyMm) params.yyyyMm = yyyyMm;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/cal/gsc`,
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 저장된 카테고리 조회
 * @author choiseonha
 * @date 03/08/2024
 * @param {*} builder
 */
export const axiosFetchCategory = async (params) => {
  const { yyyyMm = null, inloNo = null } = params;
  const response = await instance.get(`/api/cal/gsc`, { params });
  return response.data;
};

/**
 * @description 이전 내역 불러오기
 * @author choiseonha
 * @date 13/08/2024
 * @param {*} builder
 */
export const axiosFetchBeforeSaved = async (params) => {
  const { yyyyMm = null, inloNo = null } = params;
  const response = await instance.get(`/api/cal/gph`, { params });
  return response.data;
};

/**
 * @description 저장 및 배출량 산정
 * @author choiseonha
 * @date 30/07/2024
 * @param {*} builder
 */
const insertStepValue = (builder) => {
  return builder.mutation({
    query: ({ EmiCalDto, yyyyMm, inloNo }) => {
      return {
        url: `/api/cal/sec?yyyyMm=${yyyyMm}&inloNo=${inloNo}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(EmiCalDto),
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 배출구문 재설정
 * @author choiseonha
 * @date 03/08/2024
 * @param {*} builder
 */
export const axiosDeleteCatgTemp = async (params) => {
  const { yyyy = null, inloNo = null, userNo = null } = params;
  const response = await instance.delete(`/api/cal/dct`, { params });
  return response;
};

/**
 * @description Scope3 산업 분류 옵션 조회
 * @author choiseonha
 * @date 27/08/2024
 * @param {*} builder
 */
const fetchScope3Sector = (builder) => {
  return builder.query({
    query: () => ({
      url: `/api/cal/gs3s`,
      method: "GET",
    }),
  });
};

/**
 * @description Scope3 카테고리 비율
 * @author choiseonha
 * @date 28/08/2024
 * @param {*} builder
 */
export const axiosScope3Catg = async (params) => {
  // `/api/cal/gs3c`
  const { sector = null } = params;
  const response = await instance.get(`/api/cal/gs3c`, { params });
  return response;
};


/**
 * @description 구매제품 및 서비스, 업스트림 운송&유통
 * @author choiseonha
 * @date 29/08/2024
 * @param {*} builder
 */
export const axiosgetCdName = async(categories) => {

  const params = new URLSearchParams();

  categories.forEach(category => {
    params.append('cdNames', category);
  });

  const response = await instance.get(`/api/cal/gcn`, {
    params: params
  });
  return response;
};




export const tscmsCalculationEndPoints = (builder) => ({
  fetchFormSave: fetchFormSave(builder),
  fetchTemplate: fetchTemplate(builder),
  fetchManager: fetchManager(builder),
  fetchFactoryList: fetchFactoryList(builder),
  fetchFuelOptions: fetchFuelOptions(builder),
  fetchUnitOptions: fetchUnitOptions(builder),
  fetchTabsAndFormData: fetchTabsAndFormData(builder),
  fetchInitEmiCal: fetchInitEmiCal(builder),
  fetchCalYears: fetchCalYears(builder),
  fetchCalCompanies: fetchCalCompanies(builder),
  fetchCategory: fetchCategory(builder),
  insertStepValue: insertStepValue(builder),
  fetchScope3Sector: fetchScope3Sector(builder),
});
