import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../../modules/features/api/endpoints/auth";
/**
 * @description 시현용 로그인 페이지
 * @author seoeunPark
 * @date 25/07/2024
 * @param {*}
 * @return
 */

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  //로그인 상태 확인
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  //로그인 요청
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const credential = { username, password };
    try {
      const response = await login(credential);

      //console.log("response >>", response);

      if (response) {
        const token = response.headers.get("authorization");
        localStorage.setItem("token", token);
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("inloNo", response.data.inloNo);
        navigate("/");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="LoginWrap">
      <div className="LoginForm">
        <h1>회원 로그인</h1>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
