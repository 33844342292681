// src/components/CarbonNote/DashBoard/DataCollectionStatus.jsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { dataCollectionStatusData } from "./DashBoardData";
import { formatNumber } from "./../common/commonData";

/**
 * @description DashBoard 컨테이너 → 데이터 수집 현황
 * @return {JSX.Element}
 */

const DataCollectionStatus = ({ dataCollectionStatusData, selectedYear }) => {
  //console.log("dataCollectionStatusData :", dataCollectionStatusData);

  const navigate = useNavigate();

  const handleDetailClick = () => {
    const stateData = {};
    stateData.year = selectedYear;
    navigate("/history", { state: stateData });
  };

  const getStatusClass = (rate) => {
    if (rate >= 80) return "red";
    if (rate >= 50) return "yellow";
    return "black";
  };

  return (
    <div className="DashGridItem">
      <p className="DashItemTitle">
        <b>데이터 수집 현황</b>
        <button onClick={handleDetailClick}>자세히 보기</button>
      </p>
      <table>
        <colgroup>
          <col width="30%" />
          <col width="30%" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>사업장명</th>
            <th style={{ textAlign: "right" }}>미수집율(%)</th>
            <th style={{ textAlign: "right" }}>미입력 데이터</th>
          </tr>
        </thead>
        <tbody>
          {dataCollectionStatusData?.map((item, index) => (
            <tr key={index}>
              <td>{item.inloName}</td>
              <td
                style={{ textAlign: "right" }}
                className={getStatusClass(item.nonRate)}
              >
                {formatNumber(item.nonRate, 2)}
              </td>
              <td style={{ textAlign: "right" }}>
                {item.zeroCount} / {item.totalCount}개
              </td>
            </tr>
          )) || null}
        </tbody>
      </table>
    </div>
  );
};

export default DataCollectionStatus;
