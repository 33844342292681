import { instance } from "../api";
/**
 * @description 탄소저감 목표관리 시나리오 이력 조회
 * @author seoeunPark
 * @date 02/07/2024
 * @param {*}
 * @return
 */
const fetchScenarioHistory = (builder) => {
  return builder.query({
    query: ({ reduceClCd, baseYyyy }) => {
      const params = {};
      if (reduceClCd) params.reduceClCd = reduceClCd;
      if (baseYyyy) params.baseYyyy = baseYyyy;
      return {
        url: `/api/manage`,
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 배출량 감축 시나리오 선택 > 업종 선택 후
 * @author seoeunPark
 * @date 10/07/2024
 * @param {*}
 * @return
 */
const fetchRdtActionOptions = (builder) => {
  return builder.query({
    query: ({ step, sectorClCd, rdcFacClCd, rdcClCd, rdcActNo }) => {
      const params = {};
      if (step) params.step = step;
      if (sectorClCd) params.sectorClCd = sectorClCd;
      if (rdcFacClCd) params.rdcFacClCd = rdcFacClCd;
      if (rdcClCd) params.rdcClCd = rdcClCd;
      if (rdcActNo) params.rdcActNo = rdcActNo;

      return {
        url: `/api/manage/options`,
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

export const getRdtActionOptions = async (params) => {
  const {
    step = null,
    sectorClCd = null,
    rdcFacClCd = null,
    rdcClCd = null,
    rdcActNo = null,
  } = params;

  const response = await instance.get(`/api/manage/options`, {
    params,
  });

  return response.data.data;
};

/**
* @description 탄소저감 목표관리 시나리오 저장
* @author seoeunPark
* @date 17/07/2024
* @param 
{
    "baseYyyy": "2024",
    "reduceClCd": "CODE123",
    "bf2TotVol": 1000,
    "bf1TotVol": 2000,
    "baseTotVol": 3000,
    "percent": "10",
    "tarImpDtls": [
     {
            "rdcActNo": 33,
            "applyYyyy": "2023",
        },
        {
            "rdcActNo": 44,
            "applyYyyy": "2023",
        }
  ]
} 
*/
const saveScenario = (builder) => {
  return builder.mutation({
    query: (params) => {
      const manageDto = params;
      return {
        url: `/api/manage`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(manageDto),
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 기준연도 , 기준연도-1, 기준연도-2 의 기준배출량 가져오기
 * @author seoeunPark
 * @date 17/07/2024
 * @param {*}
 * @return
 */
const fetchBaseEmissions = (builder) => {
  return builder.query({
    query: ({ year }) => {
      const params = {};
      if (year) params.year = year;
      return {
        url: `/api/manage/base-emissions`,
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 탄소저감 목표관리 시나리오 정보 조회
 * @author seoeunPark
 * @date 29/07/2024
 * @param {Numver} rdcActNo
 * @return
 */

export const getRdcActInfo = async (params) => {
  const { rdcActNo = null } = params;

  const response = await instance.get(`/api/manage/rdcActInfo`, {
    params,
  });

  return response.data.data;
};

/**
 * @description 탄소저감 목표관리 이행현황 삭제
 * @author seoeunPark
 * @date 14/08/2024
 * @param {*}
 * @return
 */
export const deleteManage = async (params) => {
  const { tarImpId = null } = params;

  const response = await instance.delete(`/api/manage/${tarImpId}`);

  return response.data;
};

/**
 * @description 탄소저감 목표관리 시나리오 삭제
 * @author seoeunPark
 * @date 14/08/2024
 * @param {*}
 * @return
 */
export const deleteManageScenario = async (params) => {
  const { tarImpDtlId = null } = params;

  const response = await instance.delete(`/api/manage/scenario/${tarImpDtlId}`);

  return response.data;
};

export const tscmsReduceMboEndpoints = (builder) => ({
  fetchScenarioHistory: fetchScenarioHistory(builder),
  fetchRdtActionOptions: fetchRdtActionOptions(builder),
  saveScenario: saveScenario(builder),
  fetchBaseEmissions: fetchBaseEmissions(builder),
});
