import { useEffect, useState } from "react";
import {
  useFetchYearsQuery,
  useFetchCompaniesQuery,
} from "../../../modules/features/api/commonApi";

export const useYearsData = (setSelectedYear) => {
  const [years, setYears] = useState([]);
  const {
    data: yearsData,
    error: yearsError,
    isLoading: isYearsLoading,
  } = useFetchYearsQuery();

  useEffect(() => {
    if (yearsData && Array.isArray(yearsData.data)) {
      const sortedYears = [...yearsData.data].sort((a, b) => b - a); // 내림차순 정렬을 위한 복사본
      const formattedYears = sortedYears.map((year) => ({
        value: year.toString(),
        label: `${year}년`,
      }));
      setYears(formattedYears);
      if (setSelectedYear) {
        setSelectedYear(formattedYears[0].value);
      }
    }
  }, []);

  return { years, yearsError, isYearsLoading };
};

export const useCompaniesData = (setSeletedCompany) => {
  const [companies, setCompanies] = useState([]);
  const {
    data: companiesData,
    error: companiesError,
    isLoading: isCompaniesLoading,
  } = useFetchCompaniesQuery();

  useEffect(() => {
    if (companiesData && Array.isArray(companiesData.data)) {
      //console.log("company data : ", companiesData.data);
      const formattedCompanies = companiesData.data.map((company) => ({
        value: company.lowerInloNo,
        label: company.inloName,
      }));
      setCompanies(formattedCompanies);
      if (setSeletedCompany) {
        setSeletedCompany(formattedCompanies[0].value);
      }
    }
  }, []);

  return { companies, companiesError, isCompaniesLoading };
};

// 올해를 제외한 2016년부터 년도를 반환 (2023년부터 2016년까지)
export const getMboYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = currentYear - 1; i >= 2016; i--) {
    yearOptions.push({ value: i.toString(), label: `${i}년` });
  }
  return yearOptions;
};

export const yearOptionList = [
  { value: "2024", label: "2024년" },
  { value: "2023", label: "2023년" },
  { value: "2022", label: "2022년" },
  { value: "2021", label: "2021년" },
  { value: "2020", label: "2020년" },
  { value: "2019", label: "2019년" },
  { value: "2018", label: "2018년" },
  { value: "2017", label: "2017년" },
  { value: "2016", label: "2016년" },
];

export const monthOptionList = [...Array(12)].map((_, index) => {
  const month = index + 1;
  return {
    value: month < 10 ? `${month}` : month.toString(),
    label: `${month}월`,
  };
});

// 16년부터 오늘까지의 년도 + 1년까지의 년도를 반환
export const getYearOptionList = () => {
  const currentYear = new Date().getFullYear();
  const yearList = [];
  const firstYear = 2016;

  if (new Date().getMonth() === 11) {
    for (let i = currentYear + 1; i >= firstYear; i--) {
      yearList.push({ value: i.toString(), label: `${i}년` });
    }
  } else {
    for (let i = currentYear; i >= firstYear; i--) {
      yearList.push({ value: i.toString(), label: `${i}년` });
    }
  }

  return yearList;
};

// 숫자를 3자리마다 콤마(,)를 찍어주는 함수
export const formatNumber = (inputNumber, decimalPoint = null) => {
  let isNegative = false;

  if (typeof inputNumber !== "string") {
    inputNumber = String(inputNumber);
  }

  // 음수 체크
  if (inputNumber.startsWith("-")) {
    isNegative = true;
    inputNumber = inputNumber.substring(1);
  }

  let [integer, decimal] = inputNumber.split(".");

  integer = integer.replace(/[^0-9]/g, "").substring(0, 10); // 정수 부분 10자리 제한
  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // 음수일 경우 다시 음수 기호 추가
  if (isNegative) {
    integer = "-" + integer;
  }

  if (decimalPoint === null) decimalPoint = 5;

  if (decimal !== undefined) {
    const roundedNumber = Number(
      `${integer.replace(/,/g, "")}.${decimal}`
    ).toFixed(decimalPoint);
    let [_, roundedDecimal] = roundedNumber.split(".");
    roundedDecimal = roundedDecimal.replace(/0+$/, "");

    // 소수점이 없으면 정수만 반환
    if (roundedDecimal === "") {
      return integer;
    }

    return `${integer}.${roundedDecimal}`;
  }
  return integer;
};

/* 오늘 날짜를 반환하는 메소드로 파라미터로 반환을 원하는 날짜 형식과, 반환 데이터를 지정할 수 있음
 ex) getToday("yyyy-mm-dd") -> "2021-09-01",
 getToday("mm") => "09"
 */
export const getToday = (format) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  let result = format;
  result = result.replace(/yyyy/g, year);
  result = result.replace(/mm/g, month < 10 ? `${month}` : month);
  result = result.replace(/dd/g, day < 10 ? `0${day}` : day);

  return result;
};
