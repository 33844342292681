// globalUtils.js
const GlobalUtils = {
  makeOptions: (label, value, objList, defaultFirst = false) => {
    // console.log("GlobalUtils objList >>", objList);

    const resposne = objList.map((obj) => {
      // obj[label] 의 값이 없는경우나 "" 인 경우, obj[value]를 label로 사용
      if (!obj[label] || obj[label] === "") {
        return { label: obj[value], value: obj[value] };
      }
      return { label: obj[label], value: obj[value] };
    });
    if (!defaultFirst) {
      resposne.unshift({ label: "선택하세요", value: "" });
    }
    return resposne;
  },
};

export default GlobalUtils;
