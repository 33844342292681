/**
 * @description 연도 조회
 * @author choiseonha
 * @date 09/07/2024
 * @param {*} builder
 */
const fetchHomeYears = (builder) => {
  return builder.query({
    query: () => ({
      url: `/api/home/year`,
      method: "GET",
    }),
  });
};

/**
 * @description 로그인 한 사용자의 전체 사업장 리스트 조회
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchHomeCompanies = (builder) => {
  return builder.query({
    query: () => ({
      url: `/api/home/facs`,
      method: "GET",
    }),
  });
};

/**
 * @description 전체 배출량 조회
 * @author choiseonha
 * @date 17/07/2024
 * @param {*} builder
 */
const fetchTotalEmission = (builder) => {
  return builder.query({
    query: ({ year, inloNo }) => {
      const params = {};
      if (year) params.yyyy = year;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/home/te`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 목표관리 조회
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchGoalManagement = (builder) => {
  return builder.query({
    query: ({ yyyy, inloNo }) => {
      const params = {};
      if (yyyy) params.yyyy = yyyy;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/home/tm`,
        method: "GET",
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description Scope 별 배출량 조회
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchEmissionByScope = (builder) => {
  return builder.query({
    query: ({ year, inloNo }) => {
      const params = {};
      if (year) params.yyyy = year;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/home/ebs`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 월별 배출량 조회
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchEmissionMonthStatistics = (builder) => {
  return builder.query({
    query: ({ year, inloNo }) => {
      const params = {};
      if (year) params.yyyy = year;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/home/ge/month`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};


/**
 * @description 연도별 배출량 조회
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchEmissionYearStatistics = (builder) => {
  return builder.query({
    query: ({ inloNo }) => {
      const params = {};
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/home/ge/year`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};


/**
 * @description 데이터 수집 현황 조회
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchDataCollectionStatus = (builder) => {
  return builder.query({
    query: ({ year, inloNo }) => {
      const params = {};
      if (year) params.yyyy = year;
      if (inloNo) params.inloNo = inloNo;
      let url = `/api/home/dcs`;
      return {
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 사업장별 배출량 조회
 * @author choiseonha
 * @date 17/07/2024
 * @param {*} builder
 */
const fetchEmissionByBusiness = (builder) => {
  return builder.query({
    query: ({ year, inloNo }) => {
      const params = {};
      if (year) params.yyyy = year;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/home/ebf`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 최근 이력 조회
 * @author choiseonha
 * @date 05/08/2024
 * @param {*} builder
 */
const fetchRecentHistory = (builder) => {
  return builder.query({
    query: ({ year, inloNo }) => {
      const params = {};
      if (year) params.yyyy = year;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/home/rh`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 온실가스 최다 배출 사업장
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchHighestEmission = (builder) => {
  return builder.query({
    query: ({ year, inloNo }) => {
      const params = {};
      if (year) params.yyyy = year;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/home/lei`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 온실가스 최다 배출 시설
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchHighestFactory = (builder) => {
  return builder.query({
    query: ({ year, inloNo, type }) => {
      const params = {};
      if (year) params.yyyy = year;
      if (inloNo) params.inloNo = inloNo;
      if (type) params.type = type;
      return {
        url: `/api/home/lef`,
        mothod: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
}

/**
 * @description 배출량 산정 문의 등록
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const submitContactEmail = (builder) => {
  return builder.mutation({
    query: ( form ) => ({
      url: `/api/home/contact`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    }),
    transformResponse: (response) => {
      return response.data;
    },
  });
};

export const tscmsDashBoardEndpoints = (builder) => ({
  fetchHomeYears: fetchHomeYears(builder),
  fetchHomeCompanies: fetchHomeCompanies(builder),
  fetchTotalEmission: fetchTotalEmission(builder),
  fetchGoalManagement: fetchGoalManagement(builder),
  fetchEmissionByScope: fetchEmissionByScope(builder),
  fetchEmissionMonthStatistics: fetchEmissionMonthStatistics(builder),
  fetchEmissionYearStatistics: fetchEmissionYearStatistics(builder),
  fetchDataCollectionStatus: fetchDataCollectionStatus(builder),
  fetchEmissionByBusiness: fetchEmissionByBusiness(builder),
  fetchRecentHistory: fetchRecentHistory(builder),
  fetchHighestEmission: fetchHighestEmission(builder),
  fetchHighestFactory: fetchHighestFactory(builder),
  submitContactEmail: submitContactEmail(builder),
});
