import React, { useEffect, useState, useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuProps from "../../../components/styleModule/MenuProps";
import {
  useFetchHomeYearsQuery,
  useFetchHomeCompaniesQuery,
  useFetchTotalEmissionQuery,
  useFetchGoalManagementQuery,
  useFetchEmissionByScopeQuery,
  useFetchEmissionMonthStatisticsQuery,
  useFetchEmissionYearStatisticsQuery,
  useFetchDataCollectionStatusQuery,
  useFetchEmissionByBusinessQuery,
  useFetchRecentHistoryQuery,
  useFetchHighestEmissionQuery,
  useFetchHighestFactoryQuery,
} from "../../../modules/features/api/dashBoardApi";

/* Components */
import TotalEmission from "../DashBoard/TotalEmission";
import GoalManagement from "../DashBoard/GoalManagement";
import EmissionByScope from "../DashBoard/EmissionByScope";
import EmissionStatistics from "../DashBoard/EmissionStatistics";
import DataCollectionStatus from "../DashBoard/DataCollectionStatus";
import EmissionByBusiness from "../DashBoard/EmissionByBusiness";
import HighestEmissionCompany from "../DashBoard/HighestEmissionCompany";
import HighestEmissionFactory from "../DashBoard/HighestEmissionFactory";
import RecentHistory from "../DashBoard/RecentHistory";

import { useFetchUserInloNameQuery } from "../../../modules/features/api/commonApi";
import { jwtDecode } from "jwt-decode";
import ContactEmailPopup from "../DashBoard/ContactEmailPopup";

const DashBoard = () => {
  const [yearData, setYearData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedCompany, setSeletedCompany] = useState("");
  const [popOpen, setPopOpen] = React.useState(false);
  const [decodedToken, setDecodedToken] = useState(null);

  const {
    data: yearsData,
    error: yearsError,
    isLoading: isYearsLoading,
  } = useFetchHomeYearsQuery();

  const {
    data: companiesData,
    error: companiesError,
    isLoading: isCompaniesLoading,
  } = useFetchHomeCompaniesQuery();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      //console.log("decodedToken >", decodedToken);
      setDecodedToken(decodedToken);
    }
  }, []);

  // 연도, 사업장 데이터
  useEffect(() => {
    if (yearsData && Array.isArray(yearsData.data)) {
      //console.log("yearsData :: ", yearsData);
      const sortedYears = [...yearsData.data].sort((a, b) => b - a); // 내림차순 정렬을 위한 복사본
      const formattedYears = sortedYears.map((year) => ({
        value: year.toString(),
        label: `${year}년`,
      }));
      //console.log("formattedYears >>", formattedYears);
      setYearData(formattedYears);
      if (formattedYears) {
        setSelectedYear(formattedYears[0].value);
      }
    }

    if (companiesData && Array.isArray(companiesData.data)) {
      //console.log("company data : ", companiesData.data);
      const formattedCompanies = companiesData.data.map((company) => ({
        value: company.lowerInloNo,
        label: company.inloName,
      }));
      formattedCompanies.unshift({
        value: localStorage.getItem("inloNo"),
        label: "전체",
      });
      // formattedCompanies.unshift({
      //   value: "all",
      //   label: "전체",
      // });
      setCompanies(formattedCompanies);
      if (setSeletedCompany) {
        setSeletedCompany(formattedCompanies[0].value);
      }
    }
  }, [yearsData, companiesData]);

  // const isTotalSelected = selectedCompany === "all";
  // const inloNoToSend = isTotalSelected ? null : selectedCompany;

  /* 하위 컴포넌트 데이터 조회 */
  // 전체 배출량 조회
  const { data: totalEmissionData, refetch: refetchTotalEmission } =
    useFetchTotalEmissionQuery(
      {
        year: selectedYear,
        inloNo: selectedCompany, // 임시로 100 설정
      },
      { skip: selectedYear === "" }
    );

  // 목표 관리 조회
  const { data: goalManagementData, refetch: refetchGoalManagement } =
    useFetchGoalManagementQuery(
      {
        yyyy: selectedYear,
        inloNo: selectedCompany,
      },
      { skip: selectedYear === "" }
    );

  // 스코프 별 배출량
  const { data: emissionByScopeData, refetch: refetchEmissionByScope } =
    useFetchEmissionByScopeQuery(
      {
        year: selectedYear,
        inloNo: selectedCompany, // 임시로 100 설정
      },
      { skip: selectedYear === "" }
    );

  // 월별 배출량
  const {
    data: emissionMonthStatisticsData,
    refetch: refetchEmissionMonthStatistics,
  } = useFetchEmissionMonthStatisticsQuery(
    {
      year: selectedYear,
      inloNo: selectedCompany,
    },
    { skip: selectedYear === "" }
  );

  const {
    data: emissionYearStatisticsData,
    refetch: refetchEmissionYearStatistics,
  } = useFetchEmissionYearStatisticsQuery({
    inloNo: selectedCompany,
  });

  // 데이터 수집 현황
  const {
    data: dataCollectionStatusData,
    refetch: refetchDataCollectionStatus,
  } = useFetchDataCollectionStatusQuery(
    {
      year: selectedYear,
      inloNo: selectedCompany,
    },
    { skip: selectedYear === "" }
  );

  // 사업장별 배출량
  const { data: emissionByBusinessData, refetch: refetchEmissionByBusiness } =
    useFetchEmissionByBusinessQuery(
      {
        year: selectedYear,
        inloNo: selectedCompany,
      },
      { skip: selectedYear === "" }
    );

  // 최근 이력
  const { data: recentHistoryData, refetch: refetchRecentHistory } =
    useFetchRecentHistoryQuery(
      {
        year: selectedYear,
        inloNo: selectedCompany,
      },
      { skip: selectedYear === "" }
    );

  // 온실가스 최대 배출 사업장
  const { data: highestEmissionData, refetch: refetchHighestEmission } =
    useFetchHighestEmissionQuery(
      {
        year: selectedYear,
        inloNo: selectedCompany,
      },
      { skip: selectedYear === "" }
    );

  // 온실가스 최대 배출 시설
  const { data: highestfactoryData, refetch: refetchHighestFactory } =
    useFetchHighestFactoryQuery(
      {
        year: selectedYear,
        inloNo: selectedCompany,
        type: "year",
      },
      { skip: selectedYear === "" }
    );

  // 사용자의 사업장명 조회
  const { data: userInloNameData, refetch: refetchUserInloName } =
    useFetchUserInloNameQuery();

  const refetchWithTimeout = (refetchFunction) => {
    return Promise.race([
      refetchFunction(),
      new Promise((resolve) => setTimeout(resolve, 2500)),
    ]);
  };

  const refetchAllData = useCallback(async () => {
    await Promise.all([
      refetchWithTimeout(refetchTotalEmission),
      refetchWithTimeout(refetchGoalManagement),
      refetchWithTimeout(refetchEmissionByScope),
      refetchWithTimeout(refetchEmissionMonthStatistics),
      refetchWithTimeout(refetchEmissionYearStatistics),
      refetchWithTimeout(refetchDataCollectionStatus),
      refetchWithTimeout(refetchEmissionByBusiness),
      refetchWithTimeout(refetchRecentHistory),
      refetchWithTimeout(refetchHighestEmission),
      refetchWithTimeout(refetchHighestFactory),
    ]);
  }, [
    refetchTotalEmission,
    refetchGoalManagement,
    refetchEmissionByScope,
    refetchEmissionMonthStatistics,
    refetchEmissionYearStatistics,
    refetchDataCollectionStatus,
    refetchEmissionByBusiness,
    refetchRecentHistory,
    refetchHighestEmission,
    refetchHighestFactory,
  ]);

  useEffect(() => {
    if (selectedYear && selectedCompany) {
      refetchAllData();
    }
  }, [selectedYear, selectedCompany, refetchAllData]);

  const selectedYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const seletedCompanyChange = (event) => {
    setSeletedCompany(event.target.value);
  };

  const pophandleClose = () => {
    setPopOpen(false);
  };
  const pophandleOpen = () => {
    setPopOpen(true);
  };

  return (
    <>
      <div className="CarbonPageTitle">
        <div className="inSelect">
          <strong>{userInloNameData && userInloNameData[0].inloName}</strong>
          <FormControl>
            <Select
              value={selectedYear}
              onChange={selectedYearChange}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={MenuProps}
            >
              {isYearsLoading ? (
                <MenuItem>Loading...</MenuItem>
              ) : yearsError ? (
                <MenuItem>Error loading years</MenuItem>
              ) : (
                yearData.map((year) => (
                  <MenuItem key={year.value} value={year.value}>
                    {year.label}
                  </MenuItem>
                ))
              )}
              {/* {
                                  yearData.map((year) => (
                    <MenuItem key={year.value} value={year.value}>
                      {year.label}
                    </MenuItem>
                  ))
                } */}
            </Select>
          </FormControl>
          {decodedToken &&
            (decodedToken.inloClCd === "COMPLEX" ||
              decodedToken.inloClCd === "PROJECT") && (
              <FormControl>
                <Select
                  value={selectedCompany}
                  onChange={seletedCompanyChange}
                  displayEmpty
                  className="basicSelect"
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  {isCompaniesLoading ? (
                    <MenuItem>Loading...</MenuItem>
                  ) : companiesError ? (
                    <MenuItem>Error loading companies</MenuItem>
                  ) : (
                    companies.map((company) => (
                      <MenuItem key={company.value} value={company.value}>
                        {company.label}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            )}
        </div>
        <div className="func">
          <button className="ty1Button" onClick={pophandleOpen}>
            Scope 3 배출량 산정 문의하기
          </button>
        </div>
      </div>
      <div className="DashGrid">
        {/* 올해 전체 배출량 */}
        <TotalEmission totalEmissionData={totalEmissionData} />
        {/* 올해 목표관리 */}
        <GoalManagement goalManagementData={goalManagementData} />
        {/* Scope 별 배출량 */}
        <EmissionByScope emissionByScopeData={emissionByScopeData} />
        {/* 월별 배출량/연도별 배출량 */}
        <EmissionStatistics
          emissionMonthStatisticsData={emissionMonthStatisticsData}
          emissionYearStatisticsData={emissionYearStatisticsData}
        />
        {/* 데이터 수집 현황 */}
        <DataCollectionStatus
          dataCollectionStatusData={dataCollectionStatusData}
          selectedYear={selectedYear}
        />
        {decodedToken &&
        (decodedToken.inloClCd === "COMPLEX" ||
          decodedToken.inloClCd === "PROJECT") &&
        (selectedCompany === companies[0]?.value ||
          selectedCompany === companies[1]?.value) ? (
          // 사업장별 배출량
          <EmissionByBusiness
            emissionByBusinessData={emissionByBusinessData}
            selectedYear={selectedYear}
          />
        ) : (
          // 최근 이력
          <RecentHistory
            recentHistoryData={recentHistoryData}
            selectedYear={selectedYear}
          />
        )}
        {decodedToken &&
        (decodedToken.inloClCd === "COMPLEX" ||
          decodedToken.inloClCd === "PROJECT") &&
        (selectedCompany === companies[0]?.value ||
          selectedCompany === companies[1]?.value) ? (
          // 온실가스 최다 배출 사업장
          <HighestEmissionCompany highestEmissionData={highestEmissionData} />
        ) : (
          // 온실가스 최다 배출 시설
          <HighestEmissionFactory highestfactoryData={highestfactoryData} />
        )}
      </div>
      {/* Scope 3 배출량 산정 문의하기 팝업 */}
      <ContactEmailPopup open={popOpen} onClose={pophandleClose} />
    </>
  );
};

export default DashBoard;
