import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  nextStep,
  prevStep,
  selectProcess,
  toggleProcess,
  resetSteps,
} from "../../../modules/features/slice/stepSlice";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  useFetchCalYearsQuery,
  useFetchInitEmiCalQuery,
  useInsertStepValueMutation,
} from "../../../modules/features/api/calculationFormApi";
import { month } from "../CarbonEmissionCalculation/CarbonEmissionCalculationData";
import {
  axiosFetchBeforeSaved,
  axiosFetchCategory,
  axiosFetchTemplate,
  axiosgetCdName,
  axiosScope3Catg,
} from "../../../modules/features/api/endpoints/tscmsCalculationForm";
import { useLoading } from "../../util/LoadingContext";
import { jwtDecode } from "jwt-decode";
import { FormControl, MenuItem, Select } from "@mui/material";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../../styleModule/MenuProps";
import { useFetchUserInloNameQuery } from "../../../modules/features/api/commonApi";
import { useFetchHomeCompaniesQuery } from "../../../modules/features/api/dashBoardApi";
import CalcuScope3Step1 from "../CarbonEmissionCalculation/CalcuScope3Step1";
import CalcuScope3Step2 from "../CarbonEmissionCalculation/CalcuScope3Step2";
import CalcuScope3Step3 from "../CarbonEmissionCalculation/CalcuScope3Step3";

const CarbonEmissionCalculation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const [emiQaList, setEmiQaList] = useState([]);
  const [procTypeList, setProcTypeList] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(
    location.state?.year || getCurrentYear()
  );
  const [selectedMonth, setSelectedMonth] = useState(
    location.state?.month || 1
  );
  const [selectedCompany, setSelectedCompany] = useState(
    location.state?.company || localStorage.getItem("inloNo")
  );
  const [insertStepValue] = useInsertStepValueMutation();
  const stepNavi = useSelector((state) => state.step.stepNavi);
  const selectedProcesses = useSelector(
    (state) => state.step.selectedProcesses
  );
  const [isDataSaved, setIsDataSaved] = useState(false);
  const { showLoading, hideLoading } = useLoading();
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const [decodedToken, setDecodedToken] = useState(null);
  const [inloClCd, setInloClCd] = useState("COMPLEX");
  const [role, setRole] = useState("S");
  const [year, setYear] = useState([]);
  const [company, setCompany] = useState([]);
  const [displayedStep, setDisplayedStep] = useState(stepNavi);
  const [scope3SortedData, setScope3SortedData] = useState([]);

  const padMonth = (month) => month.toString().padStart(2, "0");

  useEffect(() => {
    if (stepNavi > 6) { // 특정 조건에 따라 displayedStep 초기화
      setDisplayedStep(stepNavi - 6); // 3단계를 기준으로 displayedStep을 초기화
    } else {
      setDisplayedStep(stepNavi);
    }
  }, [stepNavi, selectedYear, selectedMonth, selectedCompany]);

  useEffect(() => {
    // 컴포넌트가 처음 렌더링될 때 Redux 상태를 초기화
    dispatch(resetSteps());
  }, [dispatch]);

  const {
    data: yearsData,
    error: yearsError,
    isLoading: isYearsLoading,
  } = useFetchCalYearsQuery();

  const {
    data: calCompanyData,
    error: companyError,
    isLoading: isCompanyLoading,
  } = useFetchHomeCompaniesQuery();

  // 사용자의 사업장명 조회
  const { data: userInloNameData, refetch: refetchUserInloName } =
    useFetchUserInloNameQuery();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      //console.log("decodedToken >>>>", decodedToken);
      setDecodedToken(decodedToken);
      setRole(decodedToken.role);
      setInloClCd(decodedToken.inloClCd);
    }
  }, []);

  useEffect(() => {
    if (!isCompanyLoading && calCompanyData) {
      const formattedCompanies = calCompanyData.data.map((company) => ({
        value: company.lowerInloNo,
        label: company.inloName,
      }));
      //console.log("formattedCompanies >>", formattedCompanies);
      if (formattedCompanies.length > 0) {
        // setSelectedCompany(formattedCompanies[0].value);
        setCompany(formattedCompanies);
      }
    }
  }, [isCompanyLoading, calCompanyData]);

  useEffect(() => {
    if (!isYearsLoading && yearsData) {
      const sortedYears = [...yearsData.data].sort((a, b) => b - a);
      const formattedYears = sortedYears.map((year) => ({
        value: year,
        label: `${year}년`,
      }));
      setYear(formattedYears);
      if (location.state?.year) {
        setSelectedYear(location.state.year);
      } else if (formattedYears.length > 0) {
        setSelectedYear(formattedYears[0].value);
      }
    }
  }, [isYearsLoading, yearsData]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // 데이터 불러오기 시작 시 로딩 상태로 설정
      showLoading();
      try {
        const yyyyMm = `${selectedYear}${padMonth(selectedMonth)}`;
        const templateResponse = await axiosFetchTemplate({
          yyyyMm,
          inloNo: selectedCompany,
        });
        const categoryResponse = await axiosFetchCategory({
          yyyyMm,
          inloNo: selectedCompany,
        });
        hideLoading();

        // console.log("Fetched templateData:", templateResponse);
        // console.log("Fetched categoryData:", categoryResponse);

        if (!categoryResponse.data || categoryResponse.data.length === 0) {
          //console.warn("Category data is empty. Staying on the current page.");
          setIsLoading(false); // 로딩 상태 해제
          return;
        }

        const stateData = {
          templateData: templateResponse.data,
          categoryData: categoryResponse.data,
          year: selectedYear,
          month: selectedMonth,
          company: selectedCompany,
          reload: false,
        };

        if (
          templateResponse.data?.inloInitList.length > 0 &&
          templateResponse.data?.inloSaveList.length === 0
        ) {
          navigate("/calculationForm", { state: stateData });
        } else if (templateResponse.data?.inloSaveList.length > 0) {
          navigate("/calculationForm", { state: stateData });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false); // 데이터 불러오기 완료 시 로딩 상태 해제
    };

    fetchData();
  }, [
    location.state?.reload,
    selectedYear,
    selectedMonth,
    selectedCompany,
    navigate,
  ]);

  const { data: initData } = useFetchInitEmiCalQuery();

  useEffect(() => {
    if (initData) {
      setEmiQaList(initData.data.emiQaList);
      setProcTypeList(initData.data.procTypeList);
      //console.log("initData.data >>>>", initData.data);
      setTagData(initData.data.procTypeList);
    }
  }, [initData]);

  useEffect(() => {
    if (isDataSaved || location.stateData?.reload) {
      window.location.reload(true);
    }
  }, [isDataSaved, location.stateData?.reload]);

  if (isLoading) {
    return null; // 로딩 중일 때 아무것도 렌더링하지 않음
  }


  const handleNextStep = (processValue) => {
    const updatedEmiQaList = emiQaList.map((item, index) => {
      if (index === stepNavi - 1) {
        return { ...item, val1: processValue === "yes" ? "Y" : "" };
      }
      return item;
    });
    setEmiQaList(updatedEmiQaList);
    //console.log("updatedEmiQaList >>>>>>> ", updatedEmiQaList);
    dispatch(selectProcess({ step: stepNavi, process: processValue }));
    if (stepNavi < 6) {
      dispatch(nextStep());
      setDisplayedStep(1);
    }
  };


  const handleNextNavi = async (parameter = null) => {
    try {
      //console.log("handleNextNavi called with parameter:", parameter);
      if (parameter) {
        showLoading();
        const response = await axiosScope3Catg({sector : parameter });

        if (response && response.data) {
          const sortedData = response.data.data
          ? Object.entries(response.data.data)
          .filter(([key]) => key !== "propId") // propId 필드 제외
          .sort(([, a], [, b]) => b - a)
          : [];
          setScope3SortedData(sortedData)

          const categories = sortedData?.map(([key]) => key);
          if (categories && categories.length > 0) {
            const response = await axiosgetCdName(categories);
            //console.log("axiosgetCdName response >>> ", response.data.data);
            const scope3CatgData = response.data.data.map((item, index) => {
              if (item) {
                return { ...item, val1: "Y"};
              }
              return item;
            })
            //console.log("scope3CatgData >>>>", scope3CatgData);
            setEmiQaList((prevEmiQaList) => {
              return [
                ...prevEmiQaList,
                ...scope3CatgData
              ];
            })
          }

          // 이 부분에서 scope3CatgData의 propId를 제외한 key 값들을 파라미터로 하여
          // getCdName api를 호출하고 val1의 값을 Y로 변경한 뒤 emiQaList 에 추가해주어야 한다
          hideLoading();
        }
      }

      //console.log("emiQaList >>>>>>", emiQaList);
      dispatch(nextStep());
    } catch (error) {
      console.log("handleNextNavi error");
    }
  }

  const handleProcessToggle = (process) => {
    if (process === "22") {
      if (selectedProcesses[6]?.includes("22")) {
        dispatch(selectProcess({ step: 6, process: [] }));
      } else {
        dispatch(selectProcess({ step: 6, process: ["22"] }));
      }
    } else {
      let updatedProcesses;
      if (selectedProcesses[6]?.includes(process)) {
        updatedProcesses = selectedProcesses[6].filter((item) => item !== process);
      } else {
        updatedProcesses = selectedProcesses[6]?.includes("22")
          ? [process]
          : [...(selectedProcesses[6] || []), process];
      }

      dispatch(selectProcess({ step: 6, process: updatedProcesses }));
      const updatedProcTypeList = procTypeList.map((item) => {
        if (updatedProcesses.includes(item.cdCode)) {
          return { ...item, val1: "Y" };
        }
        return { ...item, val1: "" };
      }); // 선택된 태그의 val1을 Y로 설정
      setProcTypeList(updatedProcTypeList);
      //console.log("Updated procTypeList after toggle >>>>>>> ", updatedProcTypeList);
    }
  };

  // 저장 및 배출량 산정하러 가기 버튼 클릭 시
  const handleSubmit = async (Scope3Data) => {
    const stateData = { bypassRedirect: true };
    showLoading();
    //console.log("handleSumit emiQaList::: ", emiQaList);

    try {
      const dataToSend = {
        emiQaList: emiQaList,
        procTypeList: procTypeList, // 변경된 부분: procTypeList를 빈 배열로 포함
      };
      const yyyyMm = `${selectedYear}${padMonth(selectedMonth)}`;
      const inloNo = selectedCompany
        ? selectedCompany
        : localStorage.getItem("inloNo");

      // console.log("dataToSend >>>>>", dataToSend);
      // console.log("yyyyMm >>>>>", yyyyMm);
      // console.log("inloNo >>>>>", inloNo);

      await insertStepValue({ EmiCalDto: dataToSend, yyyyMm, inloNo }).unwrap();
      const response = await axiosFetchTemplate({ yyyyMm, inloNo });
      hideLoading();
      const templateData = response.data;
      //console.log("handle sumit template Data>>", templateData);

      if (templateData) {
        //console.log("templateData :", templateData.data);
        stateData.templateData = templateData;
      }

      alert("저장 성공");
      setIsDataSaved(true);
    } catch (error) {
      alert("저장 실패");
    }

  };

  const yearChange = (event) => {
    dispatch(resetSteps());
    setSelectedYear(event.target.value);
    // setEmiQaList([]);
    // setProcTypeList([]);
    navigate("/step", {
      state: { year: event.target.value, month: selectedMonth, company: selectedCompany },
    });
  };

  const monthChange = (event) => {
    dispatch(resetSteps());
    setSelectedMonth(event.target.value);
    // setEmiQaList([]);
    // setProcTypeList([]);
    navigate("/step", {
      state: { year: selectedYear, month: event.target.value, company: selectedCompany },
    });
  };

  const companyChange = (event) => {
    dispatch(resetSteps());
    setSelectedCompany(event.target.value);
    // setEmiQaList([]);
    // setProcTypeList([]);
    navigate("/step", {
      state: { year: selectedYear, month: selectedMonth, company: event.target.value },
    });
  };

  // 이전 내역 불러오기
  const handleLoadPrevious = async () => {
    showLoading();
    // 여기에 delete와 insert 기능을하는 백을 호출한다음
    // previous 데이터가 아니라 현재 year, month를 그대로 호출하도록 해야한다.
    const yyyyMm = `${selectedYear}${padMonth(selectedMonth)}`;
    const resultData = await axiosFetchBeforeSaved({
      yyyyMm,
      inloNo: selectedCompany,
    });
    //console.log("resultData ::::", resultData);
    if (resultData.data == "success") {
      hideLoading();
      navigate("/step", {
        state: {
          year: selectedYear,
          month: selectedMonth,
          company: selectedCompany,
          reload: true,
        },
      });
    } else {
      alert("이전 내역이 존재하지 않습니다");
    }
  };

  // tagData 오름차순 정렬
  const sortedTagData = [...tagData].sort(
    (a, b) => parseInt(a.cdCode) - parseInt(b.cdCode)
  );

  const MAX_VISIBLE_STEPS = 6; // 최대 표시할 게이지 개수
  const SMALL_MAX_VISIBLE_STEPS = 3; // 7부터 3개의 게이지만 표시

  const displayedSteps = stepNavi < 7 ? MAX_VISIBLE_STEPS : SMALL_MAX_VISIBLE_STEPS;

  const ulStyle = {
    width: stepNavi >= 7 ? '520px' : '1240px', // stepNavi가 7 이상일 때 width를 500px로 설정
  };

  return (
    <>
      <div className="CarbonTopSelect">
        <div className="selArea">
          <p className="label">산정기간</p>
          <FormControl>
            <Select
              value={selectedYear}
              onChange={yearChange}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon
                  sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                />
              )}
              MenuProps={MenuProps}>
              {isYearsLoading ? (
                <MenuItem value="">Loading...</MenuItem>
              ) : yearsError ? (
                <MenuItem value="">Error loading years</MenuItem>
              ) : (
                year?.map((year) => (
                  <MenuItem key={year.value} value={year.value}>
                    {year.label}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              value={selectedMonth}
              onChange={monthChange}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon
                  sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                />
              )}
              MenuProps={MenuProps}>
              {month?.map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(inloClCd === "COMPLEX" || inloClCd === "PROJECT") && (
            <>
              <p className="label">사업장 선택</p>
              <FormControl>
                <Select
                  value={selectedCompany}
                  onChange={companyChange}
                  displayEmpty
                  className="basicSelect"
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value={userInloNameData ? userInloNameData[0]?.inloNo : ""}>
                    {userInloNameData ? userInloNameData[0].inloName : ""}
                  </MenuItem>
                  {isCompanyLoading ? (
                    <MenuItem>Loading...</MenuItem>
                  ) : companyError ? (
                    <MenuItem>Error loading companies</MenuItem>
                  ) : (
                    company?.map((company) => (
                      <MenuItem key={company.value} value={company.value}>
                        {company.label}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </>
          )}
        </div>
        <div className="btnArea">
          {decodedToken &&
          (decodedToken.inloClCd === "COMPLEX" ||
            decodedToken.inloClCd === "PROJECT" ||
            ((decodedToken.inloClCd === "COMPANY" ||
              decodedToken.inloClCd === "PLANT") &&
              decodedToken.role === "S")) ? (
            <>
              {/* <button onClick={pophandleOpen3}>미입력 데이터 관리</button> */}
              {/* <button onClick={pophandleOpen}>배출구분 재설정</button> */}
              <button onClick={handleLoadPrevious}>이전 내역 불러오기</button>
              {/* <button>이전 내역 불러오기</button> */}
            </>
          ) : (
            <>
              {/* <p>
              <input type="checkbox" onChange={handleCheckboxChange}/>
              <label>
                내 할당 내역 불러오기
              </label>
            </p>
            <button onClick={pophandleOpen3}>미입력 데이터 관리</button> */}
            </>
          )}
        </div>
      </div>
      {decodedToken &&
      (decodedToken.inloClCd === "COMPLEX" ||
        decodedToken.inloClCd === "PROJECT" ||
        ((decodedToken.inloClCd === "COMPANY" ||
          decodedToken.inloClCd === "PLANT") &&
          decodedToken.role === "S")) ? (
        <>
          <div className="CarbonPageTitle">
            {stepNavi === 7 ? (
              <>
                <strong>
                다음은 Scope 3 배출량 산정을 위한 설정 과정입니다.
                </strong>
              </>
            ) : (
              <>
                <strong>
                  안녕하세요, 귀 사업장의 탄소배출량 산정을 위한 설정 단계입니다.
                </strong>
              </>
            )}
          </div>
          <div className="CarbonStepWrap">
            <div className="stepDesc">
              다음 내용을 미리 준비해주시면 더 쉽고 빠른 진행이 가능합니다.
              <br />( 차량관리대장 서류, 기계장치 연료 사용량, 시설 배치도, 전기
              고지서, 난방 고지서 )
            </div>
            <div className="CarbonBox">
              <div className="CarbonNavi">
                <p
                  className="prev"
                  style={{ visibility: stepNavi === 1 ? "hidden" : "visible" }}
                  onClick={() => stepNavi !== 1 && dispatch(prevStep())}>
                  이전
                </p>
              </div>
              <div
                className={`CarbonStep stepCnt${Math.min(
                  emiQaList.length,
                  MAX_VISIBLE_STEPS
                )}`}>
                <ul className={`step${displayedStep}`} style={ulStyle}>
                  {emiQaList.slice(0, displayedSteps).map((_, index) => (
                    <li key={index}>{index + 1}</li>
                  ))}
                </ul>
              </div>
              <div className="CarbonStepContent">
                {stepNavi < 6 ? (
                  <>
                    <strong>{emiQaList[stepNavi - 1]?.cdName}</strong>
                    <div className="stepBtn">
                      <button
                        className={
                          selectedProcesses[stepNavi] === "no" ? "on" : ""
                        }
                        onClick={() => handleNextStep("no")}>
                        아니요
                      </button>
                      <button
                        className={
                          selectedProcesses[stepNavi] === "yes" ? "on" : ""
                        }
                        onClick={() => handleNextStep("yes")}>
                        예
                      </button>
                    </div>
                  </>
                ) : stepNavi === 6 ? (
                  <>
                    <strong>
                      귀 사업장에서 생산하는 제품들의 공정 유형에 해당되는
                      항목을 모두 골라주세요.
                    </strong>
                    <div className="stepHash">
                      {sortedTagData?.map((process, index) => (
                        <div
                          key={index}
                          className={
                            selectedProcesses[6]?.includes(process.cdCode)
                              ? "on"
                              : ""
                          }
                          onClick={() => handleProcessToggle(process.cdCode)}>
                          {process.cdName}
                        </div>
                      ))}
                    </div>
                  </>
                ) : stepNavi === 7 ? (
                  <CalcuScope3Step1
                    handleSubmit={handleSubmit}
                    handleNextNavi={handleNextNavi}
                  />
                ) : stepNavi === 8 ? (
                  <CalcuScope3Step2
                    handleNextNavi={handleNextNavi}
                  />

                ) : stepNavi === 9 ? (
                  <CalcuScope3Step3
                    scope3SortedData={scope3SortedData}
                  />
                ) : null}
              </div>
            </div>
            {stepNavi === 6 ? (
              <div className="StepSubmit">
                {/* <button onClick={handleSubmit}>저장 및 산정하러 가기</button> */}
                <button onClick={() => handleNextNavi()}>다음 단계로 넘어가기</button>
              </div>
            ) : stepNavi === 9 ? (
              <div className="StepSubmit">
                <button onClick={() => handleSubmit()}>저장 및 산정하러 가기</button>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <div className="CarbonBox">
          <p>
            탄소배출량 산정 데이터가 등록되어 있지 않습니다. 관리자에게
            문의해주세요.
          </p>
        </div>
      )}
    </>
  );
};

export default CarbonEmissionCalculation;
