import React, { useEffect, useState } from "react";
import { TitleCloseDialog } from "../../styleModule/ModifyStyle";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useSubmitContactEmailMutation,
  useSubmitEmissionQueryMutation,
} from "../../../modules/features/api/dashBoardApi";

/**
 * @description 대시보드 > Scope 3 배출량 산정 문의하기 팝업
 * @author seoeunPark
 * @date 20/08/2024
 * @param {*}
 * @return
 */

const ContactEmailPopup = ({ open, onClose }) => {
  const [contactForm, setContactForm] = useState({
    email: "",
    phone: "",
  });
  const [emailError, setEmailError] = useState("");

  const [submitEmissionQuery, { isLoading }] = useSubmitContactEmailMutation();

  useEffect(() => {
    if (contactForm.email.length > 0) {
      setEmailError("");
    }
  }, [contactForm.email]);

  const handleSubmit = async () => {
    const { email, phone } = contactForm;

    try {
      if (!validateEmail(email)) {
        setEmailError("올바른 이메일 형식이 아닙니다.");
        return;
      }
      await submitEmissionQuery({ email, phone }).unwrap();
      alert("문의가 접수되었습니다.");
      onClose();
    } catch (error) {
      console.error("ContactEmailPopup >> handleSubmit >> error :", error);

      alert("문의 접수에 실패했습니다.");
    } finally {
      setContactForm({ email: "", phone: "" });
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <TitleCloseDialog open={open} onClose={onClose}>
      <DialogTitle>Scope 3 배출량 산정 문의하기</DialogTitle>
      <IconButton aria-label="close" onClick={onClose} className="dialogClose">
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="CarbonPopCont">
          <p className="typoTy1">
            Scope 3 배출량 산정 신청을 접수하시면 등록하신 연락 정보를
            <br />
            통해서 3일 내로 귀 사업장 담당자께 연락을 드리겠습니다.
          </p>
          <div className="inForm">
            <p className="typoTy1" style={{ color: "red" }}>
              {emailError}
            </p>
            <input
              type="email"
              placeholder={"이메일"}
              value={contactForm.email}
              onChange={(e) =>
                setContactForm({ ...contactForm, email: e.target.value })
              }
            />

            <input
              type="text"
              placeholder="전화번호"
              value={contactForm.phone}
              onChange={(e) =>
                setContactForm({ ...contactForm, phone: e.target.value })
              }
            />
          </div>
          <div className="CarbonPopBtn">
            <button className="cancle" onClick={onClose}>
              취소
            </button>
            <button onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? "문의 접수중" : "문의하기"}
            </button>
          </div>
          <div className="CarbonPopBtmText">
            사전문의 : contact@thingspire.com
          </div>
        </div>
      </DialogContent>
    </TitleCloseDialog>
  );
};

export default ContactEmailPopup;
