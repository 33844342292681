import { instance } from "../api";

/**
import { reportBoardApi } from './../reportBoardApi';
 * @description 보고서 게시판 조회
 * @author seoeunPark
 * @date 02/07/2024
 * @param {type, orderby, inloNo}
 * @return
 */
const fetchReportBoard = (builder) => {
  return builder.query({
    query: ({ type, order, inloNo, page, year }) => {
      const params = {};
      if (type) params.type = type;
      if (order) params.order = order;
      if (inloNo) params.inloNo = inloNo;
      if (page) params.page = page;
      if (year) params.year = year;

      return {
        url: `/api/report/board`,
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 보고서 모달 데이터 조회
 * @author seoeunPark
 * @date 09/07/2024
 * @param {*}
 * @return
 */
const fetchReportModal = (builder) => {
  return builder.query({
    query: ({ yyyyMm, type, inloNo }) => {
      const params = {};
      if (type) params.type = type;
      if (yyyyMm) params.yyyyMm = yyyyMm;
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/report/board/modal`,
        params,
      };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};

export const reportModalApi = async (params) => {
  const { yyyyMm = null, type = null, inloNo = null } = params;

  const response = await instance.get(`/api/report/board/modal`, {
    params: {
      yyyyMm,
      type,
      inloNo,
    },
  });

  return response.data.data;
};

/**
 * @description 보고서 파일 모달 데이터 조회
 * @author choiseonha
 * @date 20/08/2024
 * @param {*}
 * @return
 */
export const fetchFileBoard = async (params) => {
  const { yyyyMm = null, type = null, inloNo = null } = params;
  const response = await instance.get(`/api/report/board/files`,{
    params: {
      yyyyMm,
      type,
      inloNo,
    },
  });
  return response.data.data;
};


export const tscmsReportBoardEndpoints = (builder) => ({
  fetchReportBoard: fetchReportBoard(builder),
  fetchReportModal: fetchReportModal(builder),
});
