import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../../styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

const PurchasedProductsService = () => {
  const [topYear, setTopYear] = useState("2024");

  const TopYearChange = (event) => {
    setTopYear(event.target.value);
  };

  const [topMonth, setTopMonth] = useState(9);

  const TopMonthChange = (event) => {
    setTopMonth(event.target.value);
  };

  const [topComp, setTopComp] = useState("all");

  const TopCompChange = (event) => {
    setTopComp(event.target.value);
  };

  const [value, setValue] = React.useState(6);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="Calcu3ScopBox">
        <div className="topBtn">
          <p>
            <input type="checkbox" />
            <label>매칭 정확도가 낮은 순서로 정렬</label>
          </p>
          <button>업로드할 템플릿 다운로드하기</button>
          <button className="blue">ERP 데이터 업로드하기</button>
        </div>
        <div className="Calcu3ScopeTable">
          <table>
            <colgroup>
              <col width="3%" />
              <col width="30%" />
              <col width="7%" />
              <col width="7%" />
              <col width="9%" />
              <col width="10%" />
              <col />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>구매제품/서비스 목록</th>
                <th>단위</th>
                <th>수량</th>
                <th>단가</th>
                <th>구매액</th>
                <th>매칭정보</th>
                <th>매칭 정확도</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>1</th>
                <td>
                  <TextField value="3M/다용도테이프/583/18mm*30M" fullWidth />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="1"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="3,900"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="3,900"
                  />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "80%" }} className="success"></span>
                    </p>
                    <b>80%</b>
                  </div>
                </td>
              </tr>
              <tr>
                <th>2</th>
                <td>
                  <TextField
                    value="애경/치약/2080/시그니처토탈블루/오리지날민트향/130g*3입"
                    fullWidth
                  />
                </td>
                <td>
                  <Select
                    value={2}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="2"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="7,700"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="15,400"
                  />
                </td>
                <td>
                  <Select
                    value={2}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "42%" }} className="fail"></span>
                    </p>
                    <b>42%</b>
                  </div>
                </td>
              </tr>
              <tr>
                <th>3</th>
                <td>
                  <TextField
                    value="펠로우즈/문서홀더/I-Spire/3-in-1"
                    fullWidth
                  />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="1"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="35,000"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="35,000"
                  />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "78%" }} className="success"></span>
                    </p>
                    <b>78%</b>
                  </div>
                </td>
              </tr>
              <tr>
                <th>4</th>
                <td>
                  <TextField
                    value="마이펜/유성펜/세이프티클립/0.7mm/블랙"
                    fullWidth
                  />
                </td>
                <td>
                  <Select
                    value={3}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="1"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="9,600"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="9,600"
                  />
                </td>
                <td>
                  <Select
                    value={3}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "45%" }} className="fail"></span>
                    </p>
                    <b>45%</b>
                  </div>
                </td>
              </tr>
              <tr>
                <th>5</th>
                <td>
                  <TextField
                    value="HP/토너/W2042A/No.416A/노랑/표준용량"
                    fullWidth
                  />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="1"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="167,200"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="167,200"
                  />
                </td>
                <td>
                  <Select
                    value={4}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "25%" }} className="fail"></span>
                    </p>
                    <b>25%</b>
                  </div>
                </td>
              </tr>
              <tr>
                <th>6</th>
                <td>
                  <TextField value="알파/결재판/RA-600/A4/일반" fullWidth />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="2"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="2,700"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="5,400"
                  />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "78%" }} className="success"></span>
                    </p>
                    <b>78%</b>
                  </div>
                </td>
              </tr>
              <tr>
                <th>7</th>
                <td>
                  <TextField value="이화/경조봉투/부의/7매" fullWidth />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="2"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="900"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="1,800"
                  />
                </td>
                <td>
                  <Select
                    value={5}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "98%" }} className="success"></span>
                    </p>
                    <b>98%</b>
                  </div>
                </td>
              </tr>
              <tr>
                <th>8</th>
                <td>
                  <TextField
                    value="알파/풀테이프/AGT-0180/8.4mm*12M"
                    fullWidth
                  />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="1"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="2,500"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="2,500"
                  />
                </td>
                <td>
                  <Select
                    value={1}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "85%" }} className="success"></span>
                    </p>
                    <b>85%</b>
                  </div>
                </td>
              </tr>
              <tr>
                <th>9</th>
                <td>
                  <TextField value="광동/비타500/180ml/병" fullWidth />
                </td>
                <td>
                  <Select
                    value={4}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="1"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="14,000"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="14,000"
                  />
                </td>
                <td>
                  <Select
                    value={2}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "97%" }} className="success"></span>
                    </p>
                    <b>97%</b>
                  </div>
                </td>
              </tr>
              <tr>
                <th>10</th>
                <td>
                  <TextField
                    value="네스카페/돌체구스토/캡슐커피/스타벅스베란다블렌드"
                    fullWidth
                  />
                </td>
                <td>
                  <Select
                    value={4}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>개</MenuItem>
                    <MenuItem value={2}>팩</MenuItem>
                    <MenuItem value={3}>타</MenuItem>
                    <MenuItem value={4}>갑</MenuItem>
                  </Select>
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="1"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    fullWidth
                    value="12,800"
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </td>
                <td>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₩</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    fullWidth
                    value="12,800"
                  />
                </td>
                <td>
                  <Select
                    value={4}
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "24px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    fullWidth>
                    <MenuItem value={1}>문구용품</MenuItem>
                    <MenuItem value={2}>비알콜음료 및 얼음</MenuItem>
                    <MenuItem value={3}>기타 플라스틱제품</MenuItem>
                    <MenuItem value={4}>커피 및 차류</MenuItem>
                    <MenuItem value={5}>종이문구 및 사무용지</MenuItem>
                  </Select>
                </td>
                <td>
                  <div className="matching">
                    <p>
                      <span style={{ width: "78%" }} className="success"></span>
                    </p>
                    <b>78%</b>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default PurchasedProductsService;
