import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../../styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ReportModal from "../ReportBoard/ReportModal";
import AttachmentModal from "../ReportBoard/AttachmentModal";
import CofirmDeleteModal from "../ReportBoard/CofirmDeleteModal";
import ConfirmNewReport1 from "../ReportBoard/ConfirmNewReport1";
import ConfirmNewReport2 from "../ReportBoard/ConfirmNewReport2";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useLoading } from "../../util/LoadingContext";
import { useFetchReportBoardQuery } from "../../../modules/features/api/reportBoardApi";
import { useFetchHomeCompaniesQuery } from "../../../modules/features/api/dashBoardApi";
import { jwtDecode } from "jwt-decode";
import { reportModalApi } from "../../../modules/features/api/endpoints/tscmsReportBoard";
import { formatNumber } from "../common/commonData";
import { useFetchYearsQuery } from "../../../modules/features/api/commonApi";

/**
 * @description 보고서 게시판(연간/월간 보고서 - 기업관리자(멀티 수용가 관리자),개별수용가 관리자 및 담당자)
 * @author seoeunPark
 * @date 18/06/2024
 * @param {*}
 * @return
 */
function ReportBoard() {
  const reportRef = useRef();
  const { showLoading, hideLoading } = useLoading();
  const [clickedRow, setClickedRow] = useState(null);
  const [pdfFlag, setPdfFlag] = useState(false);
  const [decodedToken, setDecodedToken] = useState(null);
  const [yearData, setYearData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");

  const getCurrentYear = () => {
    return new Date().getFullYear();  // 현재 연도를 문자열로 반환
  };

  const [selected, setSelected] = useState({
    type: "month", // 기준 선택
    order: "latest", // 정렬 선택
    inloNo: localStorage.getItem("inloNo"), // 사업장명 선택
    page: 1,
    year: getCurrentYear(),
    month: "",
  });

  // 기준 선택 옵션
  const selectOptions1 = [
    { value: "month", label: "월간" },
    { value: "year", label: "연간" },
  ];

  // 정렬 선택 옵션
  const selectOptions2 = [
    { value: "latest", label: "최신순" },
    { value: "oldest", label: "오래된순" },
  ];

  const [selectOptions3, setSelectOptions3] = useState([]);

  // 게시판 데이터
  const [boardData, setBoardData] = useState({
    currentPage: 1,
    totalPages: 1,
    boards: [],
  });

  // 보고서 데이터
  const [reportData, setReportData] = useState(null);

  const {
    data: fetchedBoardData,
    error: boardDataError,
    isLoading: boardDataIsLoading,
    refetch: refetchBoardDataQuery,
  } = useFetchReportBoardQuery({
    type: selected.type,
    order: selected.order,
    inloNo: selected.inloNo,
    page: selected.page,
    ...(selected.type === "month" && { year: selected.year }), // 조건부로 year 추가
  });

  // 연도 데이터
  const {
    data: yearsData,
    error: yearsError,
    isLoading: isYearsLoading,
  } = useFetchYearsQuery();

  // 사업장 리스트
  const {
    data: companiesData,
    error: companiesError,
    isLoading: isCompaniesLoading,
  } = useFetchHomeCompaniesQuery();

  useEffect(() => {
    if (yearsData && Array.isArray(yearsData.data)) {
      //console.log("yearsData :: ", yearsData);
      const sortedYears = [...yearsData.data].sort((a, b) => b - a); // 내림차순 정렬을 위한 복사본
      const formattedYears = sortedYears.map((year) => ({
        value: year.toString(),
        label: `${year}년`,
      }));
      //console.log("formattedYears >>", formattedYears);
      setYearData(formattedYears);
      if (formattedYears) {
        setSelected((prevSelected) => ({
          ...prevSelected,
          year: formattedYears[0]?.value,
        }));
      }
    }
  }, [yearsData])

  useEffect(() => {
    setSelected({ ...selected, page: 1 });
  }, [selected.type, selected.order]);

  useEffect(() => {
    refetchBoardDataQuery();
  }, [selected.type, selected.inloNo, selected.year, selected.order, selected.page]);

  useEffect(() => {
    // console.log("fetchedBoardData >>", fetchedBoardData);
    setBoardData(fetchedBoardData);
  }, [fetchedBoardData]);

  useEffect(() => {
    if (
      companiesData &&
      companiesData.data &&
      Array.isArray(companiesData.data)
    ) {
      const options = companiesData?.data?.map((company) => ({
        value: company.lowerInloNo,
        label: company.inloName,
      }));

      options.unshift({ value: localStorage.getItem("inloNo"), label: "전체" });
      setSelectOptions3(options);
    }
  }, [companiesData]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      //console.log("decodedToken >", decodedToken);
      setDecodedToken(decodedToken);
    }
  }, []);

  // useEffect(() => {
  //   if (clickedRow !== null) {
  //     reportFetch(clickedRow);
  //   }
  // }, [clickedRow]);

  const reportFetch = async (index) => {
    try {
      const response = await reportModalApi({
        yyyyMm: boardData?.boards[index]?.yyyyMmRaw,
        type: selected.type,
        inloNo: selected.inloNo,
      });
      setReportData(response);
      return response;
    } catch (error) {
      console.error("Error fetching report data:", error);
      return null;
    }
  };

  const handleDownloadClick = async (index) => {
    setClickedRow(index);
    showLoading();
    const fetchedReportData = await reportFetch(index);
    if (fetchedReportData) {
      await pophandleReportOpen();
      await handleDownloadPdf(index);
    }
    hideLoading();
  };

  const handleDownloadPdf = (index) => {
    setPdfFlag(true);
    return new Promise(async (resolve, reject) => {
      try {
        const element = reportRef.current;
        if (!element) {
          console.error("Element is null");
          reject("Element is null");
          return;
        }

        const pdf = new jsPDF("p", "pt", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const margin = 40;
        let yOffset = margin;

        const renderElement = async (element) => {
          const canvas = await html2canvas(element, {
            scale: 2,
            useCORS: true,
            logging: false,
            letterRendering: true,
          });
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = pdfWidth - 2 * margin;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          if (yOffset + imgHeight > pdfHeight - margin) {
            pdf.addPage();
            yOffset = margin;
          }

          pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
          yOffset += imgHeight + 10;
        };

        const elements = element.querySelectorAll(
          ".reportTitle, .ReportGrid, .MonthlyReport"
        );
        for (let i = 0; i < elements.length; i++) {
          await renderElement(elements[i]);
        }

        pdf.save(`${boardData?.boards[index].title}.pdf`);
        resolve();
      } catch (error) {
        console.error("Error generating PDF:", error);
        reject(error);
      } finally {
        await pophandleReportClose();
        setPdfFlag(false);
      }
    });
  };

  const handlePageChange = (event, value) => {
    setSelected({ ...selected, page: value });
    // 페이지 변경에 따라 데이터를 업데이트하는 로직을 여기에 추가
  };

  // 새보고서 작성 탭 ===========================================
  const [popOpen, setPopOpen] = useState(false);

  const pophandleClose = () => {
    setPopOpen(false);
  };
  const pophandleOpen = () => {
    setPopOpen(true);
  };

  // 새보고서 작성 탭 2 ===========================================
  const [popOpen2, setPopOpen2] = useState(false);

  const pophandleClose2 = () => {
    setPopOpen2(false);
  };
  const pophandleOpen2 = () => {
    setPopOpen2(true);
  };

  // 보고서 삭제 모달 ===========================================
  const [popOpen3, setPopOpen3] = useState(false);

  const pophandleClose3 = () => {
    setPopOpen3(false);
  };
  const pophandleOpen3 = () => {
    setPopOpen3(true);
  };
  // 삭제 버튼 클릭시 실행되는 함수
  const handleDelete = () => {
    alert("삭제되었습니다.");
    pophandleClose3();
  };

  // 증빙자료 모달 ===========================================
  const [popOpen4, setPopOpen4] = useState(false);

  const pophandleClose4 = () => {
    setPopOpen4(false);
  };
  const pophandleOpen4 = (index) => {
    setPopOpen4(true);
    setClickedRow(index);
  };

  // 보고서 모달 ===========================================
  const [popReport, setPopReport] = useState(false);

  const pophandleReportClose = async () => {
    setPopReport(false);
  };
  const pophandleReportOpen = async () => {
    return new Promise((resolve) => {
      setPopReport(true);
      setTimeout(() => {
        resolve();
      }, 300); // 0.3초 후에 resolve 호출(모달이 열리는 시간 소요)
    });
  };

  const viewReport = async (index) => {
    setPdfFlag(false);
    setClickedRow(null);  // 모달을 열기 전에 clickedRow를 null로 초기화
    setReportData(null);  // 이전 데이터를 초기화
    setClickedRow(index);
    await reportFetch(index);
    await pophandleReportOpen();
  };

  return (
    <>
      {/* 새보고서 작성 탭 */}
      <ConfirmNewReport1
        open={popOpen}
        onClose={pophandleClose}
        open2={pophandleOpen2}
        selected={selected}
        setSelected={setSelected}
      />
      {/* 새보고서 작성 탭 2*/}
      <ConfirmNewReport2
        open={popOpen2}
        onClose={pophandleClose2}
        selected={selected}
      />
      {/* 보고서 삭제 모달 */}
      <CofirmDeleteModal
        open={popOpen3}
        onClose={pophandleClose3}
        handleDelete={handleDelete}
      />
      {/* 증빙자료 모달 */}
      <AttachmentModal
        open={popOpen4}
        onClose={pophandleClose4}
        // files={boardData && boardData?.boards[clickedRow]?.files}
        type={selected.type}
        inloNo={selected.inloNo}
        yyyyMmRaw={boardData && boardData?.boards[clickedRow]?.yyyyMmRaw}
      />
      {/* 보고서 모달 */}
      <ReportModal
        open={popReport}
        onClose={pophandleReportClose}
        pdfFlag={pdfFlag}
        ref={reportRef}
        type={selected.type}
        id={boardData && boardData?.boards[clickedRow]?.id}
        yyyyMm={boardData && boardData?.boards[clickedRow]?.yyyyMmRaw}
        inloNo={selected.inloNo}
        reportData={reportData}
        decodedToken={decodedToken}
      />

      <div className="CarbonPageTitle">
        <strong>보고서</strong>
      </div>
      <div className="CarbonTopSelect">
        <div className="selArea">
          <p className="label">기준</p>
          <FormControl>
            <Select
              value={selected.type}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon
                  sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                />
              )}
              MenuProps={MenuProps}
              onChange={(e) => {
                setSelected({ ...selected, type: e.target.value });
              }}
            >
              {selectOptions1.map((item, index) => {
                return (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* 기준이 '월간'일 때 연도 select box 표시 */}
          {selected.type == 'month' ?
          <>
            <p className="label">연도</p>
            <FormControl>
              <Select
                value={selected.year}
                displayEmpty
                className="basicSelect"
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={() => (
                  <ExpandMoreSharpIcon
                    sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                  />
                )}
                MenuProps={MenuProps}
                onChange={(e) => {
                  setSelected({ ...selected, year: e.target.value });
                }}
              >
                {isYearsLoading ? (
                <MenuItem>Loading...</MenuItem>
              ) : yearsError ? (
                <MenuItem>Error loading years</MenuItem>
              ) : (
                yearData?.map((year) => (
                  <MenuItem key={year.value} value={year.value}>
                    {year.label}
                  </MenuItem>
                ))
              )}
              </Select>
            </FormControl>
          </>
          : null}

          <p className="label">정렬</p>
          <FormControl>
            <Select
              value={selected.order}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon
                  sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                />
              )}
              MenuProps={MenuProps}
              onChange={(e) => {
                setSelected({ ...selected, order: e.target.value });
              }}
            >
              {selectOptions2.map((item, index) => {
                return (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* 기업  관리자 (멀티수용가 관리자) 에게 사업장명 선택 셀렉트박스 보여주기 */}
          {decodedToken &&
            (decodedToken.inloClCd === "COMPLEX" ||
              decodedToken.inloClCd === "PROJECT") && (
              <>
                <p className="label">사업장명</p>
                <FormControl>
                  <Select
                    value={selected.inloNo}
                    displayEmpty
                    className="basicSelect"
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={() => (
                      <ExpandMoreSharpIcon
                        sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                      />
                    )}
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      setSelected({ ...selected, inloNo: e.target.value });
                    }}
                  >
                    {selectOptions3.map((item, index) => {
                      return (
                        <MenuItem value={item.value} key={index}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            )}
        </div>
        <div className="btnArea">
          <button className="prm" onClick={pophandleOpen}>
            새 보고서 작성
          </button>
        </div>
      </div>
      <div className="CarbonBox">
        <TableContainer className="CarbonReportTable">
          <Table>
            <colgroup>
              <col width="10%" />
              <col />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="5%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>보고기준 기간</TableCell>
                <TableCell>보고서명</TableCell>
                <TableCell align="right">총 배출량</TableCell>
                <TableCell align="center">보고서 다운로드</TableCell>
                <TableCell>증빙자료</TableCell>
                <TableCell>생성일</TableCell>
                <TableCell></TableCell>
                {/* <TableCell align="center">삭제</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {boardData &&
                boardData?.boards.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.yyyyMm} </TableCell>
                      <TableCell onClick={() => viewReport(index)}>
                        {item.title}
                      </TableCell>
                      <TableCell align="right">
                        {formatNumber(item.totalEmission, 2)}
                        {" tCO2e"}
                      </TableCell>
                      {/* 보고서 다운로드 */}
                      <TableCell align="center">
                        {/* <IconButton onClick={handleDownloadPdf}> */}
                        <IconButton onClick={() => handleDownloadClick(index)}>
                          <SaveAltIcon />
                        </IconButton>
                      </TableCell>
                      {/* 증빙자료 */}
                      <TableCell>
                        {item.fileCount > 0 && (
                          <Button
                            variant="outlined"
                            startIcon={<NoteAddOutlinedIcon />}
                            className="CarbonTableBtn"
                            onClick={() => pophandleOpen4(index)}
                          >
                            {item.fileCount}개 파일
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>{item.regDtm}</TableCell>
                      <TableCell></TableCell>
                      {/* <TableCell align="center">
                        <IconButton>
                          <CloseOutlinedIcon onClick={pophandleOpen3} />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack className="CarbonPagination">
          <Pagination
            count={boardData?.totalPages}
            page={boardData?.currentPage}
            onChange={handlePageChange}
          />
        </Stack>
      </div>
    </>
  );
}

export default ReportBoard;
