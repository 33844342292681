import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../components/styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";

function Purpose(){
    const [topYear, setTopYear] = useState('2024');

    const TopYearChange = (event) => {
        setTopYear(event.target.value);
    };

    const [topComp, setTopComp] = useState('all');

    const TopCompChange = (event) => {
        setTopComp(event.target.value);
    };

    return (
        <>
            <div className="CarbonPageTitle">
                <strong>탄소저감 목표관리</strong>
            </div>
            <div className="CarbonTopSelect">
                <div className="selArea">
                    <p className="label">감축 방법론</p>
                    <FormControl>
                        <Select
                            value={topComp}
                            onChange={TopCompChange}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'all'}>생산량 원단위</MenuItem>
                            <MenuItem value={'2023'}>사업장1</MenuItem>
                            <MenuItem value={'2022'}>사업장2</MenuItem>
                            <MenuItem value={'2021'}>사업장3</MenuItem>
                            <MenuItem value={'2020'}>사업장4</MenuItem>
                            <MenuItem value={'2019'}>사업장5</MenuItem>
                            <MenuItem value={'2018'}>사업장6</MenuItem>
                        </Select>
                    </FormControl>
                    <p className="label">기준년도</p>
                    <FormControl>
                        <Select
                            value={topYear}
                            onChange={TopYearChange}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'2024'}>2024년</MenuItem>
                            <MenuItem value={'2023'}>2023년</MenuItem>
                            <MenuItem value={'2022'}>2022년</MenuItem>
                            <MenuItem value={'2021'}>2021년</MenuItem>
                            <MenuItem value={'2020'}>2020년</MenuItem>
                            <MenuItem value={'2019'}>2019년</MenuItem>
                            <MenuItem value={'2018'}>2018년</MenuItem>
                            <MenuItem value={'2017'}>2017년</MenuItem>
                            <MenuItem value={'2016'}>2016년</MenuItem>
                            <MenuItem value={'2015'}>2015년</MenuItem>
                            <MenuItem value={'2014'}>2014년</MenuItem>
                        </Select>
                    </FormControl>

                </div>
            </div>
            <div className="CarbonPurposeWrap">
                <div className="CarbonBox">
                    <div className="CarbonBoxTitle">
                        <b>목표관리 이행 현황</b>
                    </div>
                    <div className="PurposeInputWrap">
                        <div className="PurposeInput">
                            <div>
                                <label>기준연도 2년전의 판매 총 수량</label>
                                <input type="text" placeholder="수량을 입력해주세요."/>
                            </div>
                            <div>
                                <label>기준연도 1년전의 판매 총 수량</label>
                                <input type="text" placeholder="수량을 입력해주세요."/>
                            </div>
                            <div>
                                <label>기준연도의 판매 총 수량</label>
                                <input type="text" placeholder="수량을 입력해주세요."/>
                            </div>
                        </div>
                        <div className="PurposeSum">
                            2030년까지 기준연도 대비 판매 총 수량 <input type="text" placeholder="NN"/>% 성장 예상
                        </div>
                        <div className="btn">
                            <button disabled><span>결과 확인하기</span></button>
                        </div>
                        <div className="btn">
                            <button><span>결과 확인하기</span></button>
                        </div>
                    </div>
                </div>
                <div className="CarbonBox">
                    <div className="CarbonBoxTitle">
                    <b>적용 시나리오 이력</b>
                    </div>
                    <div className="scenarioGrid">
                        <div className="scenarioFile">
                            <p className="noData">적용 시나리오 이력이 없습니다.</p>
                        </div>
                        <div className="scenarioDetail">
                            <p className="noData">적용 시나리오 이력이 없습니다.</p>
                        </div>
                    </div>
                    <p className="scenarioBtmText">* 시나리오를 선택하시면 예상 감축량 정보를 보실 수 있습니다.</p>
                </div>
                <div className="scenarioBtn">
                    <button>관련 감축지원 사업 확인하기</button>
                </div>
            </div>

        </>
    );
}

export default Purpose;