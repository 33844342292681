import React from "react";
import CarbonReduceMBO from "./CarbonReduceMBO";
import { useParams } from "react-router-dom";
import DashBoard from "./DashBoard";
import CarbonEmissionCalculation from "./CarbonEmissionCalculation";
import CalculationForm from "../CarbonEmissionCalculation/CalculationForm";
import ReportBoard from "./ReportBoard";
import HistoryBoard from "./HistoryBoard";
import Calcu3Scope from "../../../PUB/Calcu3Scope";
import Step3Scope from "../../../PUB/Step3Scope";
import Login from "./Login";

const MainRoutes = () => {
  const { pageID } = useParams();

  const loadPage = (pageID) => {
    switch (pageID) {
      case "dash":
        return <DashBoard />;
      case "step":
        return <CarbonEmissionCalculation />;
      case "calculationForm":
        return <CalculationForm />;
      case "purpose":
        return <CarbonReduceMBO />;
      case "report":
        return <ReportBoard />;
      case "history":
        return <HistoryBoard />;
      case "calcu3Scope":
        return <Calcu3Scope />;
      case "step3Scope":
      return <Step3Scope />;
      case "login":
        return <Login />
      default:
        return <></>;
    }
  };
  return <>{loadPage(pageID)}</>;
};

export default MainRoutes;
