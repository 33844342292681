import React from "react";
import { useSelector } from "react-redux";
import { selectIsAnyApiLoading } from "../util/selectors";
import { LoadingFull } from "../common/loading/LoadingFull";

const GlobalLoadingComponent = () => {
  const isLoading = useSelector(selectIsAnyApiLoading);

  if (!isLoading) return null;

  return <LoadingFull />;
};

export default GlobalLoadingComponent;
