import { instance } from "../api";

/**
 * @description 테스트 엔드포인트 설정
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const test = (builder) => {
  return builder.query({
    query: () => `/api/notice/1`,
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 연도 조회
 * @author choiseonha
 * @date 03/07/2024
 * @param {*} builder
 */
const fetchYears = (builder) => {
  return builder.query({
    query: () => ({
      url: `/api/home/year`,
      method: "GET",
    }),
  });
};

/**
 * @description 사업장명 조회
 * @author seoeunPark
 * @date 31/07/2024
 * @param {Number} inloNo
 * @return
 */
const fetchInloName = (builder) => {
  return builder.query({
    query: ({ inloNo }) => {
      const params = {};
      if (inloNo) params.inloNo = inloNo;
      return {
        url: `/api/home/fac`,
        method: "GET",
        params,
      };
    },
  });
};

/**
 * @description 로그인 한 사용자의 사업장명 조회
 * @author seoeunPark
 * @date 31/07/2024
 * @param {Number} inloNo
 * @return
 */
const fetchUserInloName = (builder) => {
  return builder.query({
    query: () => ({
      url: `/api/home/fac/me`,
      method: "GET",
    }),
    transformResponse: (response) => {
      return response.data;
    },
  });
};

/**
 * @description 로그인 한 사용자의 전체 사업장 리스트 조회
 * @author choiseonha
 * @date 12/06/2024
 * @param {*} builder
 */
const fetchCompanies = (builder) => {
  return builder.query({
    query: () => ({
      url: `/api/home/facs`,
      method: "GET",
    }),
  });
};

/**
 * @description 파일 다운로드
 * @author
 * @date
 * @param {*} builder
 */
const fetchFileDownload = (builder) => {
  return builder.query({
    query: ({ fileName }) => {
      const url = `/api/filedownload`;
      const params = new URLSearchParams();
      params.append("fileName", fileName);

      // URL과 파라미터를 콘솔에 출력
      // console.log(`GET 요청 URL: ${url}`);
      // console.log("GET 요청 파라미터:", params.toString());

      return {
        url: `${url}?${params.toString()}`,
        method: "GET",
        responseType: "blob",
      };
    },
  });
};

/**
 * @description 공통 코드
 * @author seoeunPark
 * @date 10/07/2024
 * @param {*}
 * @return
 */
const fetchCommonCode = (builder) => {
  return builder.query({
    query: ({ cdClass, cdCode }) => {
      const params = {};
      if (cdClass) params.cdClass = cdClass;
      if (cdCode) params.cdCode = cdCode;

      if (cdClass)
        return {
          url: `/api/com/codes`,
          params,
        };
    },
    transformResponse: (response) => {
      return response.data;
    },
  });
};


/**
 * @description 파일 조회 공통 코드
 * @author choiseonha
 * @date 19/08/2024
 * @param {*}
 * @return
 */
export const axiosGetCalFilesData = async (params) => {
  const { userCurtCatgId = null, inloNo = null } = params;
  const response = await instance.get(`/api/cal/getCalFilesData`, {params});
  return response.data;
}

/**
 * @description 파일 다운로드 공통 코드
 * @author choiseonha
 * @date 19/08/2024
 * @param {*}
 * @return
 */
export const downloadFile = async (
  fileId,
  fileName,
) =>  {
  try {
    const response = await axiosFileDownload({
      fileId: fileId,
      fileName: fileName,
    });
  } catch (error) {
    console.log("downloadFile error >>", error);
  }
}

/**
 * @description 파일 다운로드 공통 코드
 * @author choiseonha
 * @date 19/08/2024
 * @param {*}
 * @return
 */
const axiosFileDownload = async (params) => {
  const { fileId = null, fileName = null } = params;

  try {
    const response = await instance.get(`/api/files/fileDownload?fileId=${fileId}`,
      {
        responseType: "blob",
      }
    );

    const blob = response.data;
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // 링크를 document.body에 추가한 후 제거
    document.body.appendChild(link);
    link.click(); // 링크를 클릭하여 파일 다운로드 시작
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // URL 객체를 해제하여 메모리 릭 방지
  } catch (e) {
    console.log("axiosGetCalFilesData error :", e);
  }
};

export const axiosFileUpload = async (params) => {
  const { files, userCurtCatgId, inloNo } = params;

  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  formData.append("userCurtCatgId", userCurtCatgId);
  formData.append("inloNo", inloNo);

  try {
    const response = await instance.post("/api/files/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("File upload error:", error);
    throw error;
  }
}

export const axiosFileDelete = async (deletedFiles) => {
  try {
    const response = await instance.delete(`/api/files/fileDelete`, {
      params: { fileSaveNames: deletedFiles },
      paramsSerializer: params => {
        return params.fileSaveNames.map(name => `fileSaveNames=${encodeURIComponent(name)}`).join('&');
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting files:", error);
    throw error;
  }
};

export const tscmsCommonEndpoints = (builder) => ({
  test: test(builder),
  fetchInloName: fetchInloName(builder),
  fetchUserInloName: fetchUserInloName(builder),
  fetchCompanies: fetchCompanies(builder),
  fetchFileDownload: fetchFileDownload(builder),
  fetchYears: fetchYears(builder),
  fetchCommonCode: fetchCommonCode(builder),
});
