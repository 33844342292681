import api from "./api";
import { tscmsReduceMboEndpoints } from "./endpoints/tscmsReduceMbo";

export const reduceMboApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ...tscmsReduceMboEndpoints(builder),
  }),
  overrideExisting: false,
});

export const {
  useFetchScenarioHistoryQuery, // fetchScenarioHistory
  useFetchRdtActionOptionsQuery, // fetchRdtActionOptions
  useLazyFetchRdtActionOptionsQuery, // fetchRdtActionOptions
  useSaveScenarioMutation, // saveScenario
  useFetchBaseEmissionsQuery, // fetchBaseEmissions
} = reduceMboApi;

export default reduceMboApi;
