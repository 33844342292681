import React from "react";
import { useParams } from "react-router-dom";
import CalculationForm from "../components/CarbonNote/CarbonEmissionCalculation/CalculationForm";
import Dash from "./Dash";
import Step from "./Step";
import Calcu from "./Calcu";
import Purpose from "./Purpose";
import PurposeGraph from "./PurposeGraph";
import Report from "./Report";
import HistoryList from "./HistoryList";
import Step3Scope from "./Step3Scope";
import Calcu3Scope from "./Calcu3Scope";

const Pub = () => {
  const { pageID } = useParams();
  const loadPage = (pageID) => {
    switch (pageID) {
      case "Dash":
        return <Dash />;
      case "Step":
        return <Step />;
      case "Calcu":
        return <Calcu />;
      case "CalculationForm":
        return <CalculationForm />;
      case "Purpose":
        return <Purpose />;
      case "PurposeGraph":
        return <PurposeGraph />;
      case "Report":
        return <Report />;
      case "HistoryList":
        return <HistoryList />;
      case "Step3Scope" :
        return <Step3Scope />;
        case "Calcu3Scope":
          return <Calcu3Scope />;
      default:
        return <></>;
    }
  };
  return <>{loadPage(pageID)}</>;
};
export default Pub;
