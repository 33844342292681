import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../components/styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {TitleCloseDialog} from "../components/styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";


function Report() {

    const cssRoot = window.getComputedStyle(document.documentElement);
    const chart1option = {
        chart: {
            backgroundColor:'transparent',
            type: 'pie',
            height: '180px',

        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: false
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                showInLegend: true,
                size: 150,
            },
            series: {
                borderWidth: 0,
                borderRadius: 0,
                colorByPoint: true,
                type: 'pie',
                size: '100%',
                innerSize: '45%',
                dataLabels: {
                    enabled: true,
                    formatter: function() {return this.y + "%"},
                    crop: false,
                    distance: '-30%',
                    style: {
                        color: '#fff',
                        textOutline: 'transparent',
                        fontSize: "12px"
                    },
                    connectorWidth: 0
                }
            }
        },
        series: [{
            type: 'pie',
            data: [
                {
                    y: 65,
                    name: "Scope1 : 직접배출량",
                    color: cssRoot.getPropertyValue('--scope1'),
                },
                {
                    y: 32,
                    name: "Scope2 : 간접배출량",
                    color: cssRoot.getPropertyValue('--scope2'),
                }
            ],
        }],
        exporting: {
            buttons: {
                contextButton: {
                    enabled: false
                }
            }
        },
    }
    const chart2option= {
        chart: {
            type: 'column',
            style: {
                fontFamily: 'Noto Sans KR',
            },
            spacingTop: 40,
        },
        exporting: {
            buttons: {
                contextButton: {
                    enabled: false
                }
            }
        },
        title: {
            text: null
        },
        xAxis: {
            categories: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
            labels : {
                style : {
                    color : cssRoot.getPropertyValue('--gray500'),
                }
            },
            lineColor : cssRoot.getPropertyValue('--line20'),
        },
        yAxis: {
            min: 0,
            max: 20000,
            title: {
                text: null,
            },
            tickInterval : 5000,
            labels : {
                format: '{value:,.0f}',
                style : {
                    color : cssRoot.getPropertyValue('--gray500'),
                }
            },
            gridLineDashStyle : "longdash"
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            reversed : true,
            shadow: false,
            y:-45
        },
        plotOptions: {
            column: {
                borderRadius: 4,
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
            },

        },
        series: [
            {
                name: 'Scope2',
                color: cssRoot.getPropertyValue('--scope2'),
                data: [7000, 3000, 7000, 3000,6500,6500,5000,7000,7000,4000,1500,6000],
                borderWidth: 0,
            },
            {
                name: 'Scope1',
                color: cssRoot.getPropertyValue('--scope1'),
                data: [5000, 12000, 1500, 1500,6000,2000,1500,5000,1500,4000,1500,6000],
                borderWidth: 0,
            },
        ],
        tooltip : {
            formatter : function() {
                return "<b>"+this.y +"</b> tCO2e";
            },
            backgroundColor: cssRoot.getPropertyValue('--gray700'),
            borderRadius: 4,
            padding : 12,
            style: {
                color: '#fff'
            }
        }
    }

    const [popOpen, setPopOpen] = useState(false);

    const pophandleClose = () => {
        setPopOpen(false);
    };
    const pophandleOpen = () => {
        setPopOpen(true);
    };

    const [popOpen2, setPopOpen2] = useState(false);

    const pophandleClose2 = () => {
        setPopOpen2(false);
    };
    const pophandleOpen2 = () => {
        setPopOpen2(true);
    };

    const [popOpen3, setPopOpen3] = useState(false);

    const pophandleClose3 = () => {
        setPopOpen3(false);
    };
    const pophandleOpen3 = () => {
        setPopOpen3(true);
    };

    const [popOpen4, setPopOpen4] = useState(false);

    const pophandleClose4 = () => {
        setPopOpen4(false);
    };
    const pophandleOpen4 = () => {
        setPopOpen4(true);
    };

    const [popReport, setPopReport] = useState(false);

    const pophandleReportClose = () => {
        setPopReport(false);
    };
    const pophandleReportOpen = () => {
        setPopReport(true);
    };

    return(
        <>
            <TitleCloseDialog
                open={popOpen}
                onClose={pophandleClose}
            >
                <DialogTitle>
                    새 보고서 작성
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <p className="typoTy1">보고서 작성을 희망하는 기간을 선택해주세요.</p>
                        <div className="doubleSel">
                            <FormControl fullWidth>
                                <Select
                                    className="popSelect"
                                    displayEmpty
                                    value={"1"}
                                    inputProps={{'aria-label': 'Without label'}}
                                    IconComponent={() => (
                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"1"}>2024년</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <Select
                                    className="popSelect"
                                    displayEmpty
                                    value={"1"}
                                    inputProps={{'aria-label': 'Without label'}}
                                    IconComponent={() => (
                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"1"}>2월</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="CarbonPopBtn">
                            <button className="cancle">취소</button>
                            <button onClick={pophandleOpen2}>확인</button>
                        </div>
                    </div>

                </DialogContent>
            </TitleCloseDialog>
            <TitleCloseDialog
                open={popOpen2}
                onClose={pophandleClose2}
            >
                <DialogTitle>
                    새 보고서 작성
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose2} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <p className="typoTy1">해당 기간 내 보고서 작성을 위한 탄소배출량 산정이 필요합니다.<br/>
                            탄소배출량 산정을 시작하시겠습니까?</p>
                        <div className="CarbonPopBtn">
                            <button className="cancle">취소</button>
                            <button>확인</button>
                        </div>
                    </div>
                </DialogContent>
            </TitleCloseDialog>


            <TitleCloseDialog
                open={popOpen3}
                onClose={pophandleClose3}
            >
                <DialogTitle>
                    보고서 삭제
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose3} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <p className="typoTy1">보고서를 삭제하시면 추후 [~~년 ~~월]에 대한 보고서 작성을<br />
                            다시 진행하셔야 합니다.<br />
                            <br />
                            정말 삭제하시겠습니까? </p>
                        <div className="CarbonPopBtn">
                            <button className="cancle">취소</button>
                            <button>확인</button>
                        </div>
                    </div>
                </DialogContent>
            </TitleCloseDialog>

            <TitleCloseDialog
                open={popOpen4}
                onClose={pophandleClose4}
            >
                <DialogTitle>
                    증빙 자료
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose3} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <div className="CarbonPopFileUpload">
                            <div className="uploadedList">
                                <dl>
                                    <dt>
                                        첨부파일 <b>NN</b>개
                                        <span className="btnArea">
                                            <button>모두저장</button>
                                            <button>전체삭제</button>
                                        </span>
                                    </dt>
                                    <dd>
                                        <ul>
                                            <li>
                                                <p className="fileName">
                                                    [1월 시설배치도.png] <span>4.5MB</span>
                                                </p>
                                                <p className="fileBtn">
                                                    <button className="down"></button>
                                                    <button className="del"></button>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="fileName">
                                                    [1월 시설배치도.png] <span>4.5MB</span>
                                                </p>
                                                <p className="fileBtn">
                                                    <button className="down"></button>
                                                    <button className="del"></button>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="fileName">
                                                    [1월 시설배치도.png] <span>4.5MB</span>
                                                </p>
                                                <p className="fileBtn">
                                                    <button className="down"></button>
                                                    <button className="del"></button>
                                                </p>
                                            </li>
                                        </ul>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div className="CarbonPopBtn">
                            <button className="cancle">취소</button>
                            <button>저장</button>
                        </div>
                    </div>
                </DialogContent>
            </TitleCloseDialog>
            <TitleCloseDialog
                open={popReport}
                onClose={pophandleReportClose}
            >
                <DialogTitle className="reportTitle">
                    <span>
                        2023년 월간 탄소배출량 보고서 (12월)
                        <IconButton>
                            <SaveAltIcon />
                        </IconButton>
                    </span>
                    <div>
                        <strong>울산 알루미늄</strong>
                        <ul>
                            <li>업태 : 알루미늄</li>
                            <li>배출량 산정 기간 : 2023년 12월</li>
                        </ul>
                    </div>
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleReportClose} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <div className="CarbonReportPop">
                            <div className="ReportGrid">
                                <div className="ReportGridItem">
                                    <dl>
                                        <dt>기간 내 총 배출량</dt>
                                        <dd>
                                            <strong>
                                                97<em className="carbonUnit">tCO2e</em>
                                            </strong>
                                            <div className="grayBox">
                                                배출량 추정치는 회원님이 제공한 활동 데이터만을 기준으로 합니다. 누락된 활동은 산정 내역에 포함되지 않습니다. 관련하여 추가
                                                문의사항이
                                                있으시다면 고객센터를 통해 연락 주십시오.
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                                <div className="ReportGridItem">
                                    <dl>
                                        <dt>배출권 환산 비용</dt>
                                        <dd>
                                            <strong>
                                                679,000<em className="carbonUnit">원</em>
                                            </strong>
                                            <div className="grayBox">
                                                회원님의 탄소배출량을 국내 배출권 시장 2022년 할당배출권 평균 가격으로
                                                환산한 값입니다.
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="ReportGrid">
                                <div className="ReportGridItem">
                                    <dl>
                                        <dt>
                                            배출량 세부 내역
                                            <span className="unit">(단위 : tCO2e)</span>
                                        </dt>
                                        <dd>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <th rowSpan={4}>
                                                        Scope1
                                                    </th>
                                                    <td>고정연소</td>
                                                    <td><span className="second500">10%</span></td>
                                                    <td>10</td>
                                                </tr>
                                                <tr>
                                                    <td>이동연소</td>
                                                    <td><span className="second500">35%</span></td>
                                                    <td>35</td>
                                                </tr>
                                                <tr>
                                                    <td>공정배출</td>
                                                    <td><span className="second500">10%</span></td>
                                                    <td>10</td>
                                                </tr>
                                                <tr>
                                                    <td>폐기물 소각</td>
                                                    <td><span className="second500">10%</span></td>
                                                    <td>10</td>
                                                </tr>
                                                <tr>
                                                    <th rowSpan={2}>
                                                        Scope2
                                                    </th>
                                                    <td>전력 사용</td>
                                                    <td><span className="second500">NN%</span></td>
                                                    <td>22</td>
                                                </tr>
                                                <tr>
                                                    <td>전력 사용</td>
                                                    <td><span className="second500">NN%</span></td>
                                                    <td>22</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <th colSpan={3}>합계</th>
                                                    <td>109</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </dd>
                                    </dl>
                                </div>
                                <div className="ReportGridItem">
                                    <dl>
                                        <dt>Scope별 배출량</dt>
                                        <dd>
                                            <div className="chartScope">
                                                <HighchartsReact highcharts={Highcharts} options={chart1option} />
                                                <ul>
                                                    <li>
                                                        <em>Scope1 : 직접 배출량</em>
                                                        <strong>65<em className="carbonUnit">tCO2e</em></strong>
                                                        <u className="scope1"></u>
                                                    </li>
                                                    <li>
                                                        <em>Scope2 : 간접 배출량</em>
                                                        <strong>32<em className="carbonUnit">tCO2e</em></strong>
                                                        <u className="scope2"></u>
                                                    </li>
                                                </ul>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            사업장별 배출량
                                            <span className="unit">(단위 : tCO2e)</span>
                                        </dt>
                                        <dd>
                                            <table>
                                                <colgroup>
                                                    <col />
                                                    <col width="35%" />
                                                    <col width="15%"/>
                                                </colgroup>

                                                <tbody>
                                                <tr>
                                                    <th>사업장 1</th>
                                                    <td><span className="second500">55%</span></td>
                                                    <td>55</td>
                                                </tr>
                                                <tr>
                                                    <th>사업장 2</th>
                                                    <td><span className="second500">42%</span></td>
                                                    <td>42</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="MonthlyReport">
                                <dl>
                                    <dt>월별 배출량 추이</dt>
                                    <dd>
                                        <div className="chartTitle">
                                            <b>올해 월별 배출량</b>
                                            <span className="unit">(단위 : tCO2e)</span>
                                        </div>
                                        <HighchartsReact highcharts={Highcharts} options={chart2option} />

                                        <table style={{marginTop: "50px"}}>
                                            <thead>
                                            <tr>
                                                <th>배출량</th>
                                                {[...Array(12)].map((value, index) => {
                                                    return (
                                                        <th>{index + 1}월</th>
                                                    );
                                                })}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>scope1</th>
                                                {[...Array(12)].map((value, index) => {
                                                    return (
                                                        <td>12345</td>
                                                    );
                                                })}
                                            </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <th>합계</th>
                                                {[...Array(12)].map((value, index) => {
                                                    return (
                                                        <td>24,690</td>
                                                    );
                                                })}
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </TitleCloseDialog>
            <div className="CarbonPageTitle">
                <strong>보고서</strong>
            </div>
            <div className="CarbonTopSelect">
                <div className="selArea">
                    <p className="label">기준</p>
                    <FormControl>
                        <Select
                            value={"1"}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'1'}>월간</MenuItem>
                        </Select>
                    </FormControl>
                    <p className="label">정렬</p>
                    <FormControl>
                        <Select
                            value={"1"}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'1'}>최신순</MenuItem>
                        </Select>
                    </FormControl>

                </div>
                <div className="btnArea">
                    <button className="prm" onClick={pophandleOpen}>새 보고서 작성</button>
                </div>
            </div>
            <div className="CarbonBox">
                <TableContainer className="CarbonReportTable">
                    <Table>
                        <colgroup>
                            <col width="10%"/>
                            <col/>
                            <col width="10%"/>
                            <col width="10%"/>
                            <col width="10%"/>
                            <col width="10%"/>
                            <col width="5%"/>
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>보고기준 기간</TableCell>
                                <TableCell>보고서명</TableCell>
                                <TableCell>총 배출량</TableCell>
                                <TableCell align="center">보고서 다운로드</TableCell>
                                <TableCell>증빙자료</TableCell>
                                <TableCell>생성일</TableCell>
                                <TableCell align="center">삭제</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...Array(10)].map((value, index) => {
                                return (
                                    <TableRow>
                                        <TableCell>2023년 12월</TableCell>
                                        <TableCell onClick={pophandleReportOpen}>2023년 월간 탄소배출량 보고서 (12월)</TableCell>
                                        <TableCell>000 tCO2e</TableCell>
                                        <TableCell align="center">
                                            <IconButton>
                                                <SaveAltIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <Button variant="outlined" startIcon={<NoteAddOutlinedIcon />} className="CarbonTableBtn" onClick={pophandleOpen4}>
                                                NN개 파일
                                            </Button>
                                        </TableCell>
                                        <TableCell>2024.02.04</TableCell>
                                        <TableCell align="center">
                                            <IconButton>
                                                <CloseOutlinedIcon onClick={pophandleOpen3}/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack className="CarbonPagination">
                    <Pagination count={10} />
                </Stack>
            </div>
        </>
    );
}

export default Report;