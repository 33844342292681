import chkimg from "../../images/Carbon/ic_check.svg";

const MenuProps = {
    PaperProps: {
        sx : {
            maxHeight : 320,
            borderRadius: "4px",
            border: "1px solid #d3d3d4",
            marginTop: "8px",
            '&::-webkit-scrollbar' : {width: "6px"},
            '&::-webkit-scrollbar-thumb' : {
                backgroundColor : "rgba(0,0,0,.2)",
            },
            "& .MuiMenuItem-root": {
                color : "#666",
                height: 40,
                fontSize: "14px",
                fontWeight: 400,
                fontFamily : "Noto Sans KR",
                "&:hover" : {
                    color : "#666",
                    backgroundColor : "#ebebeb"
                },
                "&.Mui-selected" : {
                    background : "none",
                    color : "#3ebdf3",
                    '&:after' : {
                        content : `""`,
                        display: 'block',
                        width: '20px',
                        height: '20px',
                        backgroundImage: 'url('+chkimg+')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 50%',
                    }
                }
            }
        }
    },
};

export default MenuProps;