import React, { useState, useEffect } from "react";
import { formatNumber } from "../common/commonData";

/**
 * @description DashBoard 컨테이너 → 목표관리
 * @author Choi Seon ha
 * @date 10/06/2024
 * @param {*} builder
 * @return {JSX.Element}
 */

const GoalManagement = ({ goalManagementData }) => {
  const [unit, setUnit] = useState("tCO2e");

  return (
    <>
      <div className="DashGridItem">
        <p className="DashItemTitle">
          <b>목표관리</b>
        </p>
        <ul className="totalPurpose">
          <li>
            <span>목표배출량</span>
            <strong>
              {formatNumber(goalManagementData?.goalEmission)}
              <i className="carbonUnit">{unit}</i>
            </strong>
          </li>
          <li>
            <span>달성율</span>
            <em>목표 대비 {formatNumber(goalManagementData?.percent)}% 배출</em>
          </li>
        </ul>
      </div>
    </>
  );
};

export default GoalManagement;
