import { setGraphMaxMin } from "../CarbonReduceMBO/carbonReduceMBO.helper";
import { formatNumber } from "../common/commonData";

const cssRoot = window.getComputedStyle(document.documentElement);

export const getChart1option = (scopeEmissionList, pdfFlag = false) => {

  return {
    chart: {
      backgroundColor: "transparent",
      type: "pie",
      height: "180px",
    },
    title: {
      text: null,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    accessibility: {
      enabled: false
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        size: 150,
      },
      series: {
        animation: !pdfFlag,
        borderWidth: 0,
        borderRadius: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "45%",
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y + "%";
          },
          crop: false,
          distance: "-30%",
          style: {
            color: "#fff",
            textOutline: "transparent",
            fontSize: "12px",
          },
          connectorWidth: 0,
        },
      },
    },
    series: [
      {
        type: "pie",
        data: scopeEmissionList.map((item) => {
          let color;
          switch (item.name) {
            case "S1":
              color = cssRoot.getPropertyValue(`--scope1`);
              break;
            case "S2":
              color = cssRoot.getPropertyValue(`--scope2`);
              break;
            case "S3":
              color = cssRoot.getPropertyValue(`--scope3`);
              break;
          }
          return {
            y: item.percent,
            name: item.name + " : " + item.type,
            color: color,
          };
        }),
        // [
        //   {
        //     y: 65,
        //     name: "Scope1 : 직접배출량",
        //     color: cssRoot.getPropertyValue("--scope1"),
        //   },
        //   {
        //     y: 32,
        //     name: "Scope2 : 간접배출량",
        //     color: cssRoot.getPropertyValue("--scope2"),
        //   },
        // ],
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
  };
};

export const getChart2option = (stateEmissionList, pdfFlag = false) => {
  return {
    chart: {
      type: "column",
      style: {
        fontFamily: "Noto Sans KR",
      },
      spacingTop: 40,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ],
      labels: {
        style: {
          color: cssRoot.getPropertyValue("--gray500"),
        },
      },
      lineColor: cssRoot.getPropertyValue("--line20"),
    },
    yAxis: {
      min: 0,
      max: setGraphMaxMin(stateEmissionList).max,
      title: {
        text: null,
      },
      tickInterval: setGraphMaxMin(stateEmissionList).max < 5000 ? null : 5000,
      labels: {
        format: "{value:,.0f}",
        style: {
          color: cssRoot.getPropertyValue("--gray500"),
        },
      },
      gridLineDashStyle: "longdash",
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      floating: true,
      reversed: true,
      shadow: false,
      y: -45,
    },
    plotOptions: {
      column: {
        animation: !pdfFlag,
        borderRadius: 4,
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: stateEmissionList?.map((emission) => {
      return {
        name: emission.name,
        color: cssRoot.getPropertyValue(`--${emission.name?.toLowerCase()}`),
        data: emission.data,
        borderWidth: 0,
      };
    }),
    // [
    //   {
    //     name: "Scope2",
    //     color: cssRoot.getPropertyValue("--scope2"),
    //     data: [
    //       null, 3000, 7000, 3000, 6500, 6500, 5000, 7000, 7000, 4000, 1500,
    //       6000,
    //     ],
    //     borderWidth: 0,
    //   },
    //   {
    //     name: "Scope1",
    //     color: cssRoot.getPropertyValue("--scope1"),
    //     data: [
    //       null, 12000, 1500, 1500, 6000, 2000, 1500, 5000, 1500, 4000, 1500,
    //       6000,
    //     ],
    //     borderWidth: 0,
    //   },
    // ],
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.series.name +
          "</b><br/>" +
          this.x +
          " : <b>" +
          formatNumber(this.y) +
          "</b> tCO2e"
        );
      },
      backgroundColor: cssRoot.getPropertyValue("--gray700"),
      borderRadius: 4,
      padding: 12,
      style: {
        color: "#fff",
      },
    },
    credits: {
      enabled: false,
    },
  };
};
