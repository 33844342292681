import { instance } from "../api";

/**
 * @description 시현용 로그인 API
 * @author seoeunPark
 * @date 25/07/2024
 * @param {*}
 * @return
 */
export const login = async (params) => {
  const { username, password } = params;

  const response = await instance.post(
    `/api/login?username=${username}&password=${password}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    }
  );

  return response;
};

export const authEndpoints = (builder) => ({});
