import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import Layout from "./components/layout/Layout";
import Pub from "./PUB/Pub";
import MainRoutes from "./components/CarbonNote/container/MainRoutes";

function App() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchUserData() {
      if (token) {
      } else {
        navigate("/login"); // 토큰이 없으면 로그인 페이지로 리다이렉트
      }
    }

    fetchUserData();
  }, [token, navigate]);

  return (
    <Layout>
      <Routes>
        {/* 기본 주소를 /pub/Dash로 설정 */}
        {/* <Route path="/" element={<Navigate to="/pub/Dash" />} /> */}
        <Route path="/" element={<Navigate to="/dash" />} />
        <Route path="/pub/:pageID" element={<Pub pageID={":pageID"} />} />
        <Route path="/:pageID" element={<MainRoutes pageID={":pageID"} />} />
      </Routes>
    </Layout>
  );
}

export default () => (
  <Router>
    <App />
  </Router>
);
