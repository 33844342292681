import React, { useState, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuItem from "@mui/material/MenuItem";
import MenuProps from "../../styleModule/MenuProps";
import { TitleCloseDialog } from "../../styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CalcuBox from "./CalcuBox";
import {
  useFetchFormSaveMutation,
  useLazyFetchTabsAndFormDataQuery,
  useFetchCalYearsQuery,
  useFetchCalCompaniesQuery,
  useFetchTemplateQuery,
  useFetchManagerQuery,
} from "../../../modules/features/api/calculationFormApi";
import {
  useFetchUserInloNameQuery,
  useLazyFetchFileDownloadQuery,
} from "../../../modules/features/api/commonApi";
import { month } from "./CarbonEmissionCalculationData";
import {
  resetSteps,
  selectProcess,
  toggleProcess,
} from "../../../modules/features/slice/stepSlice";
import { useFetchHomeCompaniesQuery } from "../../../modules/features/api/dashBoardApi";
import {
  axiosDeleteCatgTemp,
  axiosFetchBeforeSaved,
} from "../../../modules/features/api/endpoints/tscmsCalculationForm";
import {
  axiosFileDelete,
  axiosFileUpload,
  axiosGetCalFilesData,
  downloadFile,
} from "../../../modules/features/api/endpoints/tscmsCommon";
import { useLoading } from "../../util/LoadingContext";
import PurchasedProductsService from "./PurchasedProductsService";

const CalculationForm = ({ data }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCurrentMonth = () => {
    const currentDate = new Date();
    return currentDate.getMonth() + 1;
  };

  const [role, setRole] = useState("S");
  const [inloClCd, setInloClCd] = useState("COMPLEX");
  const [selectedYear, setSelectedYear] = useState(location.state?.year || "");
  const [selectedMonth, setSelectedMonth] = useState(
    location.state?.month || 1
  );
  const [selectedCompany, setSelectedCompany] = useState(
    location.state?.company || ""
  );
  const [year, setYear] = useState([]);
  const [company, setCompany] = useState([]);
  const [popOpen, setPopOpen] = useState(false);
  const [popOpen2, setPopOpen2] = useState({ open: false, index: null });
  const [popOpen3, setPopOpen3] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabCalcuBoxes, setTabCalcuBoxes] = useState({});
  const [serverFiles, setServerFiles] = useState({}); // 서버에서 조회된 파일들
  const [uploadedFiles, setUploadedFiles] = useState({}); // 사용자가 업로드한 파일들
  const [invalidFile, setInvalidFile] = useState(false);
  const [serverTabs, setServerTabs] = useState([]);
  const [calcuLists, setCalcuLists] = useState({});
  const [inloInitList, setInloInitList] = useState([]);
  const [inloSaveList, setInloSaveList] = useState([]);
  const [currentTList, setCurrentTList] = useState([]);
  const [focusData, setFocusData] = useState(null);
  const inputRefs = useRef({});
  const [tabData, setTabData] = useState({}); // 모든 탭의 데이터를 저장하는 객체 (변수생성)
  const [isPreviousLoaded, setIsPreviousLoaded] = useState(true);
  const [decodedToken, setDecodedToken] = useState(null);
  const [calcuBoxCount, setCalcuBoxCount] = useState({});
  const [filterOwnAssignments, setFilterOwnAssignments] = useState(false); // 체크박스 상태 관리
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [removedFiles, setRemovedFiles] = useState({});
  const { loading, stopLoading } = useLoading();

  const fileInputRef = useRef(null);
  const [fetchFormSave] = useFetchFormSaveMutation();
  const [fetchFileDownload] = useLazyFetchFileDownloadQuery();
  const [fetchTabsAndFormData, { isLoading, isError, error }] =
    useLazyFetchTabsAndFormDataQuery();

  const selectedProcesses = useSelector(
    (state) => state.step.selectedProcesses
  );
  const [redirecting, setRedirecting] = useState(false);

  const padMonth = (month) => month.toString().padStart(2, "0");

  // 상태 초기화 함수
  const resetState = () => {
    setInloInitList([]);
    setInloSaveList([]);
    setServerTabs([]);
    setCalcuLists({});
  };

  const {
    data: yearsData,
    error: yearsError,
    isLoading: isYearsLoading,
  } = useFetchCalYearsQuery();

  const {
    data: calCompanyData,
    error: companyError,
    isLoading: isCompanyLoading,
  } = useFetchHomeCompaniesQuery();

  // 사용자의 사업장명 조회
  const { data: userInloNameData, refetch: refetchUserInloName } =
    useFetchUserInloNameQuery();

  // 이전 내역 불러오기
  const handleLoadPrevious = async () => {
    // 여기에 delete와 insert 기능을하는 백을 호출한다음
    // previous 데이터가 아니라 현재 year, month를 그대로 호출하도록 해야한다.
    const yyyyMm = `${selectedYear}${padMonth(selectedMonth)}`;
    const resultData = await axiosFetchBeforeSaved({
      yyyyMm,
      inloNo: selectedCompany,
    });
    //console.log("resultData ::::", resultData);
    if (resultData.data == "success") {
      navigate("/step", {
        state: {
          year: selectedYear,
          month: selectedMonth,
          company: selectedCompany,
        },
      });
    } else {
      alert("이전 내역이 존재하지 않습니다");
    }
  };

  const { data: managerData } = useFetchManagerQuery({
    inloNo: selectedCompany ? selectedCompany : localStorage.getItem("inloNo"),
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      //console.log("decodedToken >>>>", decodedToken);
      setDecodedToken(decodedToken);
      setRole(decodedToken.role);
      setInloClCd(decodedToken.inloClCd);
    }
  }, []);

  useEffect(() => {
    if (!isYearsLoading && yearsData) {
      const sortedYears = [...yearsData.data].sort((a, b) => b - a);
      const formattedYears = sortedYears.map((year) => ({
        value: year,
        label: `${year}년`,
      }));
      setYear(formattedYears);
      if (location.state?.year) {
        setSelectedYear(location.state.year);
      } else if (formattedYears.length > 0) {
        setSelectedYear(formattedYears[0].value);
      }
    }
  }, [isYearsLoading, yearsData]);

  useEffect(() => {
    if (location.state) {
      const { year, month, company } = location.state;
      // console.log("year:", year)
      // console.log("month:", month)
      // console.log("company:", company)
      setSelectedYear(year);
      setSelectedMonth(month);
      setSelectedCompany(company);
    }
  }, [location.state]);

  useEffect(() => {
    if (!isCompanyLoading && calCompanyData) {
      const formattedCompanies = calCompanyData.data.map((company) => ({
        value: company.lowerInloNo,
        label: company.inloName,
      }));
      if (formattedCompanies.length > 0) {
        // setSelectedCompany(formattedCompanies[0].value);
        setCompany(formattedCompanies);
      }
    }
  }, [isCompanyLoading, calCompanyData]);

  const yearChange = (event) => {
    setSelectedYear(event.target.value);
    navigate("/step", {
      state: {
        year: event.target.value,
        month: selectedMonth,
        company: selectedCompany,
      },
    });
  };

  const monthChange = (event) => {
    setSelectedMonth(event.target.value);
    navigate("/step", {
      state: {
        year: selectedYear,
        month: event.target.value,
        company: selectedCompany,
      },
    });
  };

  const companyChange = (event) => {
    setSelectedCompany(event.target.value);
    navigate("/step", {
      state: {
        year: selectedYear,
        month: selectedMonth,
        company: event.target.value,
      },
    });
  };

  const generateUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const pophandleClose = () => setPopOpen(false);
  const pophandleOpen = () => setPopOpen(true);

  const pophandleClose2 = () => setPopOpen2({ open: false, index: null });

  const pophandleOpen2 = async (identifier, selectedCompany) => {
    // console.log("Identifier >>>", identifier);

    if (typeof identifier === "number") {
      // identifier가 userCurtCatgId인 경우
      const params = { userCurtCatgId: identifier, inloNo: selectedCompany };

      try {
        const filesData = await axiosGetCalFilesData(params);
        //console.log("filesData >>>>>>>", filesData.data);

        setServerFiles((prevState) => ({
          ...prevState,
          [identifier]: filesData.data, // userCurtCatgId를 키로 사용하여 저장
        }));
      } catch (error) {
        console.error("Error fetching files data:", error);
      }
    }

    // 팝업 열기 (새로 생성된 경우에도 동일하게 동작)
    setPopOpen2({ open: true, identifier, company: selectedCompany });
  };

  const pophandleClose3 = () => setPopOpen3(false);
  const pophandleOpen3 = () => setPopOpen3(true);

  const [testName, setTestName] = useState(selectedCompany);

  useEffect(() => {
    if (popOpen3) {
      setTestName(selectedCompany);
    }
  }, [popOpen3, selectedCompany]);

  const handleTabDataChange = (tabKey, boxIndex, newData) => {
    // 추가된 함수
    setCalcuLists((prevCalcuLists) => {
      const updatedCalcuList = [...(prevCalcuLists[tabKey] || [])];
      updatedCalcuList[boxIndex] = {
        ...updatedCalcuList[boxIndex],
        ...newData,
      };
      return { ...prevCalcuLists, [tabKey]: updatedCalcuList };
    });

    setTabData((prevTabData) => {
      const updatedTabData = {
        ...prevTabData,
        [tabKey]: prevTabData[tabKey] ? [...prevTabData[tabKey]] : [], // 빈 배열로 초기화
      };
      if (!updatedTabData[tabKey][boxIndex]) {
        updatedTabData[tabKey][boxIndex] = {}; // 해당 인덱스에 빈 객체로 초기화
      }
      updatedTabData[tabKey][boxIndex] = {
        ...updatedTabData[tabKey][boxIndex],
        ...newData,
      };
      return updatedTabData;
    });
  };

  // inloSaveList 데이터 -> CalcuBox 필드 데이터로 변환
  const transformData = (data, currentTList) => {
    const findActCatgType = (value, level) => {
      const match = currentTList.find((t) => t[`actCatg${level}`] === value);
      return match ? match[`actCatgType${level}`] : null;
    };

    return data.map((item) => {
      const transformedItem = {
        userCurtCatgId: item.userCurtCatgId || 0,
        inloCurtCatgId: item.inloCurtCatgId || 0,
        inloNo: item.inloNo,
        chgDtm: item.chgDtm,
        facNo: item.facNo,
        fuel: item.fuel,
        useVol: item.useVol,
        userNo: item.userNo || 0,
        facName: item.facName,
        userName: item.userName,
        chgUserNo: item.chgUserNo,
        unit: item.unit,
        calOkYn: item.calOkYn === "1",
        curtCatgNo: item.curtCatgNo,
        lastUpdate: item.lastUpdate,
        yyyyMm: item.yyyyMm,
        vars: item.vars,
        inputName1: "useVal",
        selectedValues: {
          actCatg1: item.actCatg1,
        },
        actCatg1: item.actCatg1,
        //actCatgType1: findActCatgType(item.actCatg1, 1),
        actCatgType1: item.actCatgType1,
        actCatg2: item.actCatg2,
        //actCatgType2: findActCatgType(item.actCatg2, 2),
        actCatgType2: item.actCatgType2,
        actCatg3: item.actCatg3,
        //actCatgType3: findActCatgType(item.actCatg3, 3),
        actCatgType3: item.actCatgType3,
        actCatg4: item.actCatg4,
        //actCatgType4: findActCatgType(item.actCatg4, 4),
        actCatgType4: item.actCatgType4,
        actCatg5: item.actCatg5,
        //actCatgType5: findActCatgType(item.actCatg5, 5),
        actCatgType5: item.actCatgType5,
        actCatg6: item.actCatg6,
        //actCatgType6: findActCatgType(item.actCatg6, 6),
        actCatgType6: item.actCatgType6,
        baseCbem: item.baseCbem,
        regDtm: item.regDtm,
        regUserNo: item.regUserNo,
        actCatgNo: item.actCatgNo
      };

      // inputVal 필드를 확인하여 존재하는 경우에만 inputText 필드를 추가
      if (item.inputVal1) {
        transformedItem.inputVal1 = item.inputVal1;
        transformedItem.inputText1 = item.inputVal1;
      }
      if (item.inputVal2) {
        transformedItem.inputVal2 = item.inputVal2;
        transformedItem.inputText2 = item.inputVal2;
        transformedItem.inputName2 = item.inputName2 || null;
      }
      if (item.inputVal3) {
        transformedItem.inputVal3 = item.inputVal3;
        transformedItem.inputText3 = item.inputVal3;
        transformedItem.inputName3 = item.inputName3 || null;
      }
      if (item.inputVal4) {
        transformedItem.inputVal4 = item.inputVal4;
        transformedItem.inputText4 = item.inputVal4;
        transformedItem.inputName4 = item.inputName4 || null;
      }

      return transformedItem;
    });
  };

  const handleChange = (event, newValue) => {
    // 변경사항
    // 현재 탭의 데이터를 저장
    setTabData((prevTabData) => ({
      ...prevTabData,
      [selectedTab]: calcuLists[selectedTab] || [],
    }));

    // 새로운 탭으로 변경
    setSelectedTab(newValue);

    // focusData 초기화
    setFocusData(null);

    // 새로운 탭의 데이터를 로드
    setCalcuLists((prevCalcuLists) => {
      const newTabData = tabData[newValue] || calcuLists[newValue] || [];
      return {
        ...prevCalcuLists,
        [newValue]: newTabData,
      };
    });

    // 탭 변경 후 handleTabClick 함수 호출
    handleTabClick(newValue);
  };

  useEffect(() => {
    const initializeState = async () => {
      if (location.state?.templateData && location.state?.categoryData) {
        // 상태 초기화
        resetState();

        // 상태 업데이트
        const templateData = location.state.templateData;
        const categoryData = location.state?.categoryData;

        // console.log("CalculationFrom templateData>", templateData);
        // console.log("CalculationFrom categoryData>", categoryData);

        if (templateData.inloInitList) {
          setInloInitList(templateData.inloInitList);
        }

        if (templateData.inloSaveList && templateData.inloSaveList.length > 0) {
          // sortSeq categoryData를 정렬
          const sortedCategoryData = categoryData.sort((a, b) => {
            if (a.sortSeq < b.sortSeq) return -1;
            if (a.sortSeq > b.sortSeq) return 1;
            return 0;
          });

          const uniqueTabs = sortedCategoryData.map((item) => ({
            value: item.curtCatgNo,
            label: item.cdDesc || `Tab ${item.curtCatgNo}`,
          }));
          setServerTabs(uniqueTabs);

          const initialTab = uniqueTabs.length > 0 ? uniqueTabs[0].value : null;
          setSelectedTab(initialTab);

          const transformedData = transformData(
            templateData.inloSaveList,
            currentTList
          );
          const initialCalcuLists = uniqueTabs.reduce((acc, tab) => {
            acc[tab.value] = transformedData.filter(
              (item) => item.curtCatgNo === tab.value
            );
            return acc;
          }, {});

          //console.log("initialCalcuLists >>>", initialCalcuLists);
          setCalcuLists(initialCalcuLists);
        } else if (
          templateData.inloInitList &&
          templateData.inloInitList.length > 0
        ) {
          // cdCode로 categoryData를 정렬
          const sortedCategoryData = categoryData.sort((a, b) => {
            if (a.sortSeq < b.sortSeq) return -1;
            if (a.sortSeq > b.sortSeq) return 1;
            return 0;
          });

          const uniqueTabs = sortedCategoryData.map((item) => ({
            value: item.curtCatgNo,
            label: item.cdDesc || `Tab ${item.curtCatgNo}`,
          }));
          setServerTabs(uniqueTabs);

          const initialTab = uniqueTabs.length > 0 ? uniqueTabs[0].value : null;
          setSelectedTab(initialTab);

          //console.log("uniqueTabs >>", uniqueTabs);
        }
      }
    };

    initializeState();
  }, [location.state?.templateData, location.state?.categoryData]);

  useEffect(() => {
    if (selectedTab) {
      handleTabClick(selectedTab);
    }
  }, [selectedTab, inloInitList]);

  // 파일 업로드 팝업을 열 때 UI를 갱신
  useEffect(() => {
    const updatedServerFiles = {};
    Object.keys(serverFiles).forEach((userCurtCatgId) => {
      updatedServerFiles[userCurtCatgId] = serverFiles[userCurtCatgId].filter(
        (file) => !removedFiles[userCurtCatgId]?.includes(file.fileSaveName)
      );
    });
    setServerFiles(updatedServerFiles);
  }, [popOpen2.open]);

  // 특정 탭에 맞는 필드를 결정하는 함수
  const getTabFields = (tab, currentTList) => {
    const fields = [];
    const filteredList = currentTList.filter((item) => item.curtCatgNo === tab);

    //console.log("filteredList >>", filteredList);

    if (filteredList.some((item) => item.inputText1 !== undefined)) {
      fields.push("inputText1");
    }
    if (filteredList.some((item) => item.inputText2 !== undefined)) {
      fields.push("inputText2");
    }
    if (filteredList.some((item) => item.inputText3 !== undefined)) {
      fields.push("inputText3");
    }
    if (filteredList.some((item) => item.inputText4 !== undefined)) {
      fields.push("inputText4");
    }
    return fields;
  };

  const addCalcuBox = () => {
    setCalcuLists((prevCalcuLists) => {
      const newCalcuBox = {
        userCurtCatgId: 0,
        inloCurtCatgId: 0,
        inloNo: 0,
        userNo: 0,
        calOkYn: true,
        facNo: 0,
        fuel: "",
        unit: "",
        useVol: "",
        yyyyMm: `${selectedYear}${padMonth(selectedMonth)}`,
        baseCbem: 0,
        inputName1: "useVal",
        curtCatgNo: selectedTab,
        tempId: generateUUID(),
      };

      // 선택된 탭에 맞는 currentTList를 필터링하고 필요한 inputText 및 inputVal 필드를 동적으로 추가
      const tabFields = getTabFields(selectedTab, currentTList);
      tabFields.forEach((field) => {
        newCalcuBox[field] = "";
      });

      const newCalcuList = [
        ...(prevCalcuLists[selectedTab] || []),
        newCalcuBox,
      ];
      //console.log("newCalcuList : ", newCalcuList);

      // CalcuBox의 개수를 업데이트
      setCalcuBoxCount((prevCount) => ({
        ...prevCount,
        [selectedTab]: newCalcuList.length,
      }));

      return { ...prevCalcuLists, [selectedTab]: newCalcuList };
    });
  };

  // CalcuBox 삭제
  const handleDeleteCalcuBox = (tabKey, index) => {
    setCalcuLists((prevCalcuLists) => {
      const updatedCalcuList = prevCalcuLists[tabKey].filter(
        (_, i) => i !== index
      );

      // 업데이트된 CalcuLists를 반환하면서 콘솔에 출력 (변경됨)
      const newCalcuLists = {
        ...prevCalcuLists,
        [tabKey]: updatedCalcuList,
      };
      //console.log("Updated calcuLists:", newCalcuLists); // 변경됨
      return newCalcuLists;
    });
  };

  const handleFileChange = (event) => {
    if (popOpen2.index === null) return;

    const newFiles = Array.from(event.target.files);
    const validFiles = newFiles.filter(
      (file) =>
        file.type === "text/csv" ||
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    if (validFiles.length !== newFiles.length) {
      setInvalidFile(true);
    } else {
      setInvalidFile(false);
      const identifier = popOpen2.identifier; // userCurtCatgId 또는 tempId

      setUploadedFiles((prevState) => ({
        ...prevState,
        [identifier]: [...(prevState[identifier] || []), ...validFiles],
      }));

      //console.log("Uploaded files:", uploadedFiles); // 디버깅용 로그
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    if (popOpen2.userCurtCatgId === null) return;

    const newFiles = Array.from(event.dataTransfer.files);
    const validFiles = newFiles.filter(
      (file) =>
        file.type === "text/csv" ||
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    if (validFiles.length !== newFiles.length) {
      setInvalidFile(true);
    } else {
      setInvalidFile(false);
      setUploadedFiles((prevState) => ({
        ...prevState,
        [popOpen2.userCurtCatgId]: [
          ...(prevState[popOpen2.userCurtCatgId] || []),
          ...validFiles,
        ],
      }));
    }
  };

  const removeFile = (userCurtCatgId, fileSaveNameOrIndex, fileType) => {
    if (fileType === "server") {
      const fileToDelete = serverFiles[userCurtCatgId].find(
        (file) => file.fileSaveName === fileSaveNameOrIndex
      );
      if (fileToDelete) {
        setDeletedFiles((prevState) => [
          ...prevState,
          fileToDelete.fileSaveName,
        ]);
        setServerFiles((prevState) => ({
          ...prevState,
          [userCurtCatgId]: prevState[userCurtCatgId].filter(
            (file) => file.fileSaveName !== fileSaveNameOrIndex
          ),
        }));
        setRemovedFiles((prevState) => ({
          ...prevState,
          [userCurtCatgId]: [
            ...(prevState[userCurtCatgId] || []),
            fileSaveNameOrIndex,
          ],
        }));
      }
    } else if (fileType === "uploaded") {
      setUploadedFiles((prevState) => ({
        ...prevState,
        [userCurtCatgId]: prevState[userCurtCatgId].filter(
          (_, index) => index !== fileSaveNameOrIndex
        ),
      }));
    }
  };

  const handleSave = async () => {
    try {
      // 삭제된 파일이 있는지 확인
      if (deletedFiles.length > 0) {
        //console.log("deletedFiles >>>", deletedFiles);
        await axiosFileDelete(deletedFiles);
      }

      const allCalcuBoxesData = Object.values(calcuLists).flat();
      const formData = new FormData();

      allCalcuBoxesData.forEach((box) => {
        const identifier =
          box.userCurtCatgId !== 0 ? box.userCurtCatgId : box.tempId;

        // JSON 데이터를 직접 FormData에 추가
        const jsonData = JSON.stringify(box);
        formData.append(`data_${identifier}`, jsonData);

        //console.log(`Appended data_${identifier}:`, jsonData);

        // 파일이 있는 경우, FormData에 파일을 개별적으로 추가
        if (uploadedFiles[identifier]) {
          uploadedFiles[identifier].forEach((file) => {
            formData.append(`files_${identifier}`, file);
            //console.log(`Appended files_${identifier}:`, file.name);
          });
        }
      });

      // for (let pair of formData.entries()) {
      //   if (pair[1] instanceof File) {
      //     console.log(`${pair[0]}: File - ${pair[1].name}`);
      //   } else {
      //     console.log(`${pair[0]}:`, pair[1]);
      //   }
      // }

      await fetchFormSave(formData).unwrap();

      // 성공 메시지 및 상태 초기화
      alert("저장 성공");
      resetState();
      navigate("/step", {
        state: {
          reload: true,
          year: selectedYear,
          month: selectedMonth,
          company: selectedCompany,
        },
      });
    } catch (error) {
      console.error("Error during save process:", error);
      alert("저장 실패");
    }
  };

  const updateCalcuBoxData = (tabKey, index, updatedData) => {
    setCalcuLists((prevCalcuLists) => {
      const newCalcuList = [...(prevCalcuLists[tabKey] || [])];
      newCalcuList[index] = { ...newCalcuList[index], ...updatedData };
      return { ...prevCalcuLists, [tabKey]: newCalcuList };
    });
  };

  // 배출구문 초기화
  const handleProceed = async () => {
    const stateData = {};
    const yyyyMm = `${selectedYear}${padMonth(selectedMonth)}`;

    const deleteCatgTempResponse = await axiosDeleteCatgTemp({
      yyyyMm,
      inloNo: selectedCompany,
      userNo: decodedToken.userNo,
    });

    if (deleteCatgTempResponse.data.resCode == 200) {
      navigate("/step", {
        state: {
          reload: true,
          year: selectedYear,
          month: selectedMonth,
          company: selectedCompany,
        },
      });
    }
  };

  // 내 할당 내역 불러오기
  const handleCheckboxChange = (event) => {
    setFilterOwnAssignments(event.target.checked);
  };

  // 내 할당 내역 불러오기 시 필터링된 CalcuBox 리스트를 반환하는 함수
  const getFilteredCalcuBoxes = () => {
    if (filterOwnAssignments) {
      return (calcuLists[selectedTab] || []).filter(
        (box) => box.userNo === decodedToken.userNo
      );
    }
    return calcuLists[selectedTab] || [];
  };

  // const handleFileDownload = async (fileId, fileName) => {
  //   console.log("fileId>>", fileId);
  //   console.log("fileName>>", fileName);
  //   try {

  //   } catch (e) {
  //     console.log("handleFileDownload error >>", e);
  //   }
  // };

  const TestNameChange = (event) => {
    const newComp = event.target.value;
    setTestName(newComp);
    setSelectedCompany(newComp);
  };

  const getIncompleteData = () => {
    const incompleteData = [];

    Object.keys(calcuLists).forEach((tabKey) => {
      const missingFieldsByBox = [];

      calcuLists[tabKey].forEach((box, index) => {
        const missingFields = [];
        const { selectedValues = {} } = box;

        if (!box.userNo) missingFields.push("userNo");
        if (!box.facNo) missingFields.push("factory");

        if (!selectedValues.actCatg1) {
          missingFields.push("actCatg1");
        } else {
          for (let i = 2; i <= 6; i++) {
            const actCatgKey = `actCatg${i}`;
            if (
              selectedValues.hasOwnProperty(actCatgKey) &&
              (selectedValues[actCatgKey] === undefined ||
                selectedValues[actCatgKey] === "")
            ) {
              missingFields.push(actCatgKey);
            }
          }
        }

        if (!box.unit) missingFields.push("unit");
        if (!box.inputText1) {
          missingFields.push("inputText1");
        } else {
          for (let i = 2; i <= 4; i++) {
            const inputTextKey = `inputText${i}`;
            if (
              selectedValues.hasOwnProperty(inputTextKey) &&
              selectedValues[inputTextKey] === ""
            ) {
              missingFields.push(inputTextKey);
              break;
            }
          }
        }

        if (missingFields.length > 0) {
          // userNo에 해당하는 userName을 찾기 위한 로직 추가
          const userName =
            managerData?.data?.find((user) => user.userNo === box.userNo)
              ?.userName || "";
          missingFieldsByBox.push({ index, missingFields, userName });
        }
      });

      if (missingFieldsByBox.length > 0) {
        // console.log("missingFieldsByBox > ", missingFieldsByBox);
        const foundTab = serverTabs.find(
          (tab) => tab.value === parseInt(tabKey)
        );

        incompleteData.push({
          tabKey,
          tabLabel: foundTab ? foundTab.label : "Unknown",
          period: `${selectedYear}/${padMonth(selectedMonth)}`,
          missingFieldsByBox,
          companyName: company
            ? company.find((comp) => comp.value === selectedCompany)?.label
            : "Unknown",
        });

        //미입력 항목이 있는 탭에 대해 콘솔에 출력
        // console.log(`탭: ${foundTab ? foundTab.label : "Unknown"} (${tabKey})`);
        // missingFieldsByBox.forEach(({ index, missingFields }) => {
        //   console.log(`  CalcuBox 인덱스: ${index}, 미입력 항목: ${missingFields.join(", ")}`);
        // });
      }
    });

    return incompleteData;
  };

  // 탭으로 이동하여 미입력된 항목 중 가장 앞에 있는 항목으로 포커스
  const handleFillData = (tabKey, boxIndex, missingField) => {
    setPopOpen3(false); // 팝업 닫기
    setTimeout(() => {
      setSelectedTab(parseInt(tabKey));
      setFocusData({ tabKey, boxIndex, missingField, fromButton: true }); // 포커스 데이터 설정
      //console.log("Setting focusData: ", { tabKey, boxIndex, missingField });
    }, 100); // 100ms 뒤에 실행하여 탭이 바뀌는 시간 확보
  };

  const incompleteData = getIncompleteData();

  const handleTabClick = (selectedTab) => {
    //console.log("selectedTab >>", selectedTab);
    const matchingItem = inloInitList.find(
      (item) => item.curtCatgNo == selectedTab
    );

    if (matchingItem) {
      //console.log("Matching item found:", matchingItem);
      setCurrentTList(matchingItem.tlist || []);
    } else {
      console.log("No matching item found for curtCatgNo:", selectedTab);
      setCurrentTList([]);
    }
  };

  const tabsToDisplay = () => {
    if (location.state?.templateData) return serverTabs;
  };

  return (
    <>
      <TitleCloseDialog open={popOpen3} onClose={pophandleClose3}>
        <DialogTitle className="inSelectTitle">
          미입력 데이터 관리
          <FormControl fullWidth>
            <Select
              className="popTitleSelect"
              displayEmpty
              value={testName}
              onChange={TestNameChange}
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
              )}
              MenuProps={MenuProps}>
              {company?.map((company) => (
                <MenuItem key={company.value} value={company.value}>
                  {company.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={pophandleClose3}
          className="dialogClose">
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="CarbonPopTable">
            <table>
              <colgroup>
                <col width="10%" />
                <col width="20%" />
                <col width="25%" />
                <col width="20%" />
                <col width="25%" />
              </colgroup>
              <thead>
                <tr>
                  <th>기준연월</th>
                  <th>미입력 항목</th>
                  <th>사업장명</th>
                  <th>담당자명</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {incompleteData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.period}</td>
                    <td>{item.tabLabel}</td>
                    <td>{item.companyName}</td>
                    <td>{item.missingFieldsByBox[0].userName}</td>
                    <td>
                      <button
                        onClick={() =>
                          handleFillData(
                            item.tabKey,
                            item.missingFieldsByBox[0].index,
                            item.missingFieldsByBox[0].missingFields[0]
                          )
                        }>
                        데이터 채우러가기
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DialogContent>
      </TitleCloseDialog>
      <TitleCloseDialog open={popOpen} onClose={pophandleClose}>
        <DialogTitle>배출구분 재설정</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={pophandleClose}
          className="dialogClose">
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="CarbonPopCont">
            <div className="confirmMsg">
              배출구분 재설정 시 기입력한 내용과 증빙자료가 삭제될 수 있습니다.{" "}
              <br />
              진행하시겠습니까?
            </div>
            <div className="CarbonPopBtn">
              <button className="cancle" onClick={pophandleClose}>
                취소
              </button>
              <button onClick={handleProceed}>진행</button>
            </div>
          </div>
        </DialogContent>
      </TitleCloseDialog>
      <TitleCloseDialog open={popOpen2.open} onClose={pophandleClose2}>
        <DialogTitle>증빙자료 업로드</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={pophandleClose2}
          className="dialogClose">
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="CarbonPopCont">
            <div className="CarbonPopFileUpload">
              <p className="topMsg">
                PDF, JPEG, PNG, 엑셀(csv, xlsx)파일만 업로드 가능합니다.
                <br />
                전송 버튼 클릭 시 배출량 검증인에게 증빙 자료가 전송되며{" "}
                <b>‘배출량 이력 관리’</b> 페이지에 업데이트 됩니다.
              </p>
              <div
                className="fileUploadForm"
                onDrop={handleFileDrop}
                onDragOver={(e) => e.preventDefault()}>
                <dl>
                  <dt>파일 첨부</dt>
                  <dd>
                    <p>파일을 마우스로 끌어 오세요</p>
                    <em>or</em>
                    <button
                      onClick={() =>
                        fileInputRef.current && fileInputRef.current.click()
                      }>
                      파일 첨부
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      multiple
                      onChange={handleFileChange}
                    />
                    {invalidFile && (
                      <div className="warning">
                        <span>파일 형식이 잘못되었습니다.</span>
                      </div>
                    )}
                  </dd>
                </dl>
              </div>
              <div className="uploadedList">
                <dl>
                  <dt>
                    첨부파일{" "}
                    <b>
                      {(serverFiles[popOpen2.identifier] || []).length +
                        (uploadedFiles[popOpen2.identifier] || []).length}
                    </b>
                    개
                  </dt>
                  <dd>
                    <ul>
                      {(serverFiles[popOpen2.identifier] || []).map((file) => (
                        <li key={file.fileId}>
                          <p
                            className="fileName"
                            onClick={() =>
                              downloadFile(file.fileId, file.fileName)
                            }>
                            {file.fileName}{" "}
                            <span>
                              {/* {(file.fileSize / (1024 * 1024)).toFixed(2)}MB */}
                              {file.fileSize}KB
                            </span>
                          </p>
                          <p className="fileBtn">
                            <button
                              className="del"
                              onClick={() =>
                                removeFile(
                                  popOpen2.identifier,
                                  file.fileSaveName,
                                  "server"
                                )
                              } // 서버 파일 삭제
                            ></button>
                          </p>
                        </li>
                      ))}
                      {(uploadedFiles[popOpen2.identifier] || []).map(
                        (file, index) => (
                          <li key={`uploaded-${index}`}>
                            <p className="fileName">
                              {file.name}{" "}
                              <span>
                                {/* {(file.fileSize / (1024 * 1024)).toFixed(2)}MB */}
                                {file.fileSize}KB
                              </span>
                            </p>
                            <p className="fileBtn">
                              <button
                                className="del"
                                onClick={() =>
                                  removeFile(
                                    popOpen2.identifier,
                                    index,
                                    "uploaded"
                                  )
                                } // 업로드된 파일 삭제
                              ></button>
                            </p>
                          </li>
                        )
                      )}
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="CarbonPopBtn">
              <button className="cancle" onClick={pophandleClose2}>
                취소
              </button>
              <button onClick={pophandleClose2}>진행</button>
            </div>
          </div>
        </DialogContent>
      </TitleCloseDialog>
      <div className="CarbonPageTitle">
        <strong>탄소배출량 산정</strong>
      </div>
      <div className="CarbonTopSelect">
        <div className="selArea">
          <p className="label">산정기간</p>
          <FormControl>
            <Select
              value={selectedYear}
              onChange={yearChange}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon
                  sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                />
              )}
              MenuProps={MenuProps}>
              {isYearsLoading ? (
                <MenuItem value="">Loading...</MenuItem>
              ) : yearsError ? (
                <MenuItem value="">Error loading years</MenuItem>
              ) : (
                year?.map((year) => (
                  <MenuItem key={year.value} value={year.value}>
                    {year.label}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              value={selectedMonth}
              onChange={monthChange}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon
                  sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                />
              )}
              MenuProps={MenuProps}>
              {month?.map((month) => (
                <MenuItem key={month.value} value={month.value}>
                  {month.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(inloClCd === "COMPLEX" || inloClCd === "PROJECT") && (
            <>
              <p className="label">사업장 선택</p>
              <FormControl>
                <Select
                  value={selectedCompany}
                  onChange={companyChange}
                  displayEmpty
                  className="basicSelect"
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value={userInloNameData ? userInloNameData[0]?.inloNo : ""}>
                    {userInloNameData ? userInloNameData[0].inloName : ""}
                  </MenuItem>
                  {isCompanyLoading ? (
                    <MenuItem>Loading...</MenuItem>
                  ) : companyError ? (
                    <MenuItem>Error loading companies</MenuItem>
                  ) : (
                    company?.map((company) => (
                      <MenuItem key={company.value} value={company.value}>
                        {company.label}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </>
          )}
        </div>
        <div className="btnArea">
          {decodedToken &&
          (decodedToken.inloClCd === "COMPLEX" ||
            decodedToken.inloClCd === "PROJECT" ||
            ((decodedToken.inloClCd === "COMPANY" ||
              decodedToken.inloClCd === "PLANT") &&
              decodedToken.role === "S")) ? (
            <>
              <button onClick={pophandleOpen3}>미입력 데이터 관리</button>
              <button onClick={pophandleOpen}>배출구분 재설정</button>
              <button onClick={handleLoadPrevious}>이전 내역 불러오기</button>
            </>
          ) : (
            <>
              <p>
                <input type="checkbox" onChange={handleCheckboxChange} />
                <label>내 할당 내역 불러오기</label>
              </p>
              <button onClick={pophandleOpen3}>미입력 데이터 관리</button>
            </>
          )}
        </div>
      </div>
      <div className="CarbonBox">
        <div className="CarbonbasicTab">
          {selectedTab ? (
            <Tabs value={selectedTab} onChange={handleChange}>
              {tabsToDisplay().map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>
          ) : null}
        </div>
        <div className="CarbonCalcuWrap">
          {selectedTab === 99999 ? (
              <div className="Calcu3ScopBoxOut">
                <PurchasedProductsService />
              </div>
          ) : (
            <div
              className={
                calcuBoxCount[selectedTab] > 4 ? "calcuList full" : "calcuList"
              }>
              {getFilteredCalcuBoxes().map((box, index) => (
                <CalcuBox
                  key={`calcuBox-${selectedTab}-${index}`} // 고유한 key 설정
                  id={`calcuBox-${selectedTab}-${index}`}
                  pophandleOpen2={() =>
                    pophandleOpen2(
                      box.userCurtCatgId !== 0
                        ? box.userCurtCatgId
                        : box.tempId,
                      selectedCompany
                    )
                  }
                  tabValue={selectedTab}
                  data={box}
                  onDataChange={(updatedData) =>
                    updateCalcuBoxData(selectedTab, index, updatedData)
                  }
                  files={
                    box.userCurtCatgId !== 0
                      ? serverFiles[box.userCurtCatgId] || [] // serverFiles에서 가져오거나 빈 배열
                      : uploadedFiles[box.tempId] || [] // uploadedFiles에서 가져오거나 빈 배열
                  }
                  onFilesChange={(newFiles) => {
                    setUploadedFiles((prevState) => ({
                      ...prevState,
                      [box.userCurtCatgId]: newFiles, // 수정된 부분: 업로드된 파일 리스트만 업데이트
                    }));
                  }}
                  currentTList={currentTList ? currentTList : null}
                  year={selectedYear}
                  month={selectedMonth}
                  company={selectedCompany}
                  inputRefs={inputRefs}
                  focusData={focusData}
                  index={index}
                  onTabDataChange={handleTabDataChange}
                  onDelete={() => handleDeleteCalcuBox(selectedTab, index)}
                  userCurtCatgId={box.userCurtCatgId}
                />
              ))}
              {selectedTab && (
                <div
                  className={
                    calcuBoxCount[selectedTab] > 4
                      ? "calcuAdd"
                      : "calcuAddFloat"
                  }
                  onClick={addCalcuBox}>
                  +
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="CarbonBtmSubmit">
        <button onClick={handleSave}>저장</button>
      </div>
    </>
  );
};

export default CalculationForm;
