import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: baseURL,
});

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers) => {
    const token = getToken();
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    } else {
      headers.set(
        "authorization",
        "Bearer " +
          "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6IlZVUCIsInJvbGUiOiJST0xFX1VTRVIiLCJpYXQiOjE3MjA0MTQwMjUsImV4cCI6MTcyMDUwMDQyNX0.QugfPl7MeeB3-0U2Zx8-reyxZN4PfzNY1DWC0qea41w"
      );
    }
    headers.set("Cache-Control", "no-cache");
    return headers;
  },
});

const api = createApi({
  baseQuery,
  endpoints: () => ({}),
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { instance };
export default api;
