import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stepNavi: 1,
  selectedProcesses: {},
};

const stepSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    nextStep(state) {
      if (state.stepNavi < 11) {
        state.stepNavi += 1;
      }
    },
    prevStep(state) {
      if (state.stepNavi > 1) {
        state.stepNavi -= 1;
      }
    },
    selectProcess(state, action) {
      const { step, process } = action.payload;
      state.selectedProcesses[step] = process;
    },
    toggleProcess(state, action) {
      const { process } = action.payload;
      if (process === "none") {
        state.selectedProcesses[6] = [];
      } else {
        if (state.selectedProcesses[6]?.includes(process)) {
          state.selectedProcesses[6] = state.selectedProcesses[6].filter(p => p !== process);
        } else {
          state.selectedProcesses[6] = [...(state.selectedProcesses[6] || []), process];
        }
      }
    },
    resetSteps(state) {
      state.stepNavi = 1;
      state.selectedProcesses = {};
    },
  },
});

export const { nextStep, prevStep, selectProcess, toggleProcess, resetSteps } = stepSlice.actions;
export default stepSlice.reducer;
