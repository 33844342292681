import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import { authApi } from "./features/api/authApi";
import commonApi from "./features/api/commonApi";
import dashBoardApi from "./features/api/dashBoardApi";
import calculationFormApi from "./features/api/calculationFormApi";
import stepReducer from "./features/slice/stepSlice";
import historyBoardApi from "./features/api/historyBoardApi";
import reportBoardApi from "./features/api/reportBoardApi";
import reduceMboApi from "./features/api/reduceMboApi";

export const apiList = [
  authApi,
  commonApi,
  dashBoardApi,
  calculationFormApi,
  historyBoardApi,
  reportBoardApi,
  reduceMboApi,
];

const apiReducer = apiList.reduce(
  (prev, api) => ({ ...prev, [api.reducerPath]: api.reducer }),
  {}
);
const apiMiddleware = apiList.map((api) => api.middleware);

const rootReducer = combineReducers({
  // 여기에 다른 슬라이스 리듀서를 추가할 수 있습니다.
  ...apiReducer,
  step: stepReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [], // 여기에는 지속하고 싶은 리듀서의 이름을 추가합니다.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }).concat(...apiMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
