import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CalcuScope3Step3 = ({scope3SortedData}) => {
  const cssRoot = window.getComputedStyle(document.documentElement);

  //console.log("scope3SortedData >>", scope3SortedData);

  // 데이터를 배열로 변환한 뒤 value 값에 따라 내림차순으로 정렬
  // const sortedData = scope3CatgData
  //   ? Object.entries(scope3CatgData)
  //       .filter(([key]) => key !== "propId") // propId 필드 제외
  //       .sort(([, a], [, b]) => b - a)
  //   : [];

  const colors = [
    cssRoot.getPropertyValue("--scope1"),
    cssRoot.getPropertyValue("--scope2"),
    cssRoot.getPropertyValue("--scope3"),
    cssRoot.getPropertyValue("--scope4"),
    cssRoot.getPropertyValue("--scope5"),
  ];

  // series 데이터를 동적으로 생성
  const seriesData = scope3SortedData?.map(([key, value], index) => ({
    name: key || "",
    data: [value] || "",
    color: colors[index % colors.length] || "",
  })).reverse();


  // 동적으로 StepTitle 메시지 생성
  const dynamicMessage = `귀 사업장은 Scope3 <em>${scope3SortedData.map(
    ([key]) => key
  ).join(", ")}</em> 산정 대상입니다.`;

  const chart1option = {
    chart: {
      type: "bar",
      backgroundColor: "transparent",
      height: "100px",
      width: 750,
      margin: [0, 0, 0, 0],
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [""],
      lineWidth: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      max: 100,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      followPointer: true,
    },
    series: seriesData,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 0,
            color: "#fff",
          },
        },
      },
      series: {
        stacking: "normal",
      },
    },
  };

  return (
    <>
      <strong dangerouslySetInnerHTML={{ __html: dynamicMessage }} />
      <div className="Scope3Chart">
        <HighchartsReact highcharts={Highcharts} options={chart1option} />
      </div>
      {/* 디자인 가이드상 Legend는 Highchart에서 분리해주세요*/}
      <div className="Scope3ChartLegend">
        {scope3SortedData?.map(([key, value], index) => (
          <p key={index}>
            <em style={{ backgroundColor: colors[index % colors.length] }}></em>
            <span>{key}</span>
            <b>{value}%</b>
          </p>
        ))}
      </div>
    </>
  );
};

export default CalcuScope3Step3;
