import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../components/styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";
import {TitleCloseDialog} from "../components/styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { LoadingFull } from "../components/common/loading/LoadingFull";

function Calcu(){
    const [topYear, setTopYear] = useState('2024');

    const TopYearChange = (event) => {
        setTopYear(event.target.value);
    };

    const [topMonth, setTopMonth] = useState(5);

    const TopMonthChange = (event) => {
        setTopMonth(event.target.value);
    };

    const [topComp, setTopComp] = useState('all');

    const TopCompChange = (event) => {
        setTopComp(event.target.value);
    };

    const [testName, setTestName] = useState("2");
    const TestNameChange = (event) => {
        setTestName(event.target.value);
    }

    const [popOpen, setPopOpen] = useState(false);

    const pophandleClose = () => {
        setPopOpen(false);
    };
    const pophandleOpen = () => {
        setPopOpen(true);
    };

    const [popOpen2, setPopOpen2] = useState(false);

    const pophandleClose2 = () => {
        setPopOpen2(false);
    };
    const pophandleOpen2 = () => {
        setPopOpen2(true);
    };

    const [popOpen3, setPopOpen3] = useState(false);

    const pophandleClose3 = () => {
        setPopOpen3(false);
    };
    const pophandleOpen3 = () => {
        setPopOpen3(true);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <TitleCloseDialog
                open={popOpen}
                onClose={pophandleClose}
            >
                <DialogTitle>
                    배출구분 재설정
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <div className="confirmMsg">
                            배출구분 재설정 시 기입력한 내용과 증빙자료가 삭제될 수 있습니다. <br />진행하시겠습니까?
                        </div>
                        <div className="CarbonPopBtn">
                            <button className="cancle">취소</button>
                            <button>진행</button>
                        </div>
                    </div>

                </DialogContent>
            </TitleCloseDialog>
            <TitleCloseDialog
                open={popOpen2}
                onClose={pophandleClose2}
            >
                <DialogTitle>
                    증빙자료 업로드
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose2} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <div className="CarbonPopFileUpload">
                            <p className="topMsg">
                                PDF, JPEG, PNG, 엑셀(csv, xlsx)파일만 업로드 가능합니다.<br />
                                전송 버튼 클릭 시 배출량 검증인에게 증빙 자료가 전송되며 <b>‘배출량 이력 관리’</b> 페이지에 업데이트 됩니다.
                            </p>
                            <div className="fileUploadForm">
                                <dl>
                                    <dt>파일 첨부</dt>
                                    <dd>
                                        <p>파일을 마우스로 끌어 오세요</p>
                                        <em>or</em>
                                        <button>파일 첨부</button>
                                        <div className="warning">
                                            <span>파일 형식이 잘못되었습니다.</span>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                            <div className="uploadedList">
                                <dl>
                                    <dt>첨부파일 <b>NN</b>개</dt>
                                    <dd>
                                        <ul>
                                            <li>
                                                <p className="fileName">
                                                    [1월 시설배치도.png] <span>4.5MB</span>
                                                </p>
                                                <p className="fileBtn">
                                                    <button className="down"></button>
                                                    <button className="del"></button>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="fileName">
                                                    [1월 시설배치도.png] <span>4.5MB</span>
                                                </p>
                                                <p className="fileBtn">
                                                    <button className="down"></button>
                                                    <button className="del"></button>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="fileName">
                                                    [1월 시설배치도.png] <span>4.5MB</span>
                                                </p>
                                                <p className="fileBtn">
                                                    <button className="down"></button>
                                                    <button className="del"></button>
                                                </p>
                                            </li>
                                        </ul>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div className="CarbonPopBtn">
                            <button className="cancle">취소</button>
                            <button>진행</button>
                        </div>
                    </div>

                </DialogContent>
            </TitleCloseDialog>
            <TitleCloseDialog
                open={popOpen3}
                onClose={pophandleClose3}
            >
                <DialogTitle className="inSelectTitle">
                    미입력 데이터 관리
                    <FormControl fullWidth>
                        <Select
                            className="popTitleSelect"
                            displayEmpty
                            value={"1"}
                            onChange={TestNameChange}
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={"1"}>사업장명</MenuItem>
                            <MenuItem value={"2"}>사업장2</MenuItem>
                            <MenuItem value={"3"}>사업장4</MenuItem>
                        </Select>
                    </FormControl>
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose3} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopTable">
                        <table>
                            <colgroup>
                                <col width="15%"/>
                                <col width="20%"/>
                                <col width="30%"/>
                                <col/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>기준연월</th>
                                <th>미입력 항목</th>
                                <th>사업장 명</th>
                                <th>담당자 명</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>20-01</td>
                                <td>고정연소</td>
                                <td>울산 사업장</td>
                                <td>
                                    <div className="goWrap">
                                        <span>홍길동</span>
                                        <button className="go">데이터 채우러가기</button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>20-01</td>
                                <td>고정연소</td>
                                <td>울산 사업장</td>
                                <td>
                                    <div className="goWrap">
                                        <span>홍길동</span>
                                        <button className="go">데이터 채우러가기</button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>20-01</td>
                                <td>고정연소</td>
                                <td>울산 사업장</td>
                                <td>
                                    <div className="goWrap">
                                        <span>홍길동</span>
                                        <button className="go">데이터 채우러가기</button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                </DialogContent>
            </TitleCloseDialog>
            <div className="CarbonPageTitle">
                <strong>탄소배출량 산정</strong>
            </div>
            <div className="CarbonTopSelect">
                <div className="selArea">
                    <p className="label">산정기간</p>
                    <FormControl>
                        <Select
                            value={topYear}
                            onChange={TopYearChange}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'2024'}>2024년</MenuItem>
                            <MenuItem value={'2023'}>2023년</MenuItem>
                            <MenuItem value={'2022'}>2022년</MenuItem>
                            <MenuItem value={'2021'}>2021년</MenuItem>
                            <MenuItem value={'2020'}>2020년</MenuItem>
                            <MenuItem value={'2019'}>2019년</MenuItem>
                            <MenuItem value={'2018'}>2018년</MenuItem>
                            <MenuItem value={'2017'}>2017년</MenuItem>
                            <MenuItem value={'2016'}>2016년</MenuItem>
                            <MenuItem value={'2015'}>2015년</MenuItem>
                            <MenuItem value={'2014'}>2014년</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Select
                            value={topMonth}
                            onChange={TopMonthChange}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={1}>01월</MenuItem>
                            <MenuItem value={2}>02월</MenuItem>
                            <MenuItem value={3}>03월</MenuItem>
                            <MenuItem value={4}>04월</MenuItem>
                            <MenuItem value={5}>05월</MenuItem>
                            <MenuItem value={6}>06월</MenuItem>
                            <MenuItem value={7}>07월</MenuItem>
                            <MenuItem value={8}>08월</MenuItem>
                            <MenuItem value={9}>09월</MenuItem>
                            <MenuItem value={10}>10월</MenuItem>
                            <MenuItem value={11}>11월</MenuItem>
                            <MenuItem value={12}>12월</MenuItem>
                        </Select>
                    </FormControl>
                    <p className="label">사업장 선택</p>
                    <FormControl>
                        <Select
                            value={topComp}
                            onChange={TopCompChange}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'all'}>전체사업장</MenuItem>
                            <MenuItem value={'2023'}>사업장1</MenuItem>
                            <MenuItem value={'2022'}>사업장2</MenuItem>
                            <MenuItem value={'2021'}>사업장3</MenuItem>
                            <MenuItem value={'2020'}>사업장4</MenuItem>
                            <MenuItem value={'2019'}>사업장5</MenuItem>
                            <MenuItem value={'2018'}>사업장6</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="btnArea">
                    <button onClick={pophandleOpen3}>미입력 데이터 관리</button>
                    <button onClick={pophandleOpen}>배출구분 재설정</button>
                    <button>이전 내역 불러오기</button>

                </div>
            </div>
            <div className="CarbonBox">
                <div className="CarbonbasicTab">
                    <Tabs value={value} onChange={handleChange}>
                        <Tab value={0} label="고정연소" />
                        <Tab value={1} label="이동연소" />
                        <Tab value={2} label="공정배출" />
                        <Tab value={3} label="폐기물소각" />
                        <Tab value={4} label="전력 사용시설" />
                        <Tab value={5} label="열스팀 사용시설" />
                    </Tabs>
                </div>
                <div className="CarbonCalcuWrap">
                    <div className="calcuList">
                        <div className="calcuBox select">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <ul>
                                        <li>
                                        <label>담당자</label>

                                            <FormControl fullWidth>
                                                <Select
                                                    className="smallSelect"
                                                    displayEmpty
                                                    value={testName}
                                                    onChange={TestNameChange}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    IconComponent={() => (
                                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem value={"1"}>홍길동</MenuItem>
                                                    <MenuItem value={"2"}>홍길동1</MenuItem>
                                                    <MenuItem value={"3"}>홍길동2</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </li>
                                        <li>
                                            <label>증빙자료</label>
                                            <div>
                                                <p className="existFile exist"></p>
                                                <button className="upFile" onClick={pophandleOpen2}>
                                                    <span>파일 업로드</span>
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <label>최종수정</label>
                                            <div>
                                                홍길동(24.07.31 23:57)
                                            </div>
                                        </li>
                                        <li>
                                            <label>산정 포함 여부</label>
                                            <div>
                                                <div className="toggleBtn">
                                                    <input type="checkbox" checked/>
                                                    <p><em></em></p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="calcuBtm">
                                    <ul>
                                        <li>
                                            <label>배출시설(공정명)</label>
                                            <div>
                                                <input type="text" value="터빈"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>연료/원료</label>
                                            <FormControl fullWidth>
                                                <Select
                                                    className="smallSelect"
                                                    displayEmpty
                                                    value={testName}
                                                    onChange={TestNameChange}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    IconComponent={() => (
                                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                    )}
                                                    MenuProps={MenuProps}

                                                >
                                                    <MenuItem value={"1"}>연료</MenuItem>
                                                    <MenuItem value={"2"}>도시가스(LNG)</MenuItem>
                                                    <MenuItem value={"3"}>원료</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </li>
                                        <li>
                                            <label>단위</label>
                                            <FormControl fullWidth>
                                                <Select
                                                    className="smallSelect"
                                                    displayEmpty
                                                    value={testName}
                                                    onChange={TestNameChange}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    IconComponent={() => (
                                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                    )}
                                                    MenuProps={MenuProps}

                                                >
                                                    <MenuItem value={"1"}>연료</MenuItem>
                                                    <MenuItem value={"2"}>kg</MenuItem>
                                                    <MenuItem value={"3"}>원료</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </li>
                                        <li>
                                            <label>사용량(생산량)</label>
                                            <div>
                                                <input type="text" value="00,000"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>연간에너지(천연가스)투입량</label>
                                            <div>
                                                <input type="text" value="00,000"/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <ul>
                                        <li>
                                            <label>담당자</label>
                                            <FormControl>
                                                <Select
                                                    className="smallSelect"
                                                    displayEmpty
                                                    value={""}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    IconComponent={() => (
                                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                    )}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <span>미지정</span>
                                                        }
                                                        return selected;
                                                    }}
                                                >
                                                    <MenuItem value={"1"}>홍길동</MenuItem>
                                                    <MenuItem value={"2"}>홍길동1</MenuItem>
                                                    <MenuItem value={"3"}>홍길동2</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </li>
                                        <li>
                                            <label>증빙자료</label>
                                            <div>
                                                <p className="existFile"></p>
                                                <button className="upFile" onClick={pophandleOpen2}>
                                                    <span>파일 업로드</span>
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <label>최종수정</label>
                                            <div>
                                                홍길동(24.07.31 23:57)
                                            </div>
                                        </li>
                                        <li>
                                            <label>산정 포함 여부</label>
                                            <div>
                                                <div className="toggleBtn">
                                                    <input type="checkbox"/>
                                                    <p><em></em></p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="calcuBtm">
                                    <ul>
                                        <li>
                                            <label>배출시설(공정명)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>연료/원료</label>
                                            <FormControl fullWidth>
                                                <Select
                                                    className="smallSelect"
                                                    displayEmpty
                                                    value={""}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    IconComponent={() => (
                                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                    )}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <span>선택하세요</span>
                                                        }
                                                        return selected;
                                                    }}
                                                >
                                                    <MenuItem value={""}>-</MenuItem>
                                                    <MenuItem value={""}>-</MenuItem>
                                                    <MenuItem value={""}>-</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </li>
                                        <li>
                                            <label>단위</label>
                                            <FormControl fullWidth>
                                                <Select
                                                    className="smallSelect"
                                                    displayEmpty
                                                    value={""}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    IconComponent={() => (
                                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                    )}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <span>-</span>
                                                        }
                                                        return selected;
                                                    }}
                                                >
                                                    <MenuItem value={"-"}>-</MenuItem>
                                                    <MenuItem value={"-"}>-</MenuItem>
                                                    <MenuItem value={"-"}>-</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </li>
                                        <li>
                                            <label>사용량(생산량)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="calcuBox">
                            <div className="calcuCont">
                                <div className="calcuTop">
                                    <ul>
                                        <li>
                                            <label>담당자</label>
                                            <FormControl>
                                                <Select
                                                    className="smallSelect"
                                                    displayEmpty
                                                    value={""}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    IconComponent={() => (
                                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                    )}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <span>미지정</span>
                                                        }
                                                        return selected;
                                                    }}
                                                >
                                                    <MenuItem value={"1"}>홍길동</MenuItem>
                                                    <MenuItem value={"2"}>홍길동1</MenuItem>
                                                    <MenuItem value={"3"}>홍길동2</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </li>
                                        <li>
                                            <label>증빙자료</label>
                                            <div>
                                                <p className="existFile"></p>
                                                <button className="upFile" onClick={pophandleOpen2}>
                                                    <span>파일 업로드</span>
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <label>최종수정</label>
                                            <div>
                                                홍길동(24.07.31 23:57)
                                            </div>
                                        </li>
                                        <li>
                                            <label>산정 포함 여부</label>
                                            <div>
                                                <div className="toggleBtn">
                                                    <input type="checkbox"/>
                                                    <p><em></em></p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="calcuBtm">
                                    <ul>
                                        <li>
                                            <label>배출시설(공정명)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>연료/원료</label>
                                            <FormControl fullWidth>
                                                <Select
                                                    className="smallSelect"
                                                    displayEmpty
                                                    value={""}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    IconComponent={() => (
                                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                    )}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <span>선택하세요</span>
                                                        }
                                                        return selected;
                                                    }}
                                                >
                                                    <MenuItem value={""}>-</MenuItem>
                                                    <MenuItem value={""}>-</MenuItem>
                                                    <MenuItem value={""}>-</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </li>
                                        <li>
                                            <label>단위</label>
                                            <FormControl fullWidth>
                                                <Select
                                                    className="smallSelect"
                                                    displayEmpty
                                                    value={""}
                                                    inputProps={{'aria-label': 'Without label'}}
                                                    IconComponent={() => (
                                                        <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                    )}
                                                    MenuProps={MenuProps}
                                                    fullWidth
                                                    renderValue={(selected) => {
                                                        if (selected.length === 0) {
                                                            return <span>-</span>
                                                        }
                                                        return selected;
                                                    }}
                                                >
                                                    <MenuItem value={"-"}>-</MenuItem>
                                                    <MenuItem value={"-"}>-</MenuItem>
                                                    <MenuItem value={"-"}>-</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </li>
                                        <li>
                                            <label>사용량(생산량)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>사용량(생산량)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>사용량(생산량)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>사용량(생산량)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>사용량(생산량)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>사용량(생산량)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                        <li>
                                            <label>사용량(생산량)</label>
                                            <div>
                                                <input type="text" value="" placeholder="입력해주세요"/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {[...Array(5)].map((value, index) => {
                            return (
                                <div className="calcuBox">
                                    <div className="calcuCont">
                                        <div className="calcuTop">
                                            <ul>
                                                <li>
                                                    <label>담당자</label>
                                                    <FormControl>
                                                        <Select
                                                            className="smallSelect"
                                                            displayEmpty
                                                            value={""}
                                                            inputProps={{'aria-label': 'Without label'}}
                                                            IconComponent={() => (
                                                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                            )}
                                                            MenuProps={MenuProps}
                                                            fullWidth
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return <span>미지정</span>
                                                                }
                                                                return selected;
                                                            }}
                                                        >
                                                            <MenuItem value={"1"}>홍길동</MenuItem>
                                                            <MenuItem value={"2"}>홍길동1</MenuItem>
                                                            <MenuItem value={"3"}>홍길동2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </li>
                                                <li>
                                                    <label>증빙자료</label>
                                                    <div>
                                                        <p className="existFile"></p>
                                                        <button className="upFile" onClick={pophandleOpen2}>
                                                            <span>파일 업로드</span>
                                                        </button>
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>최종수정</label>
                                                    <div>
                                                        -
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>산정 포함 여부</label>
                                                    <div>
                                                        <div className="toggleBtn">
                                                            <input type="checkbox"/>
                                                            <p><em></em></p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="calcuBtm">
                                            <ul>
                                                <li>
                                                    <label>배출시설(공정명)</label>
                                                    <div>
                                                        <input type="text" value="" placeholder="입력해주세요"/>
                                                    </div>
                                                </li>
                                                <li>
                                                    <label>연료/원료</label>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="smallSelect"
                                                            displayEmpty
                                                            value={""}
                                                            inputProps={{'aria-label': 'Without label'}}
                                                            IconComponent={() => (
                                                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                            )}
                                                            MenuProps={MenuProps}
                                                            fullWidth
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return <span>선택하세요</span>
                                                                }
                                                                return selected;
                                                            }}
                                                        >
                                                            <MenuItem value={""}>-</MenuItem>
                                                            <MenuItem value={""}>-</MenuItem>
                                                            <MenuItem value={""}>-</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </li>
                                                <li>
                                                    <label>단위</label>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            className="smallSelect"
                                                            displayEmpty
                                                            value={""}
                                                            inputProps={{'aria-label': 'Without label'}}
                                                            IconComponent={() => (
                                                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666"}}/>
                                                            )}
                                                            MenuProps={MenuProps}
                                                            fullWidth
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return <span>-</span>
                                                                }
                                                                return selected;
                                                            }}
                                                        >
                                                            <MenuItem value={"-"}>-</MenuItem>
                                                            <MenuItem value={"-"}>-</MenuItem>
                                                            <MenuItem value={"-"}>-</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </li>
                                                <li>
                                                    <label>사용량(생산량)</label>
                                                    <div>
                                                        <input type="text" value="" placeholder="입력해주세요"/>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="calcuAdd">+</div>
                </div>

            </div>
            <div className="CarbonBtmSubmit">
                <button className="dark">임시저장</button>
                <button>저장</button>
            </div>

        </>
    );
}

export default Calcu;