import React from "react";
import {Link} from "react-router-dom";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuProps from "../components/styleModule/MenuProps";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {TitleCloseDialog} from "../components/styleModule/ModifyStyle";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

/* ICON */
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import CloseIcon from '@mui/icons-material/Close';

function Dash() {
    const [topYear, setTopYear] = React.useState('2024');

    const TopYearChange = (event) => {
        setTopYear(event.target.value);
    };

    const [topComp, setTopComp] = React.useState('all');

    const TopCompChange = (event) => {
        setTopComp(event.target.value);
    };

    const cssRoot = window.getComputedStyle(document.documentElement);

    const chart1option= {
        chart: {
            type: 'column',
            backgroundColor:'transparent',
            height: '120px',
            width: 200,
            spacingTop: 0,
        },
        title: {
            text: null
        },
        xAxis: {
            categories: ['Scope1', 'Scope2'],
            labels : {
                enabled: true,
                style :  {
                    color : "#fff",
                    fontSize: "12px",
                    fontWeight : 500,
                },
                y: -15,
            },
        },
        yAxis: {
            title: {
                text: null,
            },
            gridLineWidth: 0,
            labels : {
                enabled: false
            },
            max: 100,
        },
        legend: {
            enabled: false
        },
        series: [{
            name: "Scope1",
            data: [{
                name: "Scope1",
                y: 40,
                color: cssRoot.getPropertyValue('--scope1'),
            }, {
                name: "Scope2",
                y: 60,
                color: cssRoot.getPropertyValue('--scope2'),
            }],
        }],
        exporting: {
            buttons: {
                contextButton: {
                    enabled: false
                }
            }
        },
        plotOptions:{
            column:{
                borderRadius:4,
                borderColor: 'transparent',
            },
            series : {
                groupPadding: 0,
                pointPadding: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function() {return this.y+"%"},
                    style: {
                        textOutline: 'transparent'
                    }
                },
            }
        },
        tooltip : {
            formatter : function() {
                return  "<b>"+this.y +"</b> tCO2e (60%)";
            },
            backgroundColor: cssRoot.getPropertyValue('--gray700'),
            borderRadius: 4,
            padding : 12,
            style: {
                color: '#fff'
            }
        }

    }
    const chart2option= {
        chart: {
            type: 'column',
            style: {
                fontFamily: 'Noto Sans KR',
            },
            spacingTop: 40,
        },
        exporting: {
            buttons: {
                contextButton: {
                    enabled: false
                }
            }
        },
        title: {
            text: null,
        },
        xAxis: {
            categories: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
            labels : {
                style : {
                    color : cssRoot.getPropertyValue('--gray500'),
                }
            },
            lineColor : cssRoot.getPropertyValue('--line20'),
        },
        yAxis: {
            min: 0,
            max: 20000,
            title: {
                text: null,
            },
            tickInterval : 5000,
            labels : {
                format: '{value:,.0f}',
                style : {
                    color : cssRoot.getPropertyValue('--gray500'),
                }
            },
            gridLineDashStyle : "longdash"
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            reversed : true,
            shadow: false,
            y:-45
        },
        plotOptions: {
            column: {
                borderRadius: 4,
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
            },

        },
        series: [
            {
                name: 'Scope2',
                color: cssRoot.getPropertyValue('--scope2'),
                data: [7000, 3000, 7000, 3000,6500,6500,5000,7000,7000,4000,1500,6000],
                borderWidth: 0,
            },
            {
                name: 'Scope1',
                color: cssRoot.getPropertyValue('--scope1'),
                data: [5000, 12000, 1500, 1500,6000,2000,1500,5000,1500,4000,1500,6000],
                borderWidth: 0,
            },
        ],
        tooltip : {
            formatter : function() {
                return "<b>"+this.y +"</b> tCO2e";
            },
            backgroundColor: cssRoot.getPropertyValue('--gray700'),
            borderRadius: 4,
            padding : 12,
            style: {
                color: '#fff'
            }
        }
    }
    const chart3option = {
        chart: {
            backgroundColor:'transparent',
            type: 'pie',
            height: '180px',

        },
        title: {
            text: null,
        },
        tooltip: {
            enabled: false
        },
        legend: {
            align: 'left',
            verticalAlign: 'middle',
            labelFormat: '<span style="color:#666">{name}</span>  <b>{y:,.0f}%</b>',
            layout: 'vertical',
            itemStyle : {"color": "#000", "fontSize": "12px", "textOverflow": "ellipsis"},
            itemMarginBottom: 15,
        },
        plotOptions: {
            pie: {
                showInLegend: true,
                size: 150,
            },
            series: {
                borderWidth: 0,
                borderRadius: 0,
                colorByPoint: true,
                type: 'pie',
                size: '100%',
                innerSize: '45%',
                dataLabels: {
                    enabled: true,
                    formatter: function() {return this.y},
                    crop: false,
                    distance: '-30%',
                    style: {
                        color: '#fff',
                        textOutline: 'transparent',
                        fontSize: "12px"
                    },
                    connectorWidth: 0
                }
            }
        },
        series: [{
            type: 'pie',
            data: [
                {
                    y: 24,
                    name: "배출시설명",
                    color: cssRoot.getPropertyValue('--scope1'),
                },
                {
                    y: 8,
                    name: "배출시설명",
                    color: cssRoot.getPropertyValue('--scope3'),
                },
                {
                    y: 68,
                    name: "배출시설명",
                    color: cssRoot.getPropertyValue('--scope2'),
                }
            ],
        }],
        exporting: {
            buttons: {
                contextButton: {
                    enabled: false
                }
            }
        },
    }

    const [popOpen, setPopOpen] = React.useState(false);

    const pophandleClose = () => {
        setPopOpen(false);
    };
    const pophandleOpen = () => {
        setPopOpen(true);
    };


    return (
        <>
            <TitleCloseDialog
                open={popOpen}
                onClose={pophandleClose}
            >
                <DialogTitle>
                    Scope 3 배출량 산정 문의하기
                </DialogTitle>
                <IconButton aria-label="close" onClick={pophandleClose} className="dialogClose">
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <div className="CarbonPopCont">
                        <p className="typoTy1">
                            Scope 3 배출량 산정 신청을 접수하시면 등록하신 연락 정보를<br/>
                            통해서 3일 내로 귀 사업장 담당자께 연락을 드리겠습니다.
                        </p>
                        <div className="inForm">
                            <input type="text" placeholder="이메일"/>
                            <input type="text" placeholder="전화번호"/>
                        </div>
                        <div className="CarbonPopBtn">
                            <button className="cancle">취소</button>
                            <button>저장</button>
                        </div>
                        <div className="CarbonPopBtmText">
                            사전문의 : contact@thingspire.com
                        </div>
                    </div>

                </DialogContent>
            </TitleCloseDialog>
            <div className="CarbonPageTitle">
                <div className="inSelect">
                    <strong>울산알루미늄</strong>
                    <FormControl>
                        <Select
                            value={topYear}
                            onChange={TopYearChange}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'2024'}>2024년</MenuItem>
                            <MenuItem value={'2023'}>2023년</MenuItem>
                            <MenuItem value={'2022'}>2022년</MenuItem>
                            <MenuItem value={'2021'}>2021년</MenuItem>
                            <MenuItem value={'2020'}>2020년</MenuItem>
                            <MenuItem value={'2019'}>2019년</MenuItem>
                            <MenuItem value={'2018'}>2018년</MenuItem>
                            <MenuItem value={'2017'}>2017년</MenuItem>
                            <MenuItem value={'2016'}>2016년</MenuItem>
                            <MenuItem value={'2015'}>2015년</MenuItem>
                            <MenuItem value={'2014'}>2014년</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Select
                            value={topComp}
                            onChange={TopCompChange}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'all'}>전체사업장</MenuItem>
                            <MenuItem value={'2023'}>사업장1</MenuItem>
                            <MenuItem value={'2022'}>사업장2</MenuItem>
                            <MenuItem value={'2021'}>사업장3</MenuItem>
                            <MenuItem value={'2020'}>사업장4</MenuItem>
                            <MenuItem value={'2019'}>사업장5</MenuItem>
                            <MenuItem value={'2018'}>사업장6</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="func">
                    <button className="ty1Button" onClick={pophandleOpen}>Scope 3 배출량 산정 문의하기</button>
                </div>
            </div>
            <div className="DashGrid">
                <div className="DashGridItem">
                    <p className="DashItemTitle">
                        <b>올해 전체 배출량</b>
                    </p>
                    <div className="totalCnt">
                        <strong>12,332,877,000<i className="carbonUnit">tCO2e</i></strong>
                        <em>전년도 대비 XX% 감소</em>
                    </div>
                </div>
                <div className="DashGridItem">
                    <p className="DashItemTitle">
                        <b>올해 목표관리</b>
                    </p>
                    <ul className="totalPurpose">
                        <lI><span>목표배출량</span><strong>5,000,000<i className="carbonUnit">tCO2e</i></strong></lI>
                        <lI><span>달성율</span><em>목표 대비 50% 배출</em></lI>
                    </ul>
                </div>
                <div className="DashGridItem">
                    <p className="DashItemTitle">
                        <b>Scope 별 배출량</b>
                    </p>
                    <div id="Dash1">
                        <HighchartsReact highcharts={Highcharts} options={chart1option} />
                    </div>
                </div>
                <div className="DashGridItem span3">
                    <p className="DashItemTitle">
                        <b>
                            <Link to="#" className="on">월별 배출량</Link>
                            <em>/</em>
                            <Link to="#">연도별 배출량</Link>
                        </b>
                        <span>(단위 : tCO2e)</span>
                    </p>
                    <HighchartsReact highcharts={Highcharts} options={chart2option} />
                </div>
                <div className="DashGridItem">
                    <p className="DashItemTitle">
                        <b>데이터 수집 현황</b>
                        <Link to="#">자세히 보기</Link>
                    </p>
                    <table>
                        <colgroup>
                            <col width="30%"/>
                            <col width="30%"/>
                            <col />
                        </colgroup>
                        <thead>
                        <tr>
                            <th>사업장명</th>
                            <th>미수집율(%)</th>
                            <th>미입력 데이터</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>사업장명1</td>
                            <td className="yellow">60</td>
                            <td>200 / 250개</td>
                        </tr>
                        <tr>
                            <td>사업장명2</td>
                            <td className="red">90</td>
                            <td>200 / 250개</td>
                        </tr>
                        <tr>
                            <td>사업장명3</td>
                            <td className="red">85</td>
                            <td>200 / 250개</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="DashGridItem">
                    <p className="DashItemTitle">
                        <b>사업자별 배출량</b>
                        <Link to="#">자세히 보기</Link>
                    </p>
                    <table>
                        <colgroup>
                            <col width="35%"/>
                            <col width="30%"/>
                            <col/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>사업자명</th>
                            <th>비중(%)</th>
                            <th>총 배출량 (tCO2e)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>사업장명1</td>
                            <td>NN</td>
                            <td>290,000</td>
                        </tr>
                        <tr>
                            <td>사업장명2</td>
                            <td>NN</td>
                            <td>290,000</td>
                        </tr>
                        <tr>
                            <td>사업장명3</td>
                            <td>NN</td>
                            <td>290,000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="DashGridItem">
                    <p className="DashItemTitle">
                        <b>올해 재생에너지 포트폴리오</b>
                    </p>
                    <HighchartsReact highcharts={Highcharts} options={chart3option} />
                </div>
            </div>
        </>
    );
}

export default Dash;