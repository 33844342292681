import api from "./api";
import { tscmsReportBoardEndpoints } from "./endpoints/tscmsReportBoard";

export const reportBoardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ...tscmsReportBoardEndpoints(builder),
  }),
  overrideExisting: false,
});

export const { 
  useFetchReportBoardQuery, 
  useFetchReportModalQuery } =
  reportBoardApi;

export default reportBoardApi;
