import React, { useCallback, useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../../../components/styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { formatNumber, yearOptionList } from "../common/commonData";
import { tableHeader } from "./../HistoryBoard/historyBoard.helper";
import { useFetchCnHistoryQuery } from "../../../modules/features/api/historyBoardApi";
import { useLoading } from "./../../util/LoadingContext";
import { useLocation } from "react-router-dom";

/**
 * @description 이력관리 페이지
 * @author seoeunPark
 * @date 19/06/2024
 * @param {*}
 * @return
 */
const HistoryBoard = () => {
  const location = useLocation();
  const { showLoading, hideLoading } = useLoading();
  const [selected, setSelected] = useState({
    year: location.state?.year || "2024",
    tab: "eng",
  });
  const [yearListOptions, setYearOptionList] = useState([]);

  const tabList = [
    { label: "에너지 실적 관리", value: "eng" },
    { label: "배출량 현황", value: "ems" },
  ];

  const {
    data: energyPerformanceData,
    error: energyPerformanceError,
    isLoading: isEnergyPerformanceLoading,
    refetch: refetchEnergyPerformanceQuery,
  } = useFetchCnHistoryQuery({ year: selected.year, type: selected.tab });

  const refetchAllData = useCallback(() => {
    refetchEnergyPerformanceQuery({ year: selected.year, type: selected.tab });
  }, [refetchEnergyPerformanceQuery]);

  useEffect(() => {
    setYearOptionList(yearOptionList);
  }, []);

  useEffect(() => {
    //console.log("selected >>>", selected);

    refetchAllData();
  }, [selected]);

  useEffect(() => {
    //console.log("energyPerformanceData >>", energyPerformanceData);
  }, [energyPerformanceData]);

  const groupByCompanyAndFacility = (data, inlo, fac) => {
    const groupedData = {};
    data?.forEach((item) => {
      if (!groupedData[item[inlo]]) {
        groupedData[item[inlo]] = {};
      }
      if (!groupedData[item[inlo]][item[fac]]) {
        groupedData[item[inlo]][item[fac]] = [];
      }
      groupedData[item[inlo]][item[fac]].push(item);
    });
    return groupedData;
  };

  const groupedData = groupByCompanyAndFacility(
    energyPerformanceData,
    "inloName",
    "facility"
  );

  const generateCSV = () => {
    let csvContent = "\uFEFF"; // UTF-8 BOM 추가

    // CSV 헤더 추가
    if (selected.tab === "eng") {
      csvContent +=
        "사업장," +
        "배출시설," +
        "배출유형," +
        "단위," +
        Array.from({ length: 12 }, (_, i) => `${i + 1} 월`).join(",") +
        ",합계\n";
    } else if (selected.tab === "ems") {
      csvContent +=
        "사업장," +
        "배출시설," +
        "배출유형," +
        Array.from({ length: 12 }, (_, i) => `${i + 1} 월`).join(",") +
        ",합계\n";
    }

    // CSV 데이터 추가
    Object.keys(groupedData).forEach((company) => {
      const companyRows = groupedData[company];
      Object.keys(companyRows).forEach((facility) => {
        const facilityRows = companyRows[facility];
        facilityRows.forEach((row) => {
          const monthValues = row.month.map((monthData) => monthData.value);
          const monthData = [...Array(12)].map((_, index) => {
            const monthData = row.month.find(
              (monthData) => monthData.month === index + 1
            );
            if (monthData) {
              return monthData.value;
            } else {
              return 0;
            }
          });

          const total = monthValues.reduce((sum, value) => sum + value, 0);
          let rowData = [];

          switch (selected.tab) {
            case "eng":
              rowData = [
                row.inloName,
                row.facility,
                row.fuel,
                row.unit,
                ...monthData,
                total,
              ];
              break;
            case "ems":
              rowData = [
                row.inloName,
                row.facility,
                row.fuel,
                ...monthData,
                total,
              ];
              break;
            default:
              return;
          }

          const csvRow = rowData
            .map((value) => {
              // 콤마나 큰따옴표가 포함된 데이터는 큰따옴표로 감싼다.
              if (
                typeof value === "string" &&
                (value.includes(",") || value.includes('"'))
              ) {
                return `"${value.replace(/"/g, '""')}"`;
              }
              return value;
            })
            .join(",");

          csvContent += csvRow + "\n";
        });
      });
    });

    // Blob 생성
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    return URL.createObjectURL(blob);
  };

  const downloadCSV = async () => {
    const csvContent = generateCSV();
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute(
      "download",
      `이력관리_${tabList.find((tab) => tab.value === selected.tab).label}_${
        selected.year
      }.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clickDownloadCSV = async () => {
    showLoading();
    await downloadCSV();
    hideLoading();
  };

  return (
    <>
      <div className="CarbonPageTitle">
        <strong>이력관리</strong>
      </div>
      <div className="CarbonTopSelect">
        <div className="selArea">
          <p className="label">관리년도</p>
          <FormControl>
            <Select
              value={selected.year || ""}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon
                  sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                />
              )}
              MenuProps={MenuProps}
              onChange={(event) => {
                setSelected({ ...selected, year: event.target.value });
              }}
            >
              {/* 2016-2024 년도 까지 리스트  */}
              {yearListOptions.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="btnArea">
          <button onClick={clickDownloadCSV}>csv 다운로드</button>
        </div>
      </div>

      <div className="CarbonBox">
        <div className="CarbonbasicTab">
          <Tabs
            value={selected.tab}
            onChange={(event, value) => {
              setSelected({ ...selected, tab: value });
            }}
          >
            {tabList.map((tab, index) => (
              <Tab key={index} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
          {selected.tab === "ems" && (
            <span className="unit">(단위 : tCO2e)</span>
          )}
        </div>
        <TableContainer className="CarbonHistoryTable" sx={{ maxHeight: 710 }}>
          <Table stickyHeader>
            <colgroup>
              <col width="7%" />
              <col width="7%" />
              <col />
              {selected.tab === "eng" && <col width="4%" />}
              <col width="5.5%" span={13} />
            </colgroup>
            <TableHead>
              <TableRow>
                {tableHeader(selected.tab) &&
                  tableHeader(selected.tab).map((item, index) => (
                    <TableCell
                      key={index}
                      field={item.field}
                      align={item.align}
                    >
                      {item.title}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(groupedData).map((company) => {
                const companyRows = groupedData[company];
                const companyRowSpan = Object.keys(companyRows).reduce(
                  (sum, key) => sum + companyRows[key].length,
                  0
                );
                return Object.keys(companyRows).map(
                  (facility, facilityIndex) => {
                    const facilityRows = companyRows[facility];
                    const facilityRowSpan = facilityRows.length;
                    return facilityRows.map((row, rowIndex) => (
                      <TableRow key={row.id}>
                        {facilityIndex === 0 && rowIndex === 0 && (
                          <TableCell rowSpan={companyRowSpan} component="th">
                            {company}
                          </TableCell>
                        )}
                        {rowIndex === 0 && (
                          <TableCell rowSpan={facilityRowSpan}>
                            {facility}
                          </TableCell>
                        )}
                        <TableCell>
                          {/* <Tooltip title={row.fuel}> */}
                          {row.fuel}
                          {/* </Tooltip> */}
                        </TableCell>
                        {selected.tab === "eng" && (
                          <TableCell>{row.unit}</TableCell>
                        )}
                        {[...Array(12)].map((_, index) => {
                          const monthData = row.month.find(
                            (monthData) => monthData.month === index + 1
                          );

                          if (monthData) {
                            return (
                              <TableCell key={monthData.month} align="right">
                                {formatNumber(monthData.value, 2)}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={index + 1} align="center">
                                {/* 값이 없는 경우 빈 셀 또는 기본 값을 넣습니다. */}
                              </TableCell>
                            );
                          }
                        })}
                        {/* 총합계 */}
                        <TableCell align="right">
                          {formatNumber(
                            row.month.reduce((acc, cur) => acc + cur.value, 0),
                            2
                          )}
                        </TableCell>
                      </TableRow>
                    ));
                  }
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default HistoryBoard;
