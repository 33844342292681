import React, { useState, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../../styleModule/MenuProps";
import { useFetchScope3SectorQuery } from "../../../modules/features/api/calculationFormApi";

const CalcuScope3Step2 = ({handleNextNavi}) => {
  const [selValue1, setSelValue1] = useState('');
  const [selValue2, setSelValue2] = useState('');
  const [selValue3, setSelValue3] = useState('');
  const [activityGroups, setActivityGroups] = useState([]);
  const [activities, setActivities] = useState([]);
  const [cdpQuestionnaire, setCdpQuestionnaire] = useState('');

  const {
    data: Scope3SectorData,
  } = useFetchScope3SectorQuery();

  const selValue1Change = (event) => {
    const selectedIndustry = event.target.value;
    setSelValue1(selectedIndustry);

    // 해당 industry의 activityGroups를 찾음
    const selectedSector = Scope3SectorData?.data.find(
      (sector) => sector.industry === selectedIndustry
    );

    // activityGroups를 설정
    if (selectedSector) {
      setActivityGroups(selectedSector.activityGroups);
      setSelValue2(''); // 대분류 초기화
      setActivities([]); // 소분류 초기화
      setSelValue3('');
      setCdpQuestionnaire(''); // cdpQuestionnaire 초기화
    } else {
      setActivityGroups([]);
      setActivities([]);
      setCdpQuestionnaire('');
    }
  };

  const selValue2Change = (event) => {
    const selectedActivityGroup = event.target.value;
    setSelValue2(selectedActivityGroup);

    // 해당 activityGroup의 activities를 찾음
    const selectedGroup = activityGroups.find(
      (group) => group.activityGroup === selectedActivityGroup
    );

    // activities를 설정
    if (selectedGroup) {
      setActivities(selectedGroup.activities);
      setSelValue3(''); // 소분류 초기화
      setCdpQuestionnaire('');
    } else {
      setActivities([]);
      setCdpQuestionnaire('');
    }
  };

  const selValue3Change = (event) => {
    const selectedActivity = event.target.value;
    setSelValue3(selectedActivity);

    // 선택된 소분류에 해당하는 cdpQuestionnaire를 찾음
    const selectedActivityData = activities.find(
      (activity) => activity.cdpActivity === selectedActivity
    );

    // cdpQuestionnaire를 설정
    if (selectedActivityData) {
      //console.log("selectedActivityData >>>", selectedActivityData.cdpQuestionnaire);
      setCdpQuestionnaire(selectedActivityData.cdpQuestionnaire);
    } else {
      setCdpQuestionnaire('');
    }
  };


  const StepTitle = [
    "",
    "Scope 3 배출량을 산정하시겠습니까?",
    "귀 사업장에 해당하는 항목을 선택해주세요.",
    "귀 사업장은 Scope3 <em>Category 1,10,11</em> 산정 대상입니다.",
  ];

  return (
    <>
      <div className="CarbonStepContent">
        <strong dangerouslySetInnerHTML={{ __html: StepTitle[2] }} />
        <div className="selectWrap">
          <div>
            <label>산업 선택</label>
            <FormControl>
              <Select
                value={selValue1}
                onChange={selValue1Change}
                displayEmpty
                className="basicSelect"
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={() => (
                  <ExpandMoreSharpIcon
                    sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                  />
                )}
                MenuProps={MenuProps}
              >
                <MenuItem value={""}>선택해주세요</MenuItem>
                {Scope3SectorData?.data && Scope3SectorData?.data.map((sector, index) => (
                  <MenuItem key={sector.industry} value={sector.industry}>
                    {sector.industry}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <label>활동 대분류 선택</label>
            <FormControl>
              <Select
                value={selValue2}
                onChange={selValue2Change}
                displayEmpty
                className="basicSelect"
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={() => (
                  <ExpandMoreSharpIcon
                    sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                  />
                )}
                MenuProps={MenuProps}>
                <MenuItem value={""}>선택해주세요</MenuItem>
                {activityGroups.map((group, index) => (
                <MenuItem key={index} value={group.activityGroup}>
                  {group.activityGroup}
                </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <label>활동 소분류 선택</label>
            <FormControl>
              <Select
                value={selValue3}
                onChange={selValue3Change}
                displayEmpty
                className="basicSelect"
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={() => (
                  <ExpandMoreSharpIcon
                    sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                  />
                )}
                MenuProps={MenuProps}>
                <MenuItem value={""}>선택해주세요</MenuItem>
                {activities.map((activity, index) => (
                <MenuItem key={index} value={activity.cdpActivity}>
                  {activity.cdpActivity}
                </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="stepBtn">
          <button
            onClick={() => {
              if (cdpQuestionnaire) {
                handleNextNavi(cdpQuestionnaire);
              } else {
                alert("데이터를 모두 선택해주세요");
              }
            }}
          >
            다음
          </button>
        </div>
      </div>
    </>
  );
};

export default CalcuScope3Step2;