import React, { useEffect, useState } from "react";
import { TitleCloseDialog } from "../../styleModule/ModifyStyle";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 * @description 탄소저감 목표관리 > 목표관리 이행 현황 > 배출량 감축 시나리오 선택 > 개선활동명 선택 팝업
 * @author seoeunPark
 * @date 17/06/2024
 * @param {*}
 * @return
 */

const EmssionReductionScenarioPopup2 = ({
  open,
  onClose,
  data,
  setSelected,
}) => {
  const [thisData, setThisData] = useState([]);
  const [tempSelected, setTempSelected] = useState({});

  useEffect(() => {
    if (data !== thisData) {
      setThisData(data);
    }
  }, [data]);

  const saveThis = () => {
    setSelected((prev) => ({
      ...prev,
      activityName: tempSelected.value,
      activityNamelabel: tempSelected.label,
    }));
    onClose();
  };

  return (
    <>
      <TitleCloseDialog open={open} onClose={onClose}>
        <DialogTitle>개선활동명 선택</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogClose"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="CarbonPopCont">
            <div className="logTextSelect">
              <ul>
                {thisData &&
                  thisData.map((item, index) => (
                    <li key={index}>
                      <input
                        checked={tempSelected.value === item.rdcActNo}
                        value={tempSelected.value}
                        type="radio"
                        onClick={() =>
                          setTempSelected({
                            value: item.rdcActNo,
                            label: item.rdcActName,
                          })
                        }
                      />
                      <p>{item.rdcActName}</p>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="CarbonPopBtn">
              <button onClick={() => saveThis()}>
                배출량 감축 시나리오에 적용하기
              </button>
            </div>
          </div>
        </DialogContent>
      </TitleCloseDialog>
    </>
  );
};

export default EmssionReductionScenarioPopup2;
