import React, {useState} from "react";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../components/styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

function HistoryList(){
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <>
            <div className="CarbonPageTitle">
                <strong>이력관리</strong>
            </div>
            <div className="CarbonTopSelect">
                <div className="selArea">
                    <p className="label">관리년도</p>
                    <FormControl>
                        <Select
                            value={"2024"}
                            displayEmpty
                            className="basicSelect"
                            inputProps={{'aria-label': 'Without label'}}
                            IconComponent={() => (
                                <ExpandMoreSharpIcon sx={{mr: 1, color: "#666", fontSize: "32px"}}/>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value={'2024'}>2024년</MenuItem>
                            <MenuItem value={'2023'}>2023년</MenuItem>
                            <MenuItem value={'2022'}>2022년</MenuItem>
                            <MenuItem value={'2021'}>2021년</MenuItem>
                            <MenuItem value={'2020'}>2020년</MenuItem>
                            <MenuItem value={'2019'}>2019년</MenuItem>
                            <MenuItem value={'2018'}>2018년</MenuItem>
                            <MenuItem value={'2017'}>2017년</MenuItem>
                            <MenuItem value={'2016'}>2016년</MenuItem>
                            <MenuItem value={'2015'}>2015년</MenuItem>
                            <MenuItem value={'2014'}>2014년</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="btnArea">
                    <button>csv 다운로드</button>
                </div>
            </div>
            <div className="CarbonBox">
                <div className="CarbonbasicTab">
                    <Tabs value={value} onChange={handleChange}>
                        <Tab value={0} label="에너지 실적 관리"/>
                        <Tab value={1} label="배출량 현황"/>
                    </Tabs>
                    <span className="unit">(단위 : tCO2e)</span>
                </div>
                <TableContainer className="CarbonHistoryTable" sx={{maxHeight : 710}}>
                    <Table stickyHeader>
                        <colgroup>
                            <col width="8%"/>
                            <col width="8%"/>
                            <col/>
                            <col width="8%"/>
                            <col width="5%" span={13} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>사업장</TableCell>
                                <TableCell>배출시설</TableCell>
                                <TableCell>연료/원료</TableCell>
                                <TableCell>단위</TableCell>
                                {[...Array(12)].map((value, index) => {
                                    return (
                                        <TableCell align="center">{index + 1}월</TableCell>
                                    );
                                })}
                                <TableCell align="center">합계</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">선일공장</TableCell>
                                <TableCell>터빈</TableCell>
                                <TableCell>Marine Diesel / Gas Oil (ISO 8217 Grades DMX, DMA, DMZ and DMB maximum 0.10 % S); MDO/MGO(ULSFO)</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>효율이 높고 용량제어 기능이 갖추어진 종합효율이 75%이상인 Blower도입 유지보수비가 적고 인버터제어로</TableCell>
                                <TableCell>휘발유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>가스/더블 오일</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">기계전기연구소</TableCell>
                                <TableCell>카바이드공정</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>전기차 충전기</TableCell>
                                <TableCell>전기</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>난방</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">일렉 울산공장</TableCell>
                                <TableCell>비상발전기</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">선일공장</TableCell>
                                <TableCell>터빈</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>승용자동차</TableCell>
                                <TableCell>휘발유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>가스/더블 오일</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">기계전기연구소</TableCell>
                                <TableCell>카바이드공정</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>전기차 충전기</TableCell>
                                <TableCell>전기</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>난방</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>일렉 울산공장</TableCell>
                                <TableCell>비상발전기</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">선일공장</TableCell>
                                <TableCell>터빈</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>승용자동차</TableCell>
                                <TableCell>휘발유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>가스/더블 오일</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">기계전기연구소</TableCell>
                                <TableCell>카바이드공정</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>전기차 충전기</TableCell>
                                <TableCell>전기</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>난방</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">일렉 울산공장</TableCell>
                                <TableCell>비상발전기</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">선일공장</TableCell>
                                <TableCell>터빈</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>승용자동차</TableCell>
                                <TableCell>휘발유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>가스/더블 오일</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">기계전기연구소</TableCell>
                                <TableCell>카바이드공정</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>전기차 충전기</TableCell>
                                <TableCell>전기</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>난방</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">일렉 울산공장</TableCell>
                                <TableCell>비상발전기</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">선일공장</TableCell>
                                <TableCell>터빈</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>승용자동차</TableCell>
                                <TableCell>휘발유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>가스/더블 오일</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">기계전기연구소</TableCell>
                                <TableCell>카바이드공정</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>전기차 충전기</TableCell>
                                <TableCell>전기</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>난방</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>일렉 울산공장</TableCell>
                                <TableCell>비상발전기</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">선일공장</TableCell>
                                <TableCell>터빈</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>승용자동차</TableCell>
                                <TableCell>휘발유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>가스/더블 오일</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">기계전기연구소</TableCell>
                                <TableCell>카바이드공정</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>전기차 충전기</TableCell>
                                <TableCell>전기</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>난방</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">일렉 울산공장</TableCell>
                                <TableCell>비상발전기</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">선일공장</TableCell>
                                <TableCell>터빈</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>승용자동차</TableCell>
                                <TableCell>휘발유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>가스/더블 오일</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">기계전기연구소</TableCell>
                                <TableCell>카바이드공정</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>전기차 충전기</TableCell>
                                <TableCell>전기</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>난방</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">일렉 울산공장</TableCell>
                                <TableCell>비상발전기</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">선일공장</TableCell>
                                <TableCell>터빈</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>승용자동차</TableCell>
                                <TableCell>휘발유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>가스/더블 오일</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">기계전기연구소</TableCell>
                                <TableCell>카바이드공정</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>전기차 충전기</TableCell>
                                <TableCell>전기</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>난방</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>일렉 울산공장</TableCell>
                                <TableCell>비상발전기</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">선일공장</TableCell>
                                <TableCell>터빈</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={2}>승용자동차</TableCell>
                                <TableCell>휘발유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>가스/더블 오일</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={3} component="th">기계전기연구소</TableCell>
                                <TableCell>카바이드공정</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>L</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>전기차 충전기</TableCell>
                                <TableCell>전기</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell>난방</TableCell>
                                <TableCell>도시가스(LNG)</TableCell>
                                <TableCell>kWh</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">일렉 울산공장</TableCell>
                                <TableCell>비상발전기</TableCell>
                                <TableCell>경유</TableCell>
                                <TableCell>Nm3</TableCell>
                                {[...Array(13)].map((value, index) => {
                                    return (
                                        <TableCell align="center">10</TableCell>
                                    );
                                })}
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

export default HistoryList;