import React from "react";

import Header from "./Header";
import LNB from "./LNB";
import Footer from "./Footer";
import GlobalLoadingComponent from "./GlobalLoadingComponent";

function Layout({ children }) {
  return (
    <>
      <GlobalLoadingComponent />
      <Header />
      <div id="TSCMSWrap">
        <LNB />
        <div className="ThingsCarbon">
          <div className="ThingsCarbonContent">{children}</div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
