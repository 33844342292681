import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep, prevStep, selectProcess, toggleProcess, resetSteps } from "../../../modules/features/slice/stepSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../../styleModule/MenuProps";

const CalcuScope3Step1 = ({ handleSubmit, handleNextNavi }) => {

  const StepTitle = [
    "",
    "Scope 3 배출량을 산정하시겠습니까?",
    "귀 사업장에 해당하는 항목을 선택해주세요.",
    "귀 사업장은 Scope3 <em>Category 1,10,11</em> 산정 대상입니다.",
  ];


  return (
    <>
      <strong dangerouslySetInnerHTML={{ __html: StepTitle[1] }} />
      <div className="stepBtn">
        <button onClick={handleSubmit}>아니요</button>
        <button onClick={() => handleNextNavi()}>예</button>
      </div>
    </>
  )

}


export default CalcuScope3Step1;