export const tableHeader = (tab) => {
  let defaultColumn = {};

  switch (tab) {
    case "eng":
      defaultColumn = [
        { title: "배출유형", field: "fuel" },
        { title: "단위", field: "unit" },
      ];
      break;
    case "ems":
      defaultColumn = [{ title: "배출유형", field: "scope" }];
      break;
    default:
      return;
  }

  let header = [
    {
      title: "사업장",
      field: "company",
    },
    {
      title: "배출시설",
      field: "facility",
    },
    ...defaultColumn,
    ...[...Array(12)].map((value, index) => {
      return {
        title: `${index + 1}월`,
        field: `${index + 1}`,
        align: "right",
      };
    }),
    {
      title: "합계",
      field: "total",
      align: "right",
    },
  ];

  return header;
};

export const tableData = [
  {
    id: 1,
    company: "선일공장",
    facility: "터빈",
    fuel: "Marine Diesel, LNG Marine DieselMarine Diesel, LNG Marine Diesel",
    unit: "Nm3",
    month: [
      { month: 1, value: 1120 },
      { month: 2, value: 10 },
      { month: 3, value: 10 },
      { month: 4, value: 10 },
      { month: 5, value: 10 },
      { month: 6, value: 10 },
      { month: 7, value: 10 },
      { month: 8, value: 10 },
      { month: 9, value: 10 },
      { month: 10, value: 10 },
      { month: 11, value: 10 },
      { month: 12, value: 10 },
    ],
  },
  {
    id: 2,
    company: "선일공장",
    facility: "인버터제어",
    fuel: "경유",
    unit: "Nm3",
    month: [
      { month: 1, value: 1 },
      { month: 2, value: 10 },
      { month: 3, value: 10 },
      { month: 4, value: 10 },
      { month: 5, value: 10 },
      { month: 6, value: 10 },
      { month: 7, value: 10 },
      { month: 8, value: 10 },
      { month: 9, value: 10 },
      { month: 10, value: 10 },
      { month: 11, value: 10 },
      { month: 12, value: 10 },
    ],
  },
  {
    id: 3,
    company: "기계전기연구소",
    facility: "카바이드공정",
    fuel: "경유",
    unit: "L",
    month: [
      { month: 1, value: null },
      { month: 2, value: 10 },
      { month: 3, value: 10 },
      { month: 4, value: 10 },
      { month: 5, value: 10 },
      { month: 6, value: 10 },
      { month: 7, value: 1 },
      { month: 8, value: 10 },
      { month: 9, value: 10 },
      { month: 10, value: 10 },
      { month: 11, value: 10 },
      { month: 12, value: 10 },
    ],
  },
  {
    id: 4,
    company: "기계전기연구소",
    facility: "전기차 충전기",
    fuel: "전기",
    unit: "Nm3",
    month: [
      { month: 1, value: 10 },
      { month: 2, value: 10 },
      { month: 3, value: 10 },
      { month: 4, value: 10 },
      { month: 5, value: 10 },
      { month: 6, value: 10 },
      { month: 7, value: 10 },
      { month: 8, value: 10 },
      { month: 9, value: 10 },
      { month: 10, value: 10 },
      { month: 11, value: 10 },
      { month: 12, value: 10 },
    ],
  },
  {
    id: 5,
    company: "기계전기연구소",
    facility: "난방",
    fuel: "도시가스(LNG)",
    unit: "Nm3",
    month: [
      { month: 1, value: 10 },
      { month: 2, value: 10 },
      { month: 3, value: 10 },
      { month: 4, value: 10 },
      { month: 5, value: 10 },
      { month: 6, value: 10 },
      { month: 7, value: 10 },
      { month: 8, value: 10 },
      { month: 9, value: 10 },
      { month: 10, value: 10 },
      { month: 11, value: 10 },
      { month: 12, value: 10 },
    ],
  },
  {
    id: 6,
    company: "일렉 울산공장",
    facility: "비상발전기",
    fuel: "경유",
    unit: "Nm3",
    month: [
      { month: 1, value: 10 },
      { month: 2, value: 10 },
      { month: 3, value: 10 },
      { month: 4, value: 10 },
      { month: 5, value: 10 },
      { month: 6, value: 10 },
      { month: 7, value: 10 },
      { month: 8, value: 10 },
      { month: 9, value: 10 },
      { month: 10, value: 10 },
      { month: 11, value: 10 },
      { month: 12, value: 10 },
    ],
  },
  {
    id: 7,
    company: "선일공장",
    facility: "터빈",
    fuel: "도시가스(LNG)",
    unit: "Nm3",
    month: [
      { month: 1, value: 10 },
      { month: 2, value: 10 },
      { month: 3, value: 10 },
      { month: 4, value: 10 },
      { month: 5, value: 10 },
      { month: 6, value: 10 },
      { month: 7, value: 10 },
      { month: 8, value: 10 },
      { month: 9, value: 10 },
      { month: 10, value: 10 },
      { month: 11, value: 10 },
      { month: 12, value: 10 },
    ],
  },
  {
    id: 8,
    company: "선일공장",
    facility: "승용자동차",
    fuel: "휘발유",
    unit: "L",
    month: [
      { month: 1, value: 10 },
      { month: 2, value: 10 },
      { month: 3, value: 10 },
      { month: 4, value: 10 },
      { month: 5, value: 10 },
      { month: 6, value: 10 },
      { month: 7, value: 10 },
      { month: 8, value: 10 },
      { month: 9, value: 10 },
      { month: 10, value: 10 },
      { month: 11, value: 10 },
      { month: 12, value: 10 },
    ],
  },
  {
    id: 9,
    company: "선일공장",
    facility: "승용자동차",
    fuel: "가스/더블 오일",
    unit: "kWh",
    month: [
      { month: 1, value: 10 },
      { month: 2, value: 10 },
      { month: 3, value: 10 },
      { month: 4, value: 10 },
      { month: 5, value: 10 },
      { month: 6, value: 10 },
      { month: 7, value: 10 },
      { month: 8, value: 10 },
      { month: 9, value: 10 },
      { month: 10, value: 10 },
      { month: 11, value: 10 },
      { month: 12, value: 10 },
    ],
  },
];
