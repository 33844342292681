import api from "./api";
import { tscmsCalculationEndPoints } from './endpoints/tscmsCalculationForm';

export const calculationFormApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ...tscmsCalculationEndPoints(builder),
  }),
  overrideExisting: false,
});

export const {
  useFetchFormSaveMutation,
  useFetchTemplateQuery,
  useFetchFactoryListQuery,
  useFetchManagerQuery,
  useFetchFuelOptionsQuery,
  useLazyFetchUnitOptionsQuery,
  useLazyFetchTabsAndFormDataQuery,
  useFetchInitEmiCalQuery,
  useFetchCalYearsQuery,
  useFetchCalCompaniesQuery,
  useFetchCategoryQuery,
  useInsertStepValueMutation,
  useFetchScope3SectorQuery,
} = calculationFormApi;

export default calculationFormApi;