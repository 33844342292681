import api from "./api";
import { tscmsHistoryBoardEndpoints } from "./endpoints/tscmsHistoryBoard";

export const historyBoardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ...tscmsHistoryBoardEndpoints(builder),
  }),
  overrideExisting: false,
});

export const { useFetchCnHistoryQuery } = historyBoardApi;

export default historyBoardApi;
