export const menuList = [
  {
    category: "",
    itemList: [
      {
        item: "홈",
        desc: "",
        address: "/dash",
      },
      {
        item: "탄소배출량 산정",
        desc: "",
        address: "/step",
        address2: "/calculationForm",
      },
      {
        item: "탄소저감 목표관리",
        desc: "",
        address: "/purpose",
      },
      {
        item: "보고서",
        desc: "",
        address: "/report",
      },
      {
        item: "이력관리",
        desc: "",
        address: "/history",
      },
    ],
  },
];
