import React, { useState, useEffect } from "react";
import { TitleCloseDialog } from "../../styleModule/ModifyStyle";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import {
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import MenuProps from "../../styleModule/MenuProps";
import EmssionReductionScenarioPopup2 from "./EmssionReductionScenarioPopup2";
import { useFetchCommonCodeQuery } from "../../../modules/features/api/commonApi";
import GlobalUtils from "./../../util/GlobalUtils";
import { useFetchRdtActionOptionsQuery } from "../../../modules/features/api/reduceMboApi";

/**
 * @description 탄소저감 목표관리 > 목표관리 이행 현황 > 배출량 감축 시나리오 선택 팝업
 * @author seoeunPark
 * @date 17/06/2024
 * @param {*}
 * @return
 */

const EmissionReductionScenarioPopup = ({
  open,
  onClose,
  open2,
  onClose2,
  handleOpen2,
  scenarioList,
  setScenarioList,
  baseYear,
  applyYears,
}) => {
  const [selected, setSelected] = useState({
    industry: "", // 업종
    facility: "", // 감축 대상 설비
    activity: "", // 개선활동 구분
    activityName: "", // 개선 활동명
    activityNamelabel: "", // 개선 활동명
    applyYear: "", // 개선 활동 적용 연도
  });
  const [selectedResult, setSelectedResult] = useState({});

  const [result, setResult] = useState({
    emissionReduction: "", //예상 온실가스 감축량
    emissionReductionUnit: "", //예상 온실가스 감축량 단위
    investment: "", //투자비
    investmentUnit: "", //투자비 단위
    annualSavings: "", //연감 절감액
    annualSavingsUnit: "", //연감 절감액 단위
    investmentRecoveryPeriod: "", //투자비 회수기간
    investmentRecoveryPeriodUnit: "", //투자비 회수기간 단위
  });

  //업종
  const [industryOptions, setIndustryOptions] = useState([]);
  const {
    data: fetchIndustryOptions = [],
    isLoading: industryOptionsIsLoading,
  } = useFetchCommonCodeQuery({ cdClass: "SECTOR_CL_CD" });

  useEffect(() => {
    if (
      fetchIndustryOptions.length > 0 &&
      fetchIndustryOptions !== industryOptions
    ) {
      setIndustryOptions(fetchIndustryOptions);
    }
  }, [fetchIndustryOptions]);

  //감축 대상 설비
  const [facilityOptions, setFacilityOptions] = useState([]);
  const {
    data: fetchFacilityOptions = [],
    isLoading: facilityOptionsIsLoading,
    refetch: refetchFacilityOptions,
  } = useFetchRdtActionOptionsQuery(
    { step: 1, sectorClCd: selected.industry },
    {
      skip: selected.industry === "",
    }
  );

  useEffect(() => {
    if (
      fetchFacilityOptions.length > 0 &&
      fetchFacilityOptions !== facilityOptions
    ) {
      setFacilityOptions(fetchFacilityOptions);
    }
  }, [fetchFacilityOptions]);

  //개선활동 구분
  const [activityOptions, setActivityOptions] = useState([]);
  const {
    data: fetchActivityOptions = [],
    isLoading: activityOptionsIsLoading,
    refetch: refetchActivityOptions,
  } = useFetchRdtActionOptionsQuery(
    {
      step: 2,
      sectorClCd: selected.industry,
      rdcFacClCd: selected.facility,
    },
    {
      skip: selected.facility === "",
    }
  );

  useEffect(() => {
    if (
      fetchActivityOptions.length > 0 &&
      fetchActivityOptions !== activityOptions
    ) {
      setActivityOptions(fetchActivityOptions);
    }
  }, [fetchActivityOptions]);

  //개선 활동명
  const [activityNameOptions, setActivityNameOptions] = useState([]);
  const {
    data: fetchActivityNameOptions = [],
    isLoading: activityNameOptionsIsLoading,
    refetch: refetchActivityNameOptions,
  } = useFetchRdtActionOptionsQuery(
    {
      step: 3,
      sectorClCd: selected.industry,
      rdcFacClCd: selected.facility,
      rdcClCd: selected.activity,
    },
    {
      skip: selected.activity === "",
    }
  );

  useEffect(() => {
    if (
      fetchActivityNameOptions.length > 0 &&
      fetchActivityNameOptions !== activityNameOptions
    ) {
      setActivityNameOptions(fetchActivityNameOptions);
    }
  }, [fetchActivityNameOptions]);

  //개선 활동 적용 연도
  const [activityYearOptions, setActivityYearOptions] = useState([]);
  const {
    data: fetchActivityYearOptions = [],
    isLoading: activityYearOptionsIsLoading,
    refetch: refetchActivityYearOptions,
  } = useFetchRdtActionOptionsQuery(
    {
      step: 4,
      sectorClCd: selected.industry,
      rdcFacClCd: selected.facility,
      rdcClCd: selected.activity,
      rdcActNo: selected.activityName,
    },
    {
      skip: selected.activity === "",
    }
  );

  useEffect(() => {
    if (
      fetchActivityYearOptions.length > 0 &&
      fetchActivityYearOptions !== activityYearOptions
    ) {
      setActivityYearOptions(fetchActivityYearOptions);
    }
  }, [fetchActivityYearOptions]);

  useEffect(() => {
    //console.log("selected >>", selected);
  }, [selected]);

  //selected.industry 값이 바뀌면  facility,activity,activityName,applyYear 초기화
  useEffect(() => {
    if (selected.industry === "") {
      setFacilityOptions([]);
    }
    setSelected((prev) => ({
      ...prev,
      facility: "",
      activity: "",
      activityName: "",
      activityNamelabel: "",
      applyYear: "",
    }));
  }, [selected.industry]);

  useEffect(() => {
    if (selected.facility === "") {
      setActivityOptions([]);
    }
    setSelected((prev) => ({
      ...prev,
      activity: "",
      activityName: "",
      activityNamelabel: "",
      applyYear: "",
    }));
  }, [selected.facility]);

  useEffect(() => {
    if (selected.activity === "") {
      setActivityNameOptions([]);
    }
    setSelected((prev) => ({
      ...prev,
      activityName: "",
      activityNamelabel: "",
      applyYear: "",
    }));
  }, [selected.activity]);

  useEffect(() => {
    if (selected.activityName === "") {
      setActivityYearOptions([]);
    }
    setSelected((prev) => ({
      ...prev,
      applyYear: "",
    }));
  }, [selected.activityName]);

  useEffect(() => {
    //console.log(" selected.activityName >>", selected.activityName);

    const findItem = activityYearOptions.find(
      (item) => item.rdcActNo === selected.activityName
    );
    //console.log("findItem >>", findItem);

    setSelectedResult(findItem);
  }, [selected.activityName]);

  useEffect(() => {
    setResult({
      emissionReduction: selectedResult?.ghgRdcVol,
      emissionReductionUnit: selectedResult?.ghgRdcVolUnit,
      investment: selectedResult?.invstCost,
      investmentUnit: selectedResult?.invstCostUnit,
      annualSavings: selectedResult?.rdcCost,
      annualSavingsUnit: selectedResult?.rdcCostUnit,
      investmentRecoveryPeriod: selectedResult?.invstCostRcvpd,
      investmentRecoveryPeriodUnit: selectedResult?.invstCostRcvpdUnit,
    });
  }, [selectedResult]);

  const handleSaveBtn = () => {
    // 데이터 확인
    if (selected.industry === "") {
      alert("업종을 선택해주세요.");
      return;
    }
    if (selected.facility === "") {
      alert("감축 대상 설비를 선택해주세요.");
      return;
    }
    if (selected.activity === "") {
      alert("개선활동 구분을 선택해주세요.");
      return;
    }
    if (selected.activityName === "") {
      alert("개선 활동명을 선택해주세요.");
      return;
    }
    if (selected.applyYear === "") {
      alert("개선 활동 적용 연도를 선택해주세요.");
      return;
    }

    if (!window.confirm("적용 하시겠습니까?")) {
      return;
    }

    // 저장 성공 시
    const saveData = {
      tempId: scenarioList.length + 1,
      tarImpDtlId: null,
      title: `${baseYear}-${
        industryOptions.find((item) => item.cdCode === selected.industry)
          ?.cdName
      }-${
        facilityOptions.find((item) => item.rdcFacClCd === selected.facility)
          ?.cdName
      }-${
        activityOptions.find((item) => item.rdcClCd === selected.activity)
          ?.cdName
      }-${
        activityNameOptions.find(
          (item) => item.rdcActNo === selected.activityName
        )?.rdcActName
      }-${selected.applyYear}`,
      checked: true,
      focus: false,
      detail: {
        emissionReduction: result?.emissionReduction, // 예상 온실가스 감축량
        emissionReductionUnit: result?.emissionReductionUnit, // 예상 온실가스 감축량 단위
        investment: result?.investment, // 투자비
        investmentUnit: result?.investmentUnit, // 투자비 단위
        annualSavings: result?.annualSavings, // 연감 절감액
        annualSavingsUnit: result?.annualSavingsUnit, // 연감 절감액 단위
        investmentRecoveryPeriod: result?.investmentRecoveryPeriod, // 투자비 회수기간
        investmentRecoveryPeriodUnit: result?.investmentRecoveryPeriodUnit, // 투자비 회수기간 단위
        applyYear: selected.applyYear, // 개선 활동 적용 연도
        rdcActNo: selected.activityName, // 개선 활돌명 코드
      },
    };
    //console.log("저장한 데이터 ??saveData >>", saveData);

    setScenarioList((prev) => [...prev, saveData]);

    // alert("저장되었습니다.");
    initSelected();
  };

  /**
   * @description 선택 데이터 초기화
   */
  const initSelected = () => {
    setSelected({
      industry: "",
      facility: "",
      activity: "",
      activityName: "",
      activityNamelabel: "",
      activityYear: "",
    });
  };

  const handleOpenSelect = () => {
    if (selected.activity === "") {
      alert("개선활동 구분을 선택해주세요.");
      return;
    }
    handleOpen2();
  };

  const handleClose = () => {
    initSelected();
    onClose();
  };

  return (
    <>
      <TitleCloseDialog open={open} onClose={onClose}>
        <DialogTitle>배출량 감축 시나리오 선택</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="dialogClose"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="CarbonPopCont">
            <div className="popScenario">
              <div className="ScenarioSelect">
                <ul>
                  <li>
                    <label>업종</label>
                    <FormControl>
                      <Select
                        className="popSelect"
                        displayEmpty
                        value={selected.industry || ""}
                        onChange={(e) =>
                          setSelected({ ...selected, industry: e.target.value })
                        }
                        inputProps={{ "aria-label": "Without label" }}
                        IconComponent={() => (
                          <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                        )}
                        MenuProps={MenuProps}
                      >
                        {/* <MenuItem value={"1"}>폐기물</MenuItem> */}
                        {industryOptions &&
                          GlobalUtils.makeOptions(
                            "cdName",
                            "cdCode",
                            industryOptions
                          ).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </li>
                  <li>
                    <label>감축 대상 설비</label>
                    <FormControl>
                      <Select
                        className="popSelect"
                        displayEmpty
                        value={selected.facility || ""}
                        onChange={(e) => {
                          //console.log("선택된 감축 대상 설비:", e.target.value);
                          setSelected({
                            ...selected,
                            facility: e.target.value,
                          });
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        IconComponent={() => (
                          <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                        )}
                        MenuProps={MenuProps}
                        onClick={() => {
                          if (selected.industry === "") {
                            alert("업종을 선택해주세요.");
                            return;
                          }
                        }}
                      >
                        {facilityOptions &&
                          GlobalUtils.makeOptions(
                            "cdName",
                            "rdcFacClCd",
                            facilityOptions
                          ).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </li>
                  <li>
                    <label>개선활동 구분</label>
                    <FormControl>
                      <Select
                        className="popSelect"
                        displayEmpty
                        value={selected.activity || ""}
                        onChange={(e) =>
                          setSelected({ ...selected, activity: e.target.value })
                        }
                        inputProps={{ "aria-label": "Without label" }}
                        IconComponent={() => (
                          <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                        )}
                        MenuProps={MenuProps}
                        onClick={() => {
                          if (selected.facility === "") {
                            alert("감축 대상 설비를 선택해주세요.");
                            return;
                          }
                        }}
                      >
                        {activityOptions &&
                          GlobalUtils.makeOptions(
                            "cdName",
                            "rdcClCd",
                            activityOptions
                          ).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </li>
                  <li>
                    <label>개선 활동명</label>
                    <FormControl>
                      <TextField
                        className="popSelect"
                        placeholder={
                          selected.activity !== "" ? "클릭하세요" : "선택하세요"
                        }
                        value={selected.activityNamelabel}
                        onClick={handleOpenSelect}
                      />
                    </FormControl>
                  </li>
                  <li>
                    <label>개선 활동 적용 연도</label>
                    <FormControl>
                      <Select
                        className="popSelect"
                        displayEmpty
                        value={selected.applyYear}
                        onChange={(e) =>
                          setSelected({
                            ...selected,
                            applyYear: e.target.value,
                          })
                        }
                        inputProps={{ "aria-label": "Without label" }}
                        IconComponent={() => (
                          <ExpandMoreSharpIcon sx={{ mr: 1, color: "#666" }} />
                        )}
                        MenuProps={MenuProps}
                        onClick={() => {
                          if (selected.activityName === "") {
                            alert("개선 활동명을 선택해주세요.");
                            return;
                          }
                        }}
                      >
                        {selected.activityName !== "" ? (
                          applyYears.map((year, index) => (
                            <MenuItem key={index} value={year}>
                              {year}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem key={0} value={""}>
                            선택하세요
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </li>
                </ul>
              </div>
              <div className="ScenarioResult">
                <ul>
                  <li>
                    <label>예상 온실가스 감축량</label>
                    <b>
                      {result.emissionReduction} {result.emissionReductionUnit}
                    </b>
                  </li>
                  <li>
                    <label>투자비</label>
                    <b>
                      {result.investment} {result.investmentUnit}
                    </b>
                  </li>
                  <li>
                    <label>연감 절감액</label>
                    <b>
                      {result.annualSavings} {result.annualSavingsUnit}
                    </b>
                  </li>
                  <li>
                    <label>투자비 회수기간</label>
                    <b>
                      {result.investmentRecoveryPeriod}{" "}
                      {result.investmentRecoveryPeriodUnit}
                    </b>
                  </li>
                </ul>
              </div>
            </div>
            <div className="CarbonPopBtn">
              <button onClick={handleSaveBtn}>
                목표관리 이행 현황에 적용하기
              </button>
            </div>
          </div>
        </DialogContent>
      </TitleCloseDialog>

      <EmssionReductionScenarioPopup2
        open={open2}
        onClose={onClose2}
        data={activityNameOptions}
        setSelected={setSelected}
      />
    </>
  );
};

export default EmissionReductionScenarioPopup;
