import api from "./api";
import { tscmsDashBoardEndpoints } from "./endpoints/tscmsDashBoard";

export const dashBoardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ...tscmsDashBoardEndpoints(builder),
  }),
  overrideExisting: false,
});

export const {
  useFetchHomeYearsQuery,
  useFetchHomeCompaniesQuery,
  useFetchTotalEmissionQuery,
  useFetchGoalManagementQuery,
  useFetchEmissionByScopeQuery,
  useFetchEmissionMonthStatisticsQuery,
  useFetchEmissionYearStatisticsQuery,
  useFetchDataCollectionStatusQuery,
  useFetchEmissionByBusinessQuery,
  useFetchRecentHistoryQuery,
  useFetchHighestEmissionQuery,
  useFetchHighestFactoryQuery,
  useSubmitContactEmailMutation,
} = dashBoardApi;

export default dashBoardApi;
