import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authEndpoints } from "./endpoints/auth";
const BASE_URL = "/api/auth";

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    ...authEndpoints(builder),
  }),
});

export const {} = authApi;
