import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

/**
 * @description DashBoard 컨테이너 → 온실가스 최다 배출 사업장
 * @author Choi Seon ha
 * @date 10/06/2024
 * @param {*} builder
 * @return {JSX.Element}
 */

const HighestEmissionCompany = ({ highestEmissionData }) => {
  const [chartData, setChartData] = useState([]);

  //console.log("highestEmissionData >> ", highestEmissionData);

  const cssRoot = window.getComputedStyle(document.documentElement);
  const colors = [
    cssRoot.getPropertyValue("--scope2"),
    cssRoot.getPropertyValue("--scope1"),
    cssRoot.getPropertyValue("--scope3"),
  ];

  useEffect(() => {
    if (Array.isArray(highestEmissionData)) {

      const sortedData = [...highestEmissionData].sort((a, b) => b.emission - a.emission);

      const formattedData = sortedData.map((item, index) => ({
        y: item.percentage,
        name: item.inloName,
        color: colors[index % colors.length],
      }));

      setChartData(formattedData);
    }
  }, [highestEmissionData])



  const chart3option = {
    chart: {
      backgroundColor: "transparent",
      type: "pie",
      height: "180px",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      enabled: false,
    },
    accessibility: {
      enabled: false
    },
    legend: {
      align: "left",
      verticalAlign: "middle",
      labelFormatter: function () {
        // this.y 의 값을 소수점 이하 최대 4자리까지 표시하고 불필요한 0 제거
        return `<span style="color:#666">${this.name}</span>  <b>${parseFloat(this.y.toFixed(4)).toString()}%</b>`;
      },
      layout: "vertical",
      itemStyle: { color: "#000", fontSize: "12px", textOverflow: "ellipsis" },
      itemMarginBottom: 15,
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        size: 150,
      },
      series: {
        borderWidth: 0,
        borderRadius: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "45%",
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y + '%';
          },
          crop: false,
          distance: "-30%",
          style: {
            color: "#fff",
            textOutline: "transparent",
            fontSize: "12px",
          },
          connectorWidth: 0,
        },
      },
    },
    series: [
      {
        type: "pie",
        data: chartData,
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
  };

  return (
    <>
      <div className="DashGridItem">
        <p className="DashItemTitle">
          <b>온실가스 최다 배출 사업장</b>
        </p>
        <HighchartsReact highcharts={Highcharts} options={chart3option} />
      </div>
    </>
  );
};

export default HighestEmissionCompany;
