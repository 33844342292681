import React, { useEffect, useMemo, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import MenuProps from "../../../components/styleModule/MenuProps";
import MenuItem from "@mui/material/MenuItem";
import { getMboYearOptions } from "../../../components/CarbonNote/common/commonData";
import { calcOffsetByPurchasing } from "../CarbonReduceMBO/carbonReduceMBO.helper";
import GoalManagementStatus from "../CarbonReduceMBO/GoalManagementStatus";
import GoalManagementStatusGraph from "../CarbonReduceMBO/GoalManagementStatusGraph";
import {
  useFetchBaseEmissionsQuery,
  useFetchScenarioHistoryQuery,
} from "../../../modules/features/api/reduceMboApi";
import { useFetchCommonCodeQuery } from "../../../modules/features/api/commonApi";
import GlobalUtils from "../../util/GlobalUtils";
import {
  deleteManageScenario,
  getRdcActInfo,
  getRdtActionOptions,
} from "../../../modules/features/api/endpoints/tscmsReduceMbo";

/**
 * @description 탄소저감 목표관리 페이지
 * @author seoeunPark
 * @date 17/06/2024
 * @param {*}
 * @return
 */

const CarbonReduceMBO = () => {
  const [decreaseMethod, setDecreaseMethod] = useState("RProduct"); // 배출량, 매출 원단위, 생산량 원단위 선택
  const [baseYear, setBaseYear] = useState("");
  const [targetYear, setTargetYear] = useState("2030");
  const [viewComponentId, setViewComponentId] = useState(0); // 컴포넌트 변경을 위한 id
  const [scenarioList, setScenarioList] = useState([]);
  const [yearListOptions, setYearOptionList] = useState([]);
  const [textOb, setTextOb] = useState({
    text1: "",
    text2: "",
  });
  // 감축활동에 따른 예상 감축량 초기 나눌값
  const [applyNeedList, setApplyNeedList] = useState([]);

  //RProduct생산량 원단위 RSale매출량 원단위 Remi배출량
  const { data: methodOptions = [], isLoading: methodOptionsIsLoading } =
    useFetchCommonCodeQuery({ cdClass: "REDUCE_CL_CD" });

  const [fetchEnable, setFetchEnable] = useState(false);
  const {
    data: baseEmissions,
    isLoading: baseEmissionsIsLoading,
    refetch: refetchBaseEmissionsQuery,
  } = useFetchBaseEmissionsQuery({ year: baseYear }, { skip: !fetchEnable });

  const {
    data: existingScenarioList,
    error: existingScenarioListError,
    isLoading: existingScenarioListIsLoading,
    refetch: refetchExistingScenarioList,
  } = useFetchScenarioHistoryQuery(
    {
      reduceClCd: decreaseMethod,
      baseYyyy: baseYear,
    },
    { skip: baseYear === "" }
  );

  const methodOptionList = [
    {
      name: "배출량",
      value: "Remi",
      text1: "배출량",
      text2: "감소",
      text3: "배출량",
    },
    {
      name: "매출 원단위",
      value: "RSale",
      text1: "매출",
      text2: "성장",
      text3: "매출 금액",
    },
    {
      name: "생산량 원단위",
      value: "RProduct",
      text1: "판매 총 수량",
      text2: "성장",
      text3: "판매 총 수량",
    },
  ];

  //목표관리 이행 현황
  const [form, setForm] = useState({
    //감축방법론
    decreaseMethod: "production",
    //기준연도 2년전의 판매 총 수량
    beforeBaseYear2Value: "",
    //기준연도 1년전의 판매 총 수량
    beforeBaseYear1Value: "",
    //기준연도의 판매 총 수량
    baseYearValue: "",
    // 성장 예상 퍼센트
    growthRate: "",
  });

  // 목표관리 이행현황 그래프
  const [dataList, setDataList] = useState([
    {
      id: 1,
      name: "기준연도 배출량 집약도",
      data: [],
    },
    {
      id: 2,
      name: "과거 배출량",
      data: [],
    },
    {
      id: 3,
      name: "순배출량",
      data: [],
    },
    {
      id: 4,
      name: "감축활동에 따른 예상 감축량",
      data: [],
    },
    {
      id: 5,
      name: "배출권 구매로 인한 상세",
      data: [],
    },
    {
      id: 6,
      name: "1.5℃ 감축 시나리오",
      data: [],
    },
  ]);

  const [beforeDataList, setBeforeDataList] = useState([
    {
      id: 3,
      name: "순배출량",
      data: [],
    },
    {
      id: 4,
      name: "감축활동에 따른 예상 감축량",
      data: [],
    },
    {
      id: 5,
      name: "배출권 구매로 인한 상세",
      data: [],
    },
  ]);

  useEffect(() => {
    // 기준년도 리스트
    const years = getMboYearOptions();
    const newYearOptionList = [
      ...years,
      { value: "step", label: "과거 배출량 산정 추가 진행" },
    ];
    setYearOptionList(newYearOptionList);
    setBaseYear(years[0].value);
  }, []);

  useEffect(() => {
    if (baseYear === "step") {
      // Step url 이동
      window.location.href = "/step";
    }
  }, [baseYear]);

  useEffect(() => {
    setForm({
      beforeBaseYear2Value: "",
      beforeBaseYear1Value: "",
      baseYearValue: "",
      growthRate: "",
    });

    setScenarioList([]);

    // 기존 시나리오 리스트 조회
    existingScenarioList && refetchExistingScenarioList();
  }, [baseYear, decreaseMethod]);

  useEffect(() => {
    getMethodText();
    if (decreaseMethod === "Remi") {
      changeComponent(1);
    } else {
      changeComponent(0);
    }
  }, [decreaseMethod]);

  const fetchData = async () => {
    if (existingScenarioList) {
      //console.log("existingScenarioList >>", existingScenarioList);

      if (existingScenarioList.fcnRelTarImp !== null) {
        setForm((prev) => ({
          ...prev,
          beforeBaseYear2Value: existingScenarioList.fcnRelTarImp.bf2TotVol,
          beforeBaseYear1Value: existingScenarioList.fcnRelTarImp.bf1TotVol,
          baseYearValue: existingScenarioList.fcnRelTarImp.baseTotVol,
          growthRate: existingScenarioList.fcnRelTarImp.percent,
          tarImpId: existingScenarioList.fcnRelTarImp.tarImpId,
        }));
      }

      // 적용 시나리오 리스트
      if (existingScenarioList.fcnRelTarImpDtlList.length > 0) {
        const savedScenarioList = existingScenarioList.fcnRelTarImpDtlList;

        const list = [];
        for (const item of savedScenarioList) {
          const rdcActNo = item.rdcActNo;
          const applyYear = item.applyYyyy;
          const tempId = item.tarImpDtlId;
          const tarImpDtlId = item.tarImpDtlId;
          const applyYn = item.applyYn === "Y" ? true : false;

          try {
            const data = await getRdtActionOptions({
              step: "4",
              rdcActNo: rdcActNo,
            });

            //console.log("data :: ", data);

            if (data.length > 0) {
              const item = data[0];

              const detail = {
                emissionReduction: item.ghgRdcVol,
                emissionReductionUnit: item.ghgRdcVolUnit,
                investment: item.invstCost,
                investmentUnit: item.invstCostUnit,
                annualSavings: item.rdcCost,
                annualSavingsUnit: item.rdcCostUnit,
                investmentRecoveryPeriod: item.invstCostRcvpd,
                investmentRecoveryPeriodUnit: item.invstCostRcvpdUnit,
                applyYear: applyYear,
                rdcActNo: rdcActNo,
              };

              const rdcActInfo = await getRdcActInfo({ rdcActNo: rdcActNo });

              item.applyYear = applyYear;
              list.push({
                tempId: tempId,
                tarImpDtlId: tarImpDtlId,
                title:
                  baseYear +
                  "-" +
                  rdcActInfo[0].sectorClCdName +
                  "-" +
                  rdcActInfo[0].rdcFacClCdName +
                  "-" +
                  rdcActInfo[0].rdcClCdName +
                  "-" +
                  rdcActInfo[0].rdcActName +
                  "-" +
                  applyYear,
                checked: applyYn,
                focus: false,
                detail: detail,
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
        //console.log("list >>>", list);
        setScenarioList(list);
      }
      if (existingScenarioList.fcnRelTarImp !== null) {
        changeComponent(1);
      } else {
        if (decreaseMethod === "Remi") {
          changeComponent(1);
        } else {
          changeComponent(0);
        }
      }
    } else {
      if (decreaseMethod === "Remi") {
        changeComponent(1);
      } else {
        changeComponent(0);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [existingScenarioList]);

  useEffect(() => {
    applyScenario();
  }, [scenarioList, beforeDataList]);

  useEffect(() => {
    //console.log("applyNeedList >>", applyNeedList);
  }, [applyNeedList]);

  // 관련 감축지원 사업 확인하기 버튼 클릭 시 새창 열기
  const openNewPage = () => {
    window.open(
      "https://tips.energy.or.kr/business_support/backup_info.do?code_num=MO&code_sys=BS"
    );
  };

  /**
   * @description  시나리오 선택(해제)
   */
  const selectScenario = (id) => {
    const newScenarioList = scenarioList.map((item) => {
      if (item.tempId === id) {
        if (item.focus) {
          item.focus = false;
          item.checked = false;
        } else {
          item.focus = true;
          item.checked = true;
        }
      } else {
        item.focus = false;
        // item.checked = false;
      }
      return item;
    });
    setScenarioList(newScenarioList);
  };

  /**
   * @description  선택된 시나리오 삭제
   */
  const deleteScenario = (index) => {
    try {
      //  삭제 확인 팝업
      if (!window.confirm("해당 시나리오를 삭제하시겠습니까?")) {
        return;
      }
      // console.log("deleteScenario index >>", index);
      // console.log(
      //   "scenarioList[index].tarImpDtlId >>",
      //   scenarioList[index].tarImpDtlId
      // );

      if (
        scenarioList[index].tarImpDtlId !== undefined &&
        scenarioList[index].tarImpDtlId !== null
      ) {
        deleteManageScenario({ tarImpDtlId: scenarioList[index].tarImpDtlId });
      }

      // 해당 인덱스의 시나리오 삭제
      const newScenarioList = scenarioList.filter((item, i) => i !== index);
      setScenarioList(newScenarioList);
    } catch (error) {
      alert("시나리오 삭제 중 오류가 발생했습니다.");
    } finally {
      setTimeout(() => {
        refetchExistingScenarioList();
      }, 300);
    }
  };

  /**
   * @description 0:값 입력, 1:그래프
   */
  const changeComponent = (id) => {
    if (id === 1) {
      setFetchEnable(true);
    }
    setViewComponentId(id);
  };

  /**
   * @description 감축방법론에 따른 텍스트 변경
   */
  const getMethodText = () => {
    methodOptionList.map((item) => {
      if (item.value === decreaseMethod) {
        setTextOb({
          text1: item.text1,
          text2: item.text2,
          text3: item.text3,
        });
      }
      return null;
    });
  };

  /**
   * @description 선택한 시나리오 데이터
   */
  const selectedScenario = useMemo(() => {
    return scenarioList.find((item) => item.focus === true);
  }, [scenarioList]);

  /**
   * @description 체크박스 선택한 시나리오 데이터 그래프에 적용
   */
  const applyScenario2 = () => {
    const allUnChecked = scenarioList.every((item) => item.checked === false);

    if (allUnChecked) {
      // dataList에 id 같은 데이터를 찾아서 해당 데이터에 각각 정용
      setDataList((prev) => {
        return prev.map((item) => {
          const data = beforeDataList.find((d) => d.id === item.id);
          if (data) {
            return { ...item, data: data.data };
          }
          return item;
        });
      });

      return;
    }

    scenarioList.map((item) => {
      const index = item.detail.applyYear - baseYear + 2;
      const targetIndex = targetYear - baseYear + 2;
      const reduction = item.detail.emissionReduction;
      let newData3 = [];
      let newData4 = [];
      let newData5 = [];

      if (item.checked) {
        // 선택된 시나리오의 applyYear부터 targetYear까지의 데이터의 인덱스에 dataList의 id=3인 리스트에 -emissionReduction 합산
        const newGraphData = beforeDataList.map((data) => {
          if (data.id === 3) {
            // 순배출량
            newData3 = data.data.map((item, i) => {
              if (i >= index && i <= targetIndex) {
                return item - reduction;
              }
              return item;
            });
            return { ...data, data: newData3 };
          }
          if (data.id === 4) {
            // 감축활동에 따른 예상 감축량
            newData4 = data.data.map((item, i) => {
              if (i >= index && i <= targetIndex) {
                return item + reduction;
              }
              return item;
            });
            return { ...data, data: newData4 };
          }
          if (data.id === 5) {
            // 배출권 구매로 인한 상세 =
            newData5 = data.data.map((item, i) => {
              if (i >= index && i <= targetIndex) {
                //dataList id 6에 해당하는 리스트
                const reductionScenario = dataList.filter(
                  (item) => item.id === 6
                )?.[0];

                return calcOffsetByPurchasing(
                  newData3[i],
                  reductionScenario.data[i]
                );
                // return calcOffsetByPurchasing(, reductionScenario.data[i]);
              }
              return item;
            });
            return { ...data, data: newData5 };
          }

          return data;
        });
        //console.log("newGraphData >>", newGraphData);

        // dataList에 id 같은 데이터를 찾아서 해당 데이터에 각각 정용
        setDataList((prev) => {
          return prev.map((item) => {
            const data = newGraphData.find((d) => d.id === item.id);
            if (data) {
              return { ...item, data: data.data };
            }
            return item;
          });
        });
      }
    });
  };

  const applyScenario = () => {
    try {
      const allUnChecked = scenarioList.every((item) => item.checked === false);

      const initData = beforeDataList.map((item) => {
        return { ...item, data: [...item.data] };
      });

      if (allUnChecked) {
        setDataList((prev) => {
          return prev.map((item) => {
            const data = initData.find((d) => d.id === item.id);
            if (data) {
              return { ...item, data: data.data };
            }
            return item;
          });
        });
      }

      // 누적 데이터를 저장할 객체 초기화
      let accumulatedData = {};
      let accumulatedData2 = {}; // 감축활동에 따른 예상 감축량 :: 투자비
      let accumulatedData3 = {}; // 감축활동에 따른 예상 감축량 :: 연감 절감액

      // 각 연도별 emissionReduction 누적 합산
      let cumulativeReduction = Array(targetYear - baseYear + 3).fill(0);
      let cumulativeReduction2 = Array(targetYear - baseYear + 3).fill(0);
      let cumulativeReduction3 = Array(targetYear - baseYear + 3).fill(0);

      //console.log("cumulativeReduction >>>", cumulativeReduction);

      scenarioList.forEach((scenario) => {
        //console.log("시나리오 >>>>>>", scenario);

        const applyYear = scenario.detail.applyYear;
        const reduction = scenario.detail.emissionReduction;
        const investCost = scenario.detail.investment;
        const annualSavings = scenario.detail.annualSavings;

        const index = applyYear - (baseYear - 2);

        if (scenario.checked) {
          // 체크된 시나리오만 적용

          // 해당 연도부터 누적
          for (let i = index; i < cumulativeReduction.length; i++) {
            cumulativeReduction[i] += reduction;
            cumulativeReduction2[i] += investCost;
            cumulativeReduction3[i] += annualSavings;
          }

          // console.log("누적된 cumulativeReduction >>", cumulativeReduction);
          // console.log("누적된 cumulativeReduction2 >>", cumulativeReduction2);
          // console.log("누적된 cumulativeReduction3 >>", cumulativeReduction3);

          beforeDataList.forEach((data) => {
            if (!accumulatedData[data.id]) {
              accumulatedData[data.id] = [...data.data]; // 누적 데이터에 기존 데이터 복사
              // 0으로 초기화
              accumulatedData2[data.id] = Array(targetYear - baseYear + 3).fill(
                0
              );
              accumulatedData3[data.id] = Array(targetYear - baseYear + 3).fill(
                0
              );
            }

            if (data.id === 3) {
              // 순배출량
              for (let i = index; i < accumulatedData[data.id].length; i++) {
                if (decreaseMethod === "Remi") {
                  accumulatedData[data.id][i] = Number(
                    (
                      accumulatedData[data.id][i] - cumulativeReduction[i]
                    ).toFixed(2)
                  );
                } else {
                  accumulatedData[data.id][i] = Number(
                    (
                      accumulatedData[data.id][i] -
                      cumulativeReduction[i] / applyNeedList[i]
                    ).toFixed(2)
                  );
                }
              }
            }

            if (data.id === 4) {
              // 감축활동에 따른 예상 감축량

              for (let i = index; i < accumulatedData[data.id].length; i++) {
                if (decreaseMethod === "Remi") {
                  accumulatedData[data.id][i] = Number(
                    Number(cumulativeReduction[i]).toFixed(2)
                  );

                  accumulatedData2[data.id][i] = Number(
                    Number(cumulativeReduction2[i]).toFixed(2)
                  );
                  accumulatedData3[data.id][i] = Number(
                    Number(cumulativeReduction3[i]).toFixed(2)
                  );
                } else {
                  accumulatedData[data.id][i] = Number(
                    Number(cumulativeReduction[i] / applyNeedList[i]).toFixed(2)
                  );
                  accumulatedData2[data.id][i] = Number(
                    Number(cumulativeReduction2[i]).toFixed(2)
                  );
                  accumulatedData3[data.id][i] = Number(
                    Number(cumulativeReduction3[i]).toFixed(2)
                  );
                }
              }
            }

            if (data.id === 5) {
              // 배출권 구매로 인한 상세
              for (let i = index; i < accumulatedData[data.id].length; i++) {
                const reductionScenario = dataList.find(
                  (item) => item.id === 6
                );
                if (reductionScenario) {
                  accumulatedData[data.id][i] = calcOffsetByPurchasing(
                    accumulatedData[3][i],
                    reductionScenario.data[i]
                  );
                }
              }
            }
          });
        }
      });

      // console.log("accumulatedData >>", accumulatedData);
      // console.log("accumulatedData2 >>", accumulatedData2);
      // console.log("accumulatedData3 >>", accumulatedData3);

      // ---- 순 배출량 음수 체크 --------------------------------------------------
      // 추후 키 값 단위로 예외처리를 해야할 때, 키 값 배열에 추가
      let updateCheck = true;
      const indexKey = [3];
      indexKey.forEach((key) => {
        accumulatedData[key]?.forEach((data) => {
          if (data !== null) {
            if (data < 0) {
              // 배출량이 0 미만일 때,
              //console.log("배출량이 음수일 때 너무 작습니다.");
              updateCheck = false;

              // 기입된 시나리오 제거
              let copyScenarioList = structuredClone(scenarioList);
              copyScenarioList.pop(); // 마지막 인덱스 pop
              setScenarioList(copyScenarioList);
              return;
            }
          }
        });
      });
      // ---- 순 배출량 음수 체크 끝 --------------------------------------------------

      if (updateCheck) {
        // 누적된 데이터를 dataList에 반영
        setDataList((prev) => {
          return prev.map((item) => {
            if (accumulatedData[item.id]) {
              return {
                ...item,
                data: accumulatedData[item.id],
                investCost: accumulatedData2[item.id],
                annualSavings: accumulatedData3[item.id],
              };
            }
            return item;
          });
        });
      } else {
        alert("계산된 배출량이 너무 작습니다.");
      }
    } catch (e) {
      console.log("applyScenario error >>", e);
    } finally {
      //console.log("applyScenario ==========================end");
    }
  };

  return (
    <>
      <div className="CarbonPageTitle">
        <strong>탄소저감 목표관리</strong>
      </div>
      <div className="CarbonTopSelect">
        <div className="selArea">
          <p className="label">감축 방법론</p>
          <FormControl>
            <Select
              value={decreaseMethod || ""}
              onChange={(e) => setDecreaseMethod(e.target.value)}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon
                  sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                />
              )}
              MenuProps={MenuProps}
            >
              {methodOptions &&
                GlobalUtils.makeOptions(
                  "cdName",
                  "cdCode",
                  methodOptions,
                  true
                ).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <p className="label">기준년도</p>
          <FormControl>
            <Select
              value={baseYear || ""}
              onChange={(e) => setBaseYear(e.target.value)}
              displayEmpty
              className="basicSelect"
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={() => (
                <ExpandMoreSharpIcon
                  sx={{ mr: 1, color: "#666", fontSize: "32px" }}
                />
              )}
              MenuProps={MenuProps}
            >
              {/* 2016-2024 년도 까지 리스트  */}
              {yearListOptions.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="CarbonPurposeWrap">
        {/* 목표관리 이행 현황 */}
        {viewComponentId === 0 ? (
          <GoalManagementStatus
            form={form}
            setForm={setForm}
            changeComponent={changeComponent}
            textOb={textOb}
          />
        ) : null}

        {/* 목표관리 이행 현황 - 그래프 */}
        {viewComponentId === 1 ? (
          <>
            <GoalManagementStatusGraph
              changeComponent={changeComponent}
              scenarioList={scenarioList}
              setScenarioList={setScenarioList}
              baseYear={baseYear}
              targetYear={targetYear}
              form={form}
              setForm={setForm}
              dataList={dataList}
              setDataList={setDataList}
              setBeforeDataList={setBeforeDataList}
              decreaseMethod={decreaseMethod}
              baseEmissions={baseEmissions}
              setApplyNeedList={setApplyNeedList}
              refetchExistingScenarioList={refetchExistingScenarioList}
              fetchData={fetchData}
            />

            <div className="CarbonBox">
              <div className="CarbonBoxTitle">
                <b>적용 시나리오 이력</b>
              </div>
              <div className="scenarioGrid">
                <div className="scenarioFile">
                  {scenarioList.length > 0 ? (
                    <ul>
                      {scenarioList.map((item, index) => (
                        <li
                          key={index}
                          className={item.focus ? "select" : ""}
                          onClick={() => selectScenario(item.tempId)}
                        >
                          <p>
                            <input
                              type="checkbox"
                              checked={item.checked}
                              readOnly
                            />
                            <label>{item.title}</label>
                          </p>
                          <span
                            className="del"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteScenario(index);
                            }}
                          ></span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="noData">적용 시나리오 이력이 없습니다.</p>
                  )}
                </div>
                {
                  // 적용 시나리오 이력이 없는경우
                  scenarioList.length === 0 ? (
                    <>
                      <div className="scenarioDetail">
                        <p className="noData">적용 시나리오 이력이 없습니다.</p>
                      </div>
                    </>
                  ) : // 선택된 시나리오가 없을 경우
                  selectedScenario ? (
                    <>
                      <div className="scenarioDetail">
                        <ul>
                          <li>
                            <span>예상 온실가스 감축량</span>
                            <b>
                              {selectedScenario.detail.emissionReduction}{" "}
                              {selectedScenario.detail.emissionReductionUnit}
                            </b>
                          </li>
                          <li>
                            <span>투자비</span>
                            <b>
                              {selectedScenario.detail.investment}{" "}
                              {selectedScenario.detail.investmentUnit}
                            </b>
                          </li>
                          <li>
                            <span>연감 절감액</span>
                            <b>
                              {selectedScenario.detail.annualSavings}{" "}
                              {selectedScenario.detail.annualSavingsUnit}
                            </b>
                          </li>
                          <li>
                            <span>투자비 회수기간</span>
                            <b>
                              {selectedScenario.detail.investmentRecoveryPeriod}{" "}
                              {
                                selectedScenario.detail
                                  .investmentRecoveryPeriodUnit
                              }
                            </b>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="scenarioDetail">
                        <p className="noData">
                          선택된 시나리오 이력이 없습니다.
                        </p>
                      </div>
                    </>
                  )
                }
              </div>
              <p className="scenarioBtmText">
                * 시나리오를 선택하시면 예상 감축량 정보를 보실 수 있습니다.
              </p>
            </div>
            <div className="scenarioBtn">
              <button onClick={openNewPage}>관련 감축지원 사업 확인하기</button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default CarbonReduceMBO;
