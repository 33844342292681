// src/components/CarbonNote/DashBoard/EmissionByBusiness.jsx

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * @description DashBoard 컨테이너 → 최근 이력
 * @author Choi Seon ha
 * @date 05/08/2024
 * @return {JSX.Element}
 */

const RecentHistory = ({ recentHistoryData, selectedYear }) => {

  const navigate = useNavigate();

  const handleDetailClick = () => {
    const stateData = {};
    stateData.year = selectedYear;
    navigate("/history", { state: stateData });
  };

  return (
    <div className="DashGridItem">
      <p className="DashItemTitle">
        <b>최근 이력</b>
        <button onClick={handleDetailClick}>자세히 보기</button>
      </p>
      <table>
        <colgroup>
          <col width="35%" />
          <col width="30%" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>담당자명</th>
            <th>기준 월</th>
            <th>작성 일시</th>
          </tr>
        </thead>
        <tbody>
          {recentHistoryData
            ? recentHistoryData.map((item, index) => (
                <tr key={index}>
                  <td>{item.userName}</td>
                  <td>{item.yyyyMm}</td>
                  <td>{item.regDtm}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default RecentHistory;
