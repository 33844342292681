import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { TitleCloseDialog } from "../../styleModule/ModifyStyle";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
/**
 * @description
 * @author seoeunPark
 * @date 18/06/2024
 * @param {*}
 * @return
 */
const ConfirmNewReport2 = ({ open, onClose, selected }) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate("/step", {
      state: {
        year: selected.year,
        month: selected.month,
        company: localStorage.getItem("inloNo"),
      },
    });
  };
  return (
    <>
      <TitleCloseDialog open={open} onClose={onClose}>
        <DialogTitle>새 보고서 작성</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="dialogClose"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="CarbonPopCont">
            <p className="typoTy1">
              해당 기간 내 보고서 작성을 위한 탄소배출량 산정이 필요합니다.
              <br />
              탄소배출량 산정을 시작하시겠습니까?
            </p>
            <div className="CarbonPopBtn">
              <button className="cancle" onClick={onClose}>
                취소
              </button>
              <button onClick={handleConfirm}>확인</button>
            </div>
          </div>
        </DialogContent>
      </TitleCloseDialog>
    </>
  );
};

export default ConfirmNewReport2;
