import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatNumber } from "../../CarbonNote/common/commonData";

/**
 * @description DashBoard 컨테이너 → Scope 별 배출량
 * @author Choi Seon ha
 * @date 10/06/2024
 * @param {*} builder
 * @return {JSX.Element}
 */

const EmissionByScope = ({ emissionByScopeData }) => {
  const [scope1Emission, setScope1Emission] = useState(0);
  const [scope1Percentage, setScope1Percentage] = useState(0);
  const [scope2Emission, setScope2Emission] = useState(0);
  const [scope2Percentage, setScope2Percentage] = useState(0);
  const [scope3Emission, setScope3Emission] = useState(0);
  const [scope3Percentage, setScope3Percentage] = useState(0);

  useEffect(() => {
    setScope1Emission(0);
    setScope1Percentage(0);
    setScope2Emission(0);
    setScope2Percentage(0);
    setScope3Emission(0);
    setScope3Percentage(0);

    if (emissionByScopeData) {
      const scope1Data = emissionByScopeData.find(
        (item) => item.scope === "S1"
      );
      const scope2Data = emissionByScopeData.find(
        (item) => item.scope === "S2"
      );
      const scope3Data = emissionByScopeData.find(
        (item) => item.scope === "S3"
      );

      if (scope1Data) {
        //console.log("scope1Data>>", scope1Data);
        setScope1Emission(formatNumber(scope1Data.scopeEmi));
        setScope1Percentage(scope1Data.percentage);
      }
      if (scope2Data) {
        //console.log("scope2Data>>", scope2Data);
        setScope2Emission(formatNumber(scope2Data.scopeEmi));
        setScope2Percentage(scope2Data.percentage);
      }
      if (scope3Data) {
        //console.log("scope3Data>>", scope3Data);
        setScope3Emission(formatNumber(scope3Data.scopeEmi));
        setScope3Percentage(scope3Data.percentage);
      }
    }
  }, [emissionByScopeData]);

  const cssRoot = window.getComputedStyle(document.documentElement);

  const chart1option = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
      height: "120px",
      width: 200,
      spacingTop: 0,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    accessibility: {
      enabled: false
    },
    xAxis: {
      categories: ["Scope1", "Scope2", "Scope3"],
      labels: {
        enabled: true,
        style: {
          //color: "#fff",
          color: "#000",
          fontSize: "12px",
          fontWeight: 500,
        },
        //y: -15,
      }, // 값이 작을 때, 바 위에 카테고리 이름이 겹쳐지는 문제가 있어, 카테고리 이름을 x축 아래로 내리고 컬러를 변경함
    },
    yAxis: {
      title: {
        text: null,
      },
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      max: 100,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Scope1",
        data: [
          {
            name: "Scope1",
            y: scope1Percentage,
            emi: scope1Emission,
            color: cssRoot.getPropertyValue("--scope1"),
          },
          {
            name: "Scope2",
            y: scope2Percentage,
            emi: scope2Emission,
            color: cssRoot.getPropertyValue("--scope2"),
          },
          {
            name: "Scope3",
            y: scope3Percentage,
            emi: scope3Emission,
            color: cssRoot.getPropertyValue("--scope3"),
          },
        ],
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    plotOptions: {
      column: {
        borderRadius: 4,
        borderColor: "transparent",
      },
      series: {
        groupPadding: 0,
        pointPadding: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y + "%";
          },
          style: {
            textOutline: "transparent",
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.emi}</b> tCO2e (${this.y}%)`;
      },
      backgroundColor: cssRoot.getPropertyValue("--gray700"),
      borderRadius: 4,
      padding: 12,
      style: {
        color: "#fff",
      },
    },
  };

  return (
    <>
      <div className="DashGridItem">
        <p className="DashItemTitle">
          <b>Scope 별 배출량</b>
        </p>
        <div id="Dash1">
          <HighchartsReact highcharts={Highcharts} options={chart1option} />
        </div>
      </div>
    </>
  );
};

export default EmissionByScope;
