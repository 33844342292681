import React, { useEffect, useState } from "react";
import InputNumber from "../common/InputNumber";

const GoalManagementStatus = ({ form, setForm, changeComponent, textOb }) => {
  const [resultBtn, setResultBtn] = useState(false); //결과 확인하기 버튼 상태

  // form 입력값이 변경될 때마다 checkForm 실행
  useEffect(() => {
    checkForm();
  }, [form]);

  const checkForm = () => {
    if (
      form.beforeBaseYear2Value &&
      form.beforeBaseYear1Value &&
      form.baseYearValue
    ) {
      setResultBtn(true);
    } else {
      setResultBtn(false);
    }
  };

  return (
    <>
      <div className="CarbonBox">
        <div className="CarbonBoxTitle">
          <b>목표관리 이행 현황</b>
        </div>
        <div className="PurposeInputWrap">
          <div className="PurposeInput">
            <div>
              <label>기준연도 2년전의 {textOb.text3}</label>
              <InputNumber
                type="text"
                placeholder="수량을 입력해주세요."
                value={form.beforeBaseYear2Value}
                onChange={(e) => {
                  setForm({ ...form, beforeBaseYear2Value: e.target.value });
                }}
              />
            </div>
            <div>
              <label>기준연도 1년전의 {textOb.text3}</label>
              <InputNumber
                type="text"
                placeholder="수량을 입력해주세요."
                value={form.beforeBaseYear1Value}
                onChange={(e) => {
                  setForm({ ...form, beforeBaseYear1Value: e.target.value });
                }}
              />
            </div>
            <div>
              <label>기준연도의 {textOb.text3}</label>
              <InputNumber
                type="text"
                placeholder="수량을 입력해주세요."
                value={form.baseYearValue}
                onChange={(e) => {
                  setForm({ ...form, baseYearValue: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="PurposeSum">
            2030년까지 기준연도 대비 {textOb.text1}
            <InputNumber
              type="text"
              placeholder="NN"
              value={form.growthRate}
              onChange={(e) => {
                setForm({ ...form, growthRate: e.target.value });
              }}
            />
            % {textOb.text2} 예상
          </div>
          <div className="btn">
            <button disabled={!resultBtn} onClick={() => changeComponent(1)}>
              <span>결과 확인하기</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalManagementStatus;
